<script>
import { app } from '@/main'
import { mapActions } from 'vuex'

import { services } from '../services'

const TRANSLATION = {
  header: app.$t('notifications.mediation-plan:manage.sections.header-create'),
  contentHeaderCreate: app.$t('notifications.mediation-plan:manage.sections.body.header-create'),
  contentHeaderEdit: app.$t('notifications.mediation-plan:manage.sections.body.header-edit'),
  leave: app.$t('notifications.mediation-plan:manage.sections.back'),
  save: app.$t('global:form.save'),

  feedback: {
    createSuccess: app.$t('notifications.mediation-plan:manage.section.feedback.on-create.success'),
    createError: app.$t('notifications.mediation-plan:manage.section.feedback.on-create.error'),
    editLoadingError: app.$t('notifications.mediation-plan:manage.section.feedback.edit-loading-error'),
    editUpdateSuccess: app.$t('notifications.mediation-plan:manage.section.feedback.edit-update-success'),
    editUpdateError: app.$t('notifications.mediation-plan:manage.section.feedback.edit-update-error')
  },

  modal: {
    title: app.$t('notifications.mediation-plan:manage.modal.title'),
    description: app.$t('notifications.mediation-plan:manage.modal.description')
  }
}

const TOTAL_TABS = 3
const BACK_URL = { name: 'notifications.mediation-plan' }

const TABS_INDEX_TO_NAME_TYPE = {
  1: 'tabDataPlan',
  2: 'tabMediation',
  3: 'tabAssociation'
}

export default {
  name: 'ManagePage',

  components: {
    Action: () => import('@/components/general/Action'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Pagination: () => import('@/components/general/Pagination'),

    ManageTabDataPlan: () => import('../components/ManageTabDataPlan'),
    ManageTabMediation: () => import('../components/ManageTabMediation'),
    ManageTabAssociation: () => import('../components/ManageTabAssociation'),
    ModalConfirm: () => import('@/components/macro/ModalConfirm')
  },

  data () {
    return {
      validTabs: {
        tabDataPlan: false,
        tabMediation: false,
        tabAssociation: true
      },

      activeTab: 1,
      applicabilityList: [],

      applicabilityListPagination: {
        query: {
          name: null,
          limit: 300
        },

        filter: {
          active: 1
        },

        lastPage: null,
        limit: 6,
        page: 1
      },

      isLoadingMoreApplicabilities: false,
      filterList: [],
      filterListPagination: {},

      formData: {
        name: null,
        description: null,
        applicability: null,
        dispatchTime: null,
        mediations: [],
        associations: []
      },

      mediationPlanBody: {},

      modalOpen: false,
      modalTitle: '',
      modalDescription: ''
    }
  },

  computed: {
    isEditing () {
      return !!this.$route.params.id
    },

    isValidOnEveryTabs () {
      const everyIsValid = Object.values(this.validTabs)
        .every(tab => tab)

      return everyIsValid
    },

    isValidOnCurrentTab () {
      this.checkChildrenValidationForm()
      const currentTabIsValid = this.validTabs[TABS_INDEX_TO_NAME_TYPE[this.activeTab]]

      return currentTabIsValid
    },

    getHeaderText () {
      return this.isEditing ? this.formData.name : TRANSLATION.header
    },

    getContentHeaderText () {
      return this.isEditing ? TRANSLATION.contentHeaderEdit : TRANSLATION.contentHeaderCreate
    }
  },

  watch: {
    activeTab: {
      immediate: true,
      handler (currentTab, previousTab) {
        const tab = TABS_INDEX_TO_NAME_TYPE[currentTab]

        const hasTabFunction = typeof this[tab] === 'function'

        if (hasTabFunction) {
          const switchTab = this[tab]

          switchTab(currentTab, previousTab)
        }
      }
    }
  },

  created () {
    this.setup()

    const page = this.$route?.params?.page

    if (page) {
      this.activeTab = page
    }

    if (this.isEditing) {
      this.fetchEditingFormData()
    }
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetSolutionsList',
      'attemptGetLearningPathsList'
    ]),

    setup () {
      this.TRANSLATION = TRANSLATION
      this.TOTAL_TABS = TOTAL_TABS
      this.BACK_URL = BACK_URL
      this.TABS_INDEX_TO_NAME_TYPE = TABS_INDEX_TO_NAME_TYPE
    },

    // BEGIN - scope for childrens comunication
    mergeFormDataTabs (formData) {
      Object.keys(formData)
        .forEach(key => {
          this.formData[key] = formData[key]
        })
    },

    checkChildrenValidationForm () {
      const currentTabChildren = this.$refs[TABS_INDEX_TO_NAME_TYPE[this.activeTab]]

      currentTabChildren.checkIsInvalidToParent()
    },

    mergeValidationTabs ({ tab, isValid }) {
      this.validTabs[tab] = isValid
    },
    // END - scope for childrens comunication

    tabMediation (currentTab, previousTab) {
      const isAdvancingPages = currentTab > previousTab

      if (isAdvancingPages) {
        this.fetchFilters()
      }
    },

    tabAssociation (currentTab, previousTab) {
      const isAdvancingPages = currentTab > previousTab

      if (isAdvancingPages) {
        this.fetchApplicability()
      }
    },

    fetchEditingFormData () {
      this.setFetching(true)

      const id = this.$route.params.id

      services.attemptGetMediationPlan(id)
        .then(response => {
          const tabDataPlan = {
            name: response.name,
            description: response.description,
            applicability: response.applicability,
            dispatchTime: this.formatDispatchTime(response.dispatchTime)
          }

          const mediations = response.mediations.map((mediation, index) => {
            return {
              key: index,
              isMinimized: true,
              ...mediation
            }
          })
          const tabMediation = {
            mediations
          }

          const tabAssociation = {
            associations: response.associations
          }

          this.formData = {
            ...tabDataPlan,
            ...tabMediation,
            ...tabAssociation
          }

          const TABS_FORM_DATA_TYPE = {
            tabDataPlan,
            tabMediation,
            tabAssociation
          }

          const childrens = Object.values(TABS_INDEX_TO_NAME_TYPE)

          childrens.forEach(children => {
            this.$refs[children].formData = TABS_FORM_DATA_TYPE[children]
          })

          this.fetchApplicability()
        })
        .catch(() => {
          this.setFeedback({ message: TRANSLATION.feedback.editLoadingError })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    fetchFilters () {
      this.setFetching(true)

      this.filterListPagination = {
        order: {
          name: 'asc'
        },

        filter: {
          active: true,
          type: this.formData.applicability
        },

        limit: 9999
      }

      services.attemptGetFilterList(this.filterListPagination)
        .then(response => {
          this.filterList = response.data.map(item => ({
            text: item.name,
            value: item.id
          }))
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    fetchApplicability () {
      const FORM_DATA_TYPE = {
        solution: 'fetchSolutions',
        learning_path: 'fetchPaths',
        program: 'fetchPrograms'
      }

      const applicability = FORM_DATA_TYPE[this.formData.applicability]

      const hasApplicabilityFunction = typeof this[applicability] === 'function'

      if (hasApplicabilityFunction) {
        const switchApplicability = this[applicability]

        switchApplicability()
      }
    },

    createMediationPlan () {
      this.setFetching(true)

      services.attemptCreateMediationPlan(this.mediationPlanBody)
        .then(() => {
          this.setFeedback({ message: TRANSLATION.feedback.createSuccess })
        })
        .catch(() => {
          this.setFeedback({ message: TRANSLATION.feedback.createError })
        })
        .finally(() => {
          this.setFetching(false)
          this.leave()
        })
    },

    updateMediationPlan () {
      this.setFetching(true)

      const id = this.$route.params.id

      services.attemptUpdateMediationPlan({
        id,
        data: this.mediationPlanBody
      })
        .then(() => {
          this.setFeedback({ message: TRANSLATION.feedback.editUpdateSuccess })
        })
        .catch(() => {
          this.setFeedback({ message: TRANSLATION.feedback.editUpdateError })
        })
        .finally(() => {
          this.setFetching(false)
          this.leave()
        })
    },

    submit () {
      this.mediationPlanBody = {
        name: this.formData.name,
        description: this.formData.description,
        applicability: this.formData.applicability,
        dispatchTime: this.formData.dispatchTime,
        mediations: [],
        associations: []
      }

      this.mediationPlanBody.mediations = this.formData.mediations.map(mediationPlan => {
        return {
          id: mediationPlan.id ?? null,
          filter_id: mediationPlan.filter.id,
          type: mediationPlan.type,
          title: mediationPlan.messageTitle || undefined,
          content: mediationPlan.messageContent || undefined,
          mediationAsset: mediationPlan.mediationAsset || null
        }
      })

      this.mediationPlanBody.associations = this.formData.associations.map(association => association.id)

      if (this.isEditing) {
        if (this.mediationPlanBody.applicability === 'solution' && this.mediationPlanBody.associations.length === 0) {
          this.modalTitle = TRANSLATION.modal.title
          this.modalDescription = TRANSLATION.modal.description
          this.modalOpen = true
        } else {
          this.updateMediationPlan()
        }

        return
      }

      this.createMediationPlan()
    },

    leave () {
      this.$router.push(BACK_URL)
    },

    prevTab () {
      this.activeTab--
    },

    nextTab () {
      const mayChangeTab = this.isValidOnCurrentTab

      if (mayChangeTab) {
        this.activeTab++
      }
    },

    searchApplicability (search) {
      const FORM_DATA_TYPE = {
        solution: 'searchSolutions',
        learning_path: 'searchPaths',
        program: 'searchPrograms'
      }

      const applicability = FORM_DATA_TYPE[this.formData.applicability]

      const hasApplicabilityFunction = typeof this[applicability] === 'function'

      if (hasApplicabilityFunction) {
        const switchApplicability = this[applicability]

        switchApplicability(search)
      }
    },

    loadMoreApplicabilities () {
      if (this.isLoadingMoreApplicabilities) return

      const FORM_DATA_TYPE = {
        solution: 'fetchSolutions',
        learning_path: 'fetchPaths',
        program: 'fetchPrograms'
      }

      const applicability = FORM_DATA_TYPE[this.formData.applicability]

      const hasApplicabilityFunction = typeof this[applicability] === 'function'

      if (hasApplicabilityFunction) {
        const switchApplicability = this[applicability]

        if (this.applicabilityListPagination.lastPage) {
          if (this.applicabilityListPagination.lastPage > this.applicabilityListPagination.page) {
            this.isLoadingMoreApplicabilities = true
            this.applicabilityListPagination.page += 1

            switchApplicability(true)
              .then(() => {
                this.isLoadingMoreApplicabilities = false
              })
          }
        }
      }
    },

    searchSolutions (term) {
      this.applicabilityListPagination.query.name = term
      this.applicabilityListPagination.page = 1
      this.applicabilityListPagination.lastPage = null
      this.fetchSolutions()
    },

    async fetchSolutions (isLoadMore = false) {
      return this.attemptGetSolutionsList(this.applicabilityListPagination)
        .then((pagination) => {
          this.applicabilityListPagination.lastPage = pagination.lastPage
          this.applicabilityListPagination.page = pagination.actualPage

          if (pagination.data) {
            if (!isLoadMore) {
              this.applicabilityList = []
            }

            pagination.data.forEach(element => {
              this.applicabilityList.push({
                name: element.name,
                id: element.id,
                workload: element.workload,
                solutionType: element.solutionType
              })
            })
          }

          return pagination
        })
    },

    searchPaths (search) {
      this.applicabilityListPagination.query.name = search
      this.applicabilityListPagination.page = 1
      this.applicabilityListPagination.lastPage = null
      this.fetchPaths()
    },

    async fetchPaths (isLoadMore = false) {
      return this.attemptGetLearningPathsList(this.applicabilityListPagination)
        .then((pagination) => {
          this.applicabilityListPagination.lastPage = pagination.lastPage
          this.applicabilityListPagination.page = pagination.actualPage

          if (pagination.data) {
            if (!isLoadMore) {
              this.applicabilityList = []
            }

            pagination.data.forEach(element => {
              this.applicabilityList.push({
                name: element.name,
                id: element.id,
                learningPathSolutions: element.learningPathSolutions.length,
                workload: element.workload
              })
            })
          }

          return pagination
        })
    },

    confirmUpdate () {
      this.updateMediationPlan()
    },

    cancelUpdate () {
      this.modalOpen = false
    },

    formatDispatchTime (num) {
      return `${num.toString().padStart(2, '0')}:00`
    }
  }

}
</script>

<template>
  <div class="main-content mediation-plan-create">
    <ContentHeader
      :title="getHeaderText"
      dark-theme
    >
      <Action
        slot="back"
        class="btn-back"
        :text="TRANSLATION.leave"
        type="button"
        icon="keyboard_backspace"
        @click="leave()"
      />

      <template #buttons>
        <Action
          :text="TRANSLATION.save"
          :disabled="!isValidOnEveryTabs"
          type="button"
          flat
          dark
          @click="submit()"
        />
      </template>
    </ContentHeader>

    <div class="mediation-plan-create-header">
      <p
        class="mediation-plan-create-description"
        v-html="getContentHeaderText"
      />
    </div>

    <div class="main-content-inner">
      <ManageTabDataPlan
        v-show="TABS_INDEX_TO_NAME_TYPE[activeTab] === 'tabDataPlan'"
        ref="tabDataPlan"
        :applicability="formData.applicability"
        @mergeFormDataTab="mergeFormDataTabs"
        @validationStatus="mergeValidationTabs"
      />

      <ManageTabMediation
        v-show="TABS_INDEX_TO_NAME_TYPE[activeTab] === 'tabMediation'"
        ref="tabMediation"
        :filter-list="filterList"
        :applicability="formData.applicability"
        @mergeFormDataTab="mergeFormDataTabs"
        @validationStatus="mergeValidationTabs"
      />

      <ManageTabAssociation
        v-show="TABS_INDEX_TO_NAME_TYPE[activeTab] === 'tabAssociation'"
        ref="tabAssociation"
        :is-loading-more-applicabilities="isLoadingMoreApplicabilities"
        :available-applicability-list="applicabilityList"
        :applicability="formData.applicability"
        @mergeFormDataTab="mergeFormDataTabs"
        @searchApplicability="searchApplicability"
        @loadMoreApplicabilities="loadMoreApplicabilities"
      />
    </div>

    <Pagination
      :active-first-last="false"
      :active-page="activeTab"
      :page-count="TOTAL_TABS"
      @nextPage="nextTab()"
      @previousPage="prevTab()"
    />
    <ModalConfirm
      :active="modalOpen"
      :title="modalTitle"
      :description="modalDescription"
      @confirmAction="confirmUpdate"
      @cancelAction="cancelUpdate"
    />
  </div>
</template>

<style lang="scss" src="../../../assets/scss/Notification.scss"></style>

<style lang="scss" src="../assets/scss/Manage.scss"></style>
