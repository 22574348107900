<script>
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'TracksManageQualify',
  components: {
    Action: () => import('@/components/general/Action'),
    ActionBar: () => import('@/components/general/ActionBar'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    FormSection: () => import('@/components/general/FormSection'),
    InputField: () => import('@/components/general/InputField'),
    Pagination: () => import('@/components/general/Pagination'),
    Radio: () => import('@/components/general/Radio'),
    SelectField: () => import('@/components/general/SelectField')
  },

  data () {
    return {
      timeDurationList: [
        {
          text: this.$t('global.time:select.h'),
          value: 'h'
        },
        {
          text: this.$t('global.time:select.d'),
          value: 'd'
        },
        {
          text: this.$t('global.time:select.s'),
          value: 's'
        }
      ],

      trackThemeList: [
        {
          text: this.$t('tracks.manage:trackThemesList.TI'),
          value: 'ti'
        },
        {
          text: this.$t('tracks.manage:trackThemesList.management'),
          value: 'planejamento'
        },
        {
          text: this.$t('tracks.manage:trackThemesList.money'),
          value: 'financas'
        }
      ],

      trackProfileList: [
        {
          text: this.$t('tracks.manage:trackProfilesList.PE'),
          value: 'pe'
        },
        {
          text: this.$t('tracks.manage:trackProfilesList.MEI'),
          value: 'mei'
        },
        {
          text: this.$t('tracks.manage:trackProfilesList.EPP'),
          value: 'epp'
        }
      ],

      workloadOptions: [
        {
          label: this.$t('tracks.manage:workloadOption.auto'),
          value: 'auto'
        },
        {
          label: this.$t('tracks.manage:workloadOption.manual'),
          value: 'manual'
        }
      ],

      pagination: {
        page: 2,
        lastPage: 4
      },

      formData: {
        id: null,
        name: '',
        callText: '',
        description: '',
        audience: '',
        content: '',
        differentials: '',
        trackType: null,
        format: null,
        certificateType: null,
        paymentType: null,
        teaserLink: null,
        image: null,
        trackTheme: [],
        trackProfile: [],
        trackIntegration: [],
        segment: null,
        problemToBeSolved: null,
        skillsDeveloped: null,
        canvasCourseId: null,
        workloadOption: null,
        workload: {
          type: null,
          number: null
        // },
        // duration: {
        //   type: null,
        //   number: null
        }
      }
    }
  },

  validations: {
    formData: {
      trackTheme: { required },
      workloadOption: { required },
      trackProfile: { required },
      // duration: { required },
      workload: { required }
    }
  },

  computed: {
    isEditing () {
      return this.$route.params.id && this.$route.params.isNew !== 'new'
    }
  },

  created () {
    // TODO: remover esse || this.formatFormData()
    this.formData = this.$store.getters.getManagingTrack || this.formatFormData()
    this.formData.canvasCourseId = this.formData.trackIntegration.length > 0 ? this.formData.trackIntegration.filter((item) => item.integration.alias === 'canvas')[0].vendorId : null
    this.formData.trackIntegration = this.formData.trackIntegration.length > 0 ? this.formData.trackIntegration.filter((item) => item.integration.alias === 'canvas').map(item => item.integration.alias || item) : [ 'canvas' ]

    if (this.isEditing) {
      this.formData.trackTheme = this.formData.trackTheme.map((item) => item.theme.alias || item)
      this.formData.trackProfile = this.formData.trackEntrepreneurProfile.map((item) => item.entrepreneurProfile.id || item)

      if (this.formData.workload) {
        this.formData.workload = {
          number: this.formData.workload.toString().substr(0, this.formData.workload.length - 1),
          type: this.formData.workload.toString().substr(this.formData.workload.length - 1, 1)
        }
      }

      if (this.formData.duration) {
        this.formData.duration = {
          number: this.formData.duration.toString().substr(0, this.formData.duration.length - 1),
          type: this.formData.duration.toString().substr(this.formData.duration.length - 1, 1)
        }
      }
    } else {
      this.formData.workload = {
        number: 0,
        type: ''
      }

      this.formData.duration = {
        number: 0,
        type: ''
      }
    }
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptSaveTrack',
      'attemptUpdateTrack',
      'attemptUpdateActiveTrack',
      'attemptSaveActiveTrack'
    ]),

    prevPage () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        if (this.formData.id != null) {
          const formData = this.formatFormData()

          this.attemptUpdateActiveTrack({
            id: this.$route.params.id,
            data: formData
          }).then(() => {
            this.$router.push({ name: 'tracks.manage' })
          })
        } else {
          this.attemptSaveActiveTrack(this.formData).then(() => {
            this.$router.push({ name: 'tracks.manage' })
          })
        }
      }
    },

    nextPage () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.$router.push({ name: 'tracks.manage.seo' })
      }
    },

    leave () {
      this.modalConfirm = false

      this.$nextTick(() => {
        this.$router.push(this.backUrl)
      })
    },

    submitCreation () {
      this.setFetching(true)

      this.attemptSaveTrack(this.formData).then(({ data }) => {
        this.reports.items.push(data)
        this.setFeedback({ message: this.$t('reports:feedback.created.success') })
        this.$router.push({ name: 'reports.list' })
      }).catch(({ response }) => {
        this.setFeedback({ message: this.$t(`reports:feedback.created.error:${response.data.message.replace(/_/g, '.')}`) })
        this.$router.push({ name: 'reports.list' })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    submitUpdate () {
      this.setFetching(true)
      const formData = this.formatFormData()

      this.attemptUpdateTrack({
        id: this.$route.params.id,
        data: formData
      }).then(({ data }) => {
        if (data.imagePath) {
          data.imagePath = data.imagePath + '?v=' + Math.random()
        }

        this.setFeedback({ message: this.$t('tracks:feedback.updated.success') })
        this.$router.push({ name: 'tracks.index' })
      }).catch(() => {
        this.setFeedback({ message: this.$t('tracks:feedback.updated.error') })
        this.$router.push({ name: 'tracks.index' })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    submit (next) {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.$route.params.id ? this.submitUpdate(next) : this.submitCreation(next)
      } else {
        this.setFeedback({ message: this.$t('tracks:feedback.validation.error') })
      }
    },

    formatFormData () {
      const data = {}

      data.track_type = this.formData.trackType.alias
      data.name = this.formData.name

      if (this.formData.duration && this.formData.duration.number > 0) {
        data.duration = this.formData.duration.number + this.formData.duration.type
      }

      if (this.formData.workload && this.formData.workload.number > 0) {
        data.workload = this.formData.workload.number + this.formData.workload.type
      }

      data.description = this.formData.description
      data.format = this.formData.format
      data.call_text = this.formData.callText
      data.audience = this.formData.audience
      data.content = this.formData.content
      data.differentials = this.formData.differentials
      data.certificate_type = this.formData.certificateType
      data.payment_type = this.formData.paymentType
      data.teaser_link = this.formData.teaserLink
      data.active = this.formData.active
      data.themes = this.formData.trackTheme
      data.entrepreneur_profile = this.formData.trackProfile
      data.integration = this.formData.trackIntegration
      data.canvas_course_id = this.formData.canvasCourseId || 0

      return data
    }
  }
}
</script>

<template>
  <div class="main-content tracks-create">
    <ContentHeader
      :title="isEditing ? formData.title : $t('tracks.create:header.title')"
      light-theme
      fixed
    >
      <Action
        slot="back"
        type="button"
        :text="$t('global:back.tracks')"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave()"
      />
      <ActionBar slot="actionbar" />
      <!--
 <template slot="buttons">
        <action :text="$t('global:form.save')" type="button" flat @click="submit(false)"></action>
      </template>
-->
    </ContentHeader>
    <div class="main-content-inner">
      <div class="center">
        <div class="tracks-create-header">
          <h2 class="tracks-create-title">
            {{ $t('global:form.step', {'num': 2}) }}
          </h2>
          <p
            class="tracks-create-description"
            v-html="$t('tracks.create:header.description.2')"
          />
        </div>
        <form
          class="form"
          @submit.prevent="submit"
        >
          <!--
 <input-field :label="$t('global:form.tracks.canvas.course')" v-model="formData.canvasCourseId" :counter="40"></input-field>
-->

          <FormSection :title="$t('global:form.themes')">
            <SelectField
              v-model="formData.trackTheme"
              :label="$t('global:form.theme')"
              multiple
              :items="trackThemeList"
              :validation="$v.formData.trackTheme"
            />
            <!--
 <action :text="$t('global:form.solutions.add.theme')" type="button" flat></action>
-->
          </FormSection>
          <FormSection :title="$t('global:form.profiles')">
            <SelectField
              v-model="formData.trackProfile"
              :label="$t('global:form.profile')"
              multiple
              :items="trackProfileList"
              :validation="$v.formData.trackProfile"
            />
            <!--
 <action :text="$t('global:form.solutions.add.profile')" type="button" flat></action>
-->
          </FormSection>

          <!--
          <select-field :label="$t('global:form.tracks.segment')" :items="genericSelectList" v-model="formData.segment" :validation="$v.formData.segment"></select-field>
-->
          <!--
          <select-field :label="$t('global:form.tracks.problemToBeSolved')" :items="genericSelectList" v-model="formData.problemToBeSolved" :validation="$v.formData.problemToBeSolved"></select-field>
-->
          <!--
          <select-field :label="$t('global:form.tracks.skillsDeveloped')" :items="genericSelectList" v-model="formData.skillsDeveloped" :validation="$v.formData.skillsDeveloped"></select-field>
-->

          <FormSection :title="$t('global:solution.workload')">
            <Radio
              v-model="formData.workloadOption"
              :items="workloadOptions"
              horizontal
              :validation="$v.formData.workloadOption"
            />
          </FormSection>

          <div
            v-if="formData.workloadOption === 'manual'"
            class="form-group clearfix"
            data-gap="40"
            data-cols="2"
          >
            <InputField
              v-model="formData.workload.number"
              type="number"
              :min="0"
              :label="$t('global:solution.workload')"
              :validation="$v.formData.workload"
            />
            <SelectField
              v-model="formData.workload.type"
              :label="$t('global:form.type')"
              :items="timeDurationList"
              :validation="$v.formData.workload"
            />
          </div>

          <!--
 <div class="form-group clearfix" data-gap="40" data-cols="2">
            <input-field type="number" :min="0" :label="$t('global:solution.duration')" v-model="formData.duration.number" :validation="$v.formData.duration"></input-field>
            <select-field :label="$t('global:form.type')" :items="timeDurationList" v-model="formData.duration.type" :validation="$v.formData.duration"></select-field>
          </div>
-->
        </form>
      </div>
    </div>
    <Pagination
      class="center"
      :active-first-last="false"
      :active-page="pagination.page"
      :page-count="pagination.lastPage"
      @nextPage="nextPage"
      @previousPage="prevPage"
    />
  </div>
</template>

<style src="@/assets/styles/themes/default/tracks.css"></style>
