<script>
import { mapActions } from 'vuex'
import { solutionTypeFormatter } from '@/support/utils/solutionTypeFormatter'
import { minLength, requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'SolutionsConfig',
  components: {
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Tabs: () => import('@/components/general/Tabs'),
    Datatable: () => import('@/components/general/Datatable'),
    InputField: () => import('@/components/general/InputField'),
    Action: () => import('@/components/general/Action'),
    FilterList: () => import('@/components/general/FilterList'),
    FilterListSearch: () => import('@/components/general/FilterListSearch'),
    FormSection: () => import('@/components/general/FormSection'),
    Divisor: () => import('@/components/general/Divisor'),
    Icon: () => import('@/components/general/Icon'),
    Radio: () => import('@/components/general/Radio')
  },

  data () {
    return {
      formData: {
        listHighlights: [],
        ordenation: 'default'
      },

      tabLinks: [
        {
          location: { name: 'solutions.index' },
          text: this.$t('solutions.tabs:link.1')
        },
        {
          location: { name: 'solutions.configs' },
          text: this.$t('solutions.tabs:link.2')
        }
      ],

      configTabs: [
        {
          text: this.$t('solutions.config:title')
        },
        {
          text: this.$t('solutions.config.tab:highlights')
        }
      ],

      orderOptions: [
        {
          label: this.$t('global:manual'),
          value: 'manual'
        },
        {
          label: this.$t('global:default'),
          value: 'default'
        }
      ],

      solutionsPagination: {
        query: {
          name: null,
          limit: 300
        },

        filter: {
          active: 1,
          show_on_portal: 1,
          is_highlight: 1
        },

        order: {
          name: 'ASC'
        },

        lastPage: null,
        limit: 6,
        page: 1
      },

      activeTab: 0,
      isLoadingMoreSolutions: false,
      solutions: [],
      rules: []
    }
  },

  validations: {
    formData: {
      listHighlights: {
        required: requiredIf(function () {
          return this.formData.ordenation === 'manual'
        }),

        minLength: minLength(4)
      }
    }
  },

  computed: {
    linkedSolutionsLabel () {
      return this.solutions.length ? this.$t('solutions.list.link:datatable.header.1') : this.$t('solutions.list.link:datatable.header.1.without-solutions')
    },

    availableSolutions () {
      return this.solutions.filter(solution => !this.formData.listHighlights.find(findSolution => solution.id === findSolution.id))
    }
  },

  created () {
    this.setFetching(true)

    Promise.all([
      this.getEndRules(),
      this.fetchSolutionsHighlights(),
      this.fetchSolutionsData()
    ]).finally(() => {
      this.setFetching(false)
    })
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetSolutionsEndRules',
      'attemptUpdateSolutionsEndRules',
      'attemptGetSolutionsList',
      'attemptGetListSolutionsHighlights',
      'attemptUpdateSolutionsHighlights',
      'attemptGetGeneralSettings'
    ]),

    getEndRules () {
      this.attemptGetSolutionsEndRules().then(res => {
        this.rules = res.data.configurations.solutionsTypes
      })
    },

    updateEndRules () {
      const form = {}

      form.configurations = {}

      form.configurations.solutionsTypes = {}

      this.rules.forEach(rule => {
        form.configurations.solutionsTypes[rule.alias] = Number(rule.minimumRequiredConclusion)
      })

      this.attemptUpdateSolutionsEndRules(form).then(() => {
        this.setFeedback({ message: this.$t('solutions.config:feedback') })
      }).finally(() => {
        this.getEndRules()
      })
    },

    changeTab (index) {
      this.activeTab = index
    },

    fetchSolutionsData (isLoadMore = false) {
      return this.attemptGetSolutionsList(this.solutionsPagination)
        .then((pagination) => {
          this.solutionsPagination.lastPage = pagination.lastPage
          this.solutionsPagination.page = pagination.actualPage

          if (pagination.data) {
            if (!isLoadMore) {
              this.solutions = []
            }

            pagination.data.forEach(element => {
              this.solutions.push({
                name: element.name,
                id: element.id,
                solutionType: element.solutionType
              })
            })
          }

          return pagination
        })
    },

    fetchSolutionsHighlights () {
      this.setFetching(true)

      this.attemptGetGeneralSettings().then(({ data }) => {
        const ordenation = data.data.find((item) => item.field === 'solution_highlight_ordenation')

        this.formData.ordenation = ordenation.value ? ordenation.value : 'default'

        this.attemptGetListSolutionsHighlights().then(({ data }) => {
          this.formData.listHighlights = data.data.map((highlight) => {
            return {
              active: 1,
              ...highlight.solution
            }
          })

          this.setFetching(false)
        })
      })
    },

    searchSolution (search) {
      this.solutionsPagination.query.name = search
      this.solutionsPagination.page = 1
      this.solutionsPagination.lastPage = null
      this.solutionsPagination.query.only_name = true
      this.fetchSolutionsData()
    },

    loadMoreSolutions () {
      this.isLoadingMoreSolutions = true

      if (this.solutionsPagination.lastPage) {
        if (this.solutionsPagination.lastPage > this.solutionsPagination.page) {
          this.solutionsPagination.page += 1
        } else {
          this.isLoadingMoreSolutions = false

          return
        }
      }

      this.fetchSolutionsData(true)
        .then(() => {
          this.isLoadingMoreSolutions = false
        })
    },

    addToLinkedSolution (solutionId) {
      const solution = this.solutions.find(solution => solution.id === solutionId)

      solution.active = 1
      this.formData.listHighlights.push(solution)
    },

    removeLinkedSolution (solutionId) {
      this.formData.listHighlights = this.formData.listHighlights.filter(solution => solution.id !== solutionId)
    },

    moveUpLinkedSolution (solutionId) {
      const index = this.formData.listHighlights.findIndex(solution => solution.id === solutionId)

      if (index > 0) {
        const swapSolution = this.formData.listHighlights[index - 1]

        this.$set(this.formData.listHighlights, (index - 1), this.formData.listHighlights[index])
        this.$set(this.formData.listHighlights, index, swapSolution)
      }
    },

    moveDownLinkedSolution (solutionId) {
      const index = this.formData.listHighlights.findIndex(solution => solution.id === solutionId)

      if (index < (this.formData.listHighlights.length - 1)) {
        const swapSolution = this.formData.listHighlights[index + 1]

        this.$set(this.formData.listHighlights, (index + 1), this.formData.listHighlights[index])
        this.$set(this.formData.listHighlights, index, swapSolution)
      }
    },

    getSolutionTypeFormattedName (solutionType) {
      return solutionTypeFormatter(solutionType)
    },

    updateHighlights () {
      this.$v.$touch()
      const formatHighlights = {
        solutions: [],
        ordenation: this.formData.ordenation
      }

      if (this.formData.ordenation === 'manual') {
        formatHighlights.solutions = this.formData.listHighlights.map((solution, index) => {
          return {
            solutionId: solution.id,
            highlightPosition: index
          }
        })
      }

      if (!this.$v.$invalid) {
        this.attemptUpdateSolutionsHighlights(formatHighlights).then(() => {
          this.setFeedback({ message: this.$t('solutions.config.highlights:feedback.success') })
        }).then(() => {
          this.fetchSolutionsHighlights()
        }).catch(({ data }) => {
          if (data.error.message === 'too_many_solutions') {
            this.setFeedback({ message: this.$t('solutions.config.highlights:max.length.message') })

            return
          }

          this.setFeedback({ message: this.$t('solutions.config.highlights:feedback.error') })
        })
      } else {
        this.setFeedback({ message: this.$t('solutions.config.highlights:min.length.message') })
      }
    }
  }
}
</script>

<template>
  <div class="main-content">
    <ContentHeader
      :title="$t('solutions:header.title')"
      :description="$t('solutions:header.description')"
      background="/assets/images/themes/default/png/solutions_banner.png"
    >
      <Tabs
        slot="tabs"
        dark
        :links="tabLinks"
      />
    </ContentHeader>
    <FilterList>
      <Tabs
        slot="button"
        class="gamification-tabs"
        :links="configTabs"
        :index-active="activeTab"
        @tabChange="changeTab"
      />
    </FilterList>
    <div class="center solutions-config">
      <div
        v-if="activeTab === 0"
        class="solutions-config_rules"
      >
        <span>{{ $t('solutions.config:subtitle') }}</span>
        <Datatable :items="rules">
          <template slot="thead">
            <tr>
              <th class="th">
                {{ $t('solutions.config:datatable.header.1') }}
              </th>
              <th class="th text-center">
                {{ $t('solutions.config:datatable.header.2') }}
              </th>
            </tr>
          </template>

          <template
            slot="tbody"
            slot-scope="props"
          >
            <tr>
              <td class="td input-range">
                <InputField
                  v-model="props.item.minimumRequiredConclusion"
                  :label="props.item.name"
                  class="slider"
                  type="range"
                  :min="0"
                  :max="100"
                />
              </td>
              <td
                class="td"
                width="80"
              >
                <InputField
                  v-model="props.item.minimumRequiredConclusion"
                  :max="100"
                  :min="0"
                  type="number"
                  has-percent
                />
              </td>
            </tr>
          </template>
        </Datatable>
        <Action
          slot="button"
          primary
          fixed-width
          type="button"
          :text="$t('global:save')"
          class="btn-save"
          @click="updateEndRules"
        />
      </div>
      <div
        v-if="activeTab === 1"
        class="solutions-config_highlights"
      >
        <FormSection title="Ordenação">
          <Radio
            v-model="formData.ordenation"
            :items="orderOptions"
            horizontal
          />
        </FormSection>
        <div
          v-if="formData.ordenation === 'manual'"
          class="link-table"
        >
          <div class="linkable-items">
            <Datatable
              :is-loading-more="isLoadingMoreSolutions"
              :items="availableSolutions"
              hide-horizontal-line
              has-infinite-scroll
              has-fade
              empty-message="no_result"
              @loadMore="loadMoreSolutions"
            >
              <template slot="thead">
                <th
                  class="th"
                  width="300"
                >
                  {{ linkedSolutionsLabel }}
                </th>
                <th
                  class="th"
                  width="40"
                />
              </template>

              <template slot="tsearch">
                <FilterListSearch
                  slot="search"
                  class="filter-search"
                  :prepend-label="$t('global:form.solution-search')"
                  :hide-error-details="true"
                  @search="searchSolution"
                />
              </template>

              <template
                slot="tbody"
                slot-scope="props"
              >
                <tr
                  :key="props.index"
                  @click="addToLinkedSolution(props.item.id)"
                >
                  <td class="td td-full">
                    <span class="td-text bolder">{{ props.item.name }}</span>
                    <br>
                    <span class="td-text td-small">{{ getSolutionTypeFormattedName(props.item.solutionType) }}</span>
                  </td>
                  <td class="td td-button td-small text-right">
                    <Action
                      type="button"
                      icon="keyboard_arrow_right"
                      flat
                    />
                  </td>
                </tr>
              </template>
            </Datatable>
          </div>

          <Divisor orientation="vertical" />

          <div class="linked-items">
            <Datatable
              has-fade
              drag
              :items="formData.listHighlights"
              hide-horizontal-line
            >
              <template slot="thead">
                <th class="th">
                  {{ $t('solutions.config.highlights:datatable.header.2') }}
                </th>
                <th
                  class="th"
                  width="40"
                />
              </template>
              <template
                slot="tbody"
                slot-scope="props"
              >
                <tr
                  v-if="props.item.active"
                  :key="props.index"
                  class="tr-draggable"
                >
                  <td class="td td-drag">
                    <Icon
                      name="drag"
                      size="is-small"
                    />
                  </td>
                  <td class="td td-full">
                    <span class="td-text bolder">{{ props.item.name }}</span>
                  </td>
                  <td class="td td-button td-small text-right">
                    <Action
                      class="order-action"
                      type="button"
                      icon="arrow_drop_down"
                      flat
                      @click="moveDownLinkedSolution(props.item.id)"
                    />
                    <Action
                      class="order-action"
                      type="button"
                      icon="arrow_drop_up"
                      flat
                      @click="moveUpLinkedSolution(props.item.id)"
                    />
                    <Action
                      class="order-action"
                      type="button"
                      icon="close"
                      flat
                      @click="removeLinkedSolution(props.item.id)"
                    />
                  </td>
                </tr>
              </template>
            </Datatable>
          </div>
        </div>
        <Action
          slot="button"
          primary
          fixed-width
          type="button"
          :text="$t('global:save')"
          class="btn-save"
          @click="updateHighlights"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">

.solutions-config {
  padding: 10px;
  display: flex;
  flex-direction: column;

  .solutions-config_rules {
    h3 {
      color: rgba(0,0,0,0.5);
      font-family: 'Quicksand';
      padding-bottom: 10px;
      font-weight: bold;
      font-size: 2.2em;
      height: auto;
    }

    span {
      font-size: 1.25em;
    }
  }

  .datatable-wrapper {
    padding-top: 35px;
  }

  table .td {
    border: none;

    &.input-range {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 80%;

      .form-input{
        padding: 8px 0;

        .form-item.has-value.has-floating-label .form-label {
          font-size: 1.5em;
          text-transform: capitalize;
          font-weight: 500;
        }
      }
    }
  }

  .btn-save{
    align-self: center;
    margin-top: 15px;
  }

  .solutions-config_highlights {
    display: flex;
    flex-direction: column;
    align-items: center;

    .link-table {
      width: 100%;
    }

    .form-section {
      .form-section-title {
        text-align: center;
      }
    }
  }

}

</style>
