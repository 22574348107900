export const certificates = getters => {
  return getters.certificates
}

export const entrepreneurProfiles = getters => {
  return getters.entrepreneurProfiles
}

export const profiles = getters => {
  return getters.profiles
}

export const surveyList = getters => {
  return getters.surveyList
}
