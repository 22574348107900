import * as services from '../services'
import * as TYPES from './mutations-types'

export const attemptGetUsersList = ({ commit }, pagination) => {
  return services.getUsersList(pagination)
    .then(usersList => {
      commit(TYPES.SET_USERS_LIST, usersList.data.data)

      return {
        firstPage: usersList.data.firstPage,
        lastPage: usersList.data.lastPage,
        nextPage: usersList.data.nextPage,
        previousPage: usersList.data.previousPage,
        total: usersList.data.total
      }
    })
}

export const attemptManageUser = ({ commit, getters }, userId) => {
  const user = getters.usersList.find(item => parseInt(item.id) === parseInt(userId))

  commit(TYPES.SET_MANAGE_USER, user || null)
}

export const attemptUpdateUser = ({ commit }, userPayload) => {
  commit(TYPES.UPDATE_USER, userPayload)

  return services.updateUser(userPayload)
}

export const attemptSendValidationEmail = (_, userId) => {
  return services.sendValidationEmail(userId)
}

export const attemptGetUser = (_, userId) => {
  return services.getUser(userId)
}

export const attemptGetAddressByCep = (_, cep) => {
  return services.getAddressByCep(cep)
}

export const attemptListEducationLevels = () => {
  return services.listEducationLevels()
}

export const attemptListStates = () => {
  return services.listStates()
}

export const attemptListCities = (_, state) => {
  return services.listCities(state)
}

export const attemptListBusinessSectors = () => {
  return services.listBusinessSectors()
}

export const attemptListBusinessSizes = () => {
  return services.listBusinessSizes()
}

export const attemptListCnaes = (_, search) => {
  return services.listCnaes(search)
}

export const attemptGetCompanyDataByCnpj = (_, cnpj) => {
  return services.getCompanyDataByCnpj(cnpj)
}

export const attemptCreateAdmin = (_, adminPayload) => {
  return services.createAdmin(adminPayload)
}

export const attemptUpdateAdmin = (_, adminPayload) => {
  return services.updateAdmin(adminPayload)
}

export const attemptCreateUserApi = (_, userApiPayload) => {
  return services.createUserApi(userApiPayload)
}

export const attemptUpdateUserApi = (_, userApiPayload) => {
  return services.updateUserApi(userApiPayload)
}

export const attemptRemoveUser = (_, userId) => {
  return services.removeUser(userId)
}

export const attemptGetUserSessions = ({ commit }, data) => {
  commit(TYPES.SET_USER_SESSIONS_LIST, [])

  return services.getUserSessions(data.userId, data.pagination)
    .then(sessions => {
      commit(TYPES.SET_USER_SESSIONS_LIST, sessions.data.data)

      return {
        firstPage: sessions.data.firstPage,
        lastPage: sessions.data.lastPage,
        nextPage: sessions.data.nextPage,
        previousPage: sessions.data.previousPage,
        total: sessions.data.total
      }
    })
}

export const attemptGetUserLogins = ({ commit }, data) => {
  commit(TYPES.SET_USER_LOGINS_LIST, [])

  return services.getUserLogins(data.userId, data.pagination)
    .then(sessions => {
      commit(TYPES.SET_USER_LOGINS_LIST, sessions.data.data)

      return {
        firstPage: sessions.data.firstPage,
        lastPage: sessions.data.lastPage,
        nextPage: sessions.data.nextPage,
        previousPage: sessions.data.previousPage,
        total: sessions.data.total
      }
    })
}

export const attemptGetUserEmailLogs = ({ commit }, data) => {
  commit(TYPES.SET_USER_EMAIL_LOGS_LIST, [])

  return services.getUserEmailLogs(data.pagination)
    .then(logs => {
      commit(TYPES.SET_USER_EMAIL_LOGS_LIST, logs.data.data)

      return {
        firstPage: logs.data.firstPage,
        lastPage: logs.data.lastPage,
        nextPage: logs.data.nextPage,
        previousPage: logs.data.previousPage,
        total: logs.data.total
      }
    })
}

export const attemptGetUserLearningPath = ({ commit }, data) => {
  commit(TYPES.SET_USER_LEARNING_PATH_LIST, [])

  return services.getUserLearningPath(data.userId, data.pagination)
    .then(learningPath => {
      commit(TYPES.SET_USER_LEARNING_PATH_LIST, learningPath.data.data)

      return {
        firstPage: learningPath.data.firstPage,
        lastPage: learningPath.data.lastPage,
        nextPage: learningPath.data.nextPage,
        previousPage: learningPath.data.previousPage,
        total: learningPath.data.total
      }
    })
}

export const attemptGetSessionsBySolutionSlug = (_, data) => {
  return services.getSessionsBySolutionSlug(data.slug, data.userId)
}

export const attemptCancelEnrollUserInSession = (_, data) => {
  return services.cancelEnrollUserInSession(data)
}

export const attemptEnrollUserInSession = (_, data) => {
  return services.enrollUserInSession(data.sessionId, data.userId)
}

export const attemptCreateUser = (_, userData) => {
  return services.createUser(userData)
}

export const attemptCreateUserBatch = (_, data) => {
  return services.createUserBatch(data)
}

export const attemptCreateEnrollmentBatch = (_, data) => {
  return services.createEnrollmentBatch(data)
}

export const attemptGetUserByCpf = (_, cpf) => {
  return services.getUserByCpf(cpf)
}

export const attemptImpersonateUser = (_, userId) => {
  return services.impersonateUser(userId)
}

export const attemptGetBatchList = (_, pagination) => {
  return services.getBatchList(pagination)
    .then(batchList => {
      return {
        data: batchList.data.data,
        firstPage: batchList.data.firstPage,
        lastPage: batchList.data.lastPage,
        nextPage: batchList.data.nextPage,
        previousPage: batchList.data.previousPage,
        total: batchList.data.total
      }
    })
}

export const attemptGetBatchDetailsList = (_, batchId) => {
  return services.getBatchDetailsList(batchId)
}
