const SessionsList = () => import('./components/SessionsList')
const SessionsManage = () => import('./components/SessionsManage')

export default [
  {
    name: 'sessions.index',
    path: '/sessions',
    component: SessionsList,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'sessions',
        access: 'read'
      }
    }
  },
  {
    name: 'sessions.manage',
    path: '/sessions/manage/:id?',
    component: SessionsManage,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'sessions',
        access: 'write'
      }
    }
  },
  {
    name: 'sessions.attendance',
    path: '/sessions/manage/:id(\\d+)/attendance',
    component: SessionsManage,
    meta: {
      indexTab: 1,
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'session_attendance',
        access: 'write'
      }
    }
  }
]
