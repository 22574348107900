import { routes as Auth } from './Auth'
import { routes as Dashboard } from './Dashboard'
import { routes as Sessions } from './Sessions'
import { routes as Solutions } from './Solutions'
import { routes as Tracks } from './Tracks'
import { routes as Programs } from './Programs'
import { routes as Reports } from './Reports'
import { routes as Users } from './Users'
import { routes as ThematicPages } from './ThematicPages'
import { routes as Management } from './Management'
import { routes as Comments } from './Comments'
import { routes as Tools } from './Tools'
import { Email, MediationPlan, Filter } from './Notification'
import { Finance } from './Finance'
import NotFoundPage from '@/components/general/NotFoundPage'
import UnderConstruction from './Underconstruction/underconstruction'
import ServiceUnavailablePage from '@/components/general/ServiceUnavailablePage'

const notFoundRoute = [
  {
    name: 'index',
    path: '*',
    redirect: { name: 'solutions.index' },
    meta: {
      requiresAuth: false,
      hideNavbar: false
    }
  },

  {
    name: 'underconstruction',
    path: '/underconstruction',
    component: UnderConstruction,
    meta: {
      requiresAuth: false,
      hideNavbar: false
    }
  },

  {
    name: 'pages.underconstruction',
    path: '/underconstruction/pages',
    component: UnderConstruction,
    meta: {
      requiresAuth: false,
      hideNavbar: false
    }
  },

  {
    name: 'management.underconstruction',
    path: '/underconstruction/management',
    component: UnderConstruction,
    meta: {
      requiresAuth: false,
      hideNavbar: false
    }
  },

  {
    name: 'tickets.underconstruction',
    path: '/underconstruction/tickets',
    beforeEnter () {
      window.open(
        'http://atendimento.dotgroup.com.br/',
        '_blank'
      )
    }
  },

  {
    name: 'catchall',
    path: '*',
    redirect: { name: '404' },
    meta: {
      requiresAuth: false,
      hideNavbar: false
    }
  },

  {
    name: '404',
    path: '/404',
    component: NotFoundPage,
    meta: {
      requiresAuth: false,
      hideNavbar: false
    }
  },
  {
    name: 'unavailable',
    path: '/503',
    component: ServiceUnavailablePage,
    meta: {
      requiresAuth: false,
      hideNavbar: false
    }
  }
]

export default [
  ...Auth,
  ...Dashboard,
  ...Solutions,
  ...Tracks,
  ...Programs,
  ...Users,
  ...Reports,
  ...Sessions,
  ...ThematicPages,
  ...Management,
  ...Comments,
  ...Tools,
  ...Email,
  ...MediationPlan,
  ...Filter,
  ...Finance,
  ...notFoundRoute
]
