<script>
export default {
  name: 'Underconstruction'
}
</script>
<template>
  <section class="underconstruction-page">
    <p>{{ $t('global:underconstruction') }}</p>
    <img src="/assets/images/themes/default/svg/underconstruction.svg">
  </section>
</template>
<style src="@/assets/styles/themes/default/underconstruction.css"></style>
