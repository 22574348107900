<script>
import { mapActions } from 'vuex'

export default {
  name: 'SessionsList',
  components: {
    Action: () => import('@/components/general/Action'),
    Autocomplete: () => import('@/components/general/Autocomplete'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Datatable: () => import('@/components/general/Datatable'),
    Datepicker: () => import('@/components/general/Datepicker'),
    Dropdown: () => import('@/components/general/Dropdown'),
    DropdownLink: () => import('@/components/general/DropdownLink'),
    EmptyMessage: () => import('@/components/general/EmptyMessage'),
    FilterList: () => import('@/components/general/FilterList'),
    FilterListSearch: () => import('@/components/general/FilterListSearch'),
    Modal: () => import('@/components/general/Modal'),
    ModalConfirm: () => import('@/components/macro/ModalConfirm'),
    Pagination: () => import('@/components/general/Pagination')
  },

  data () {
    return {
      pagination: {
        query: {},
        filter: {
          type: 'closed',
          id_solution: null,
          init_date: null,
          finish_date: null
        },

        order: {
          session_ini_date: 'DESC',
          session_name: 'DESC',
          session_status: 'ASC'
        },

        page: 1,
        limit: 12,
        lastPage: null
      },

      modalRemoveOpen: false,
      modalSessionsFiltersOpen: false,
      sessionId: null,
      solutionTerm: null,
      solutionsList: null
    }
  },

  computed: {
    sessionsList () {
      return this.$store.getters.sessionsList
    },

    canWrite () {
      return this.getContextPermission('sessions') === 'write'
    }
  },

  watch: {
    'pagination.page' () {
      this.getSessionList()
    }
  },

  created () {
    this.getSessionList()
    this.getSolutions()
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetSessionsList',
      'attemptGetSolutionsList',
      'attemptManageSession',
      'attemptCancelSession'
    ]),

    prevPage () {
      if (this.pagination.page > 1) {
        this.pagination.page -= 1
      }
    },

    nextPage () {
      this.pagination.page += 1
    },

    firstPage () {
      this.pagination.page = 1
    },

    lastPage () {
      this.pagination.page = this.pagination.lastPage
    },

    getSessionList () {
      this.setFetching(true)
      const idSolution = this.pagination.filter.id_solution

      if (idSolution) {
        this.pagination.filter.id_solution = this.pagination.filter.id_solution[0].value
      }

      this.attemptGetSessionsList(this.pagination).then(pagination => {
        this.pagination.lastPage = pagination.lastPage
      }).finally(() => {
        this.setFetching(false)

        if (idSolution) {
          this.pagination.filter.id_solution = idSolution
        }
      })
    },

    createNewSession () {
      this.attemptManageSession(null)
      this.$router.push({ name: 'sessions.manage' })
    },

    searchSession (search) {
      this.pagination.query = {
        session_name: search
      }

      this.pagination.page = 1
      this.getSessionList()
    },

    edit (sessionId) {
      this.setFetching(true)

      this.attemptManageSession(sessionId).then(() => {
        this.$router.push({
          name: 'sessions.manage',
          params: { id: sessionId }
        })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    remove () {
      this.modalRemoveOpen = false
      this.setFetching(true)

      this.attemptCancelSession(this.sessionId).then(() => {
        this.setFeedback({ message: this.$t('sessions:feedback.cancel.success') })
        this.getSessionList()
      }).catch(() => {
        this.setFeedback({ message: this.$t('sessions:feedback.cancel.error') })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    confirmRemove (idSession) {
      this.sessionId = idSession
      this.modalRemoveOpen = true
    },

    cancelRemove () {
      this.modalRemoveOpen = false
    },

    goToAttendance (id) {
      this.attemptManageSession(id).then(() => {
        this.$router.push({
          name: 'sessions.attendance',
          params: { id: id }
        })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    doCopy (idSolution, idSession) {
      const copiedText = `${process.env.VUE_APP_PORTAL_URL}matricular/${idSolution}/${idSession}`

      this.$copyText(copiedText).then(() => {
        this.setFeedback({ message: `Link copiado!<br /> ${copiedText}` })
      }, () => {
        this.setFeedback({ message: `Copie o link de matrícula:<br /> ${copiedText}` })
      })
    },

    goToAccess (url) {
      if (url === null) {
        this.setFeedback({ message: this.$t('sessions.manage:feedback.access.error') })

        return
      }

      window.open(url)
    },

    openFilterModal () {
      this.modalSessionsFiltersOpen = true
    },

    closeFilterModal () {
      this.modalSessionsFiltersOpen = false
    },

    filter () {
      this.closeFilterModal()
      this.getSessionList()
    },

    fetchSolutions (search) {
      this.getSolutions(search)
    },

    getSolutions (search = null) {
      this.solutionsList = []

      this.attemptGetSolutionsList({
        filter: {
          active: 'true',
          session_type: 'closed'
        },
        query: { name: search },
        order: { name: 'ASC' },
        limit: 300
      }).then(res => {
        res.data.filter(solution => {
          if (!search || (search && solution.name.toLowerCase().indexOf(search.toLowerCase()) > -1)) {
            this.solutionsList.push({
              label: solution.name,
              value: solution.id
            })
          }

          return solution
        })
      })
    },

    clearFilters () {
      this.pagination.filter.id_solution = null
      this.pagination.filter.init_date = null
      this.pagination.filter.finish_date = null
    }
  }
}
</script>

<template>
  <div class="main-content">
    <ContentHeader
      :title="$t('sessions:header.title')"
      :description="$t('sessions:header.description')"
      background="/assets/images/themes/default/png/sessions_banner.png"
    />
    <FilterList>
      <Action
        v-if="canWrite"
        slot="button"
        primary
        large
        fixed-width
        type="button"
        :text="$t('sessions.list:btn.add')"
        @click="createNewSession"
      />
      <Action
        slot="button"
        flat
        fixed-width
        type="button"
        :text="$t('sessions.list:btn.filter')"
        @click="openFilterModal"
      />
      <FilterListSearch
        slot="search"
        :hide-error-details="true"
        @search="searchSession"
      />
    </FilterList>
    <div
      v-if="sessionsList.length > 0"
      class="center sessions-table"
    >
      <Datatable :items="sessionsList">
        <template slot="thead">
          <tr>
            <th class="th">
              {{ $t('sessions.list:datatable.header.1') }}
            </th>
            <th class="th">
              {{ $t('sessions.list:datatable.header.2') }}
            </th>
            <th class="th">
              {{ $t('sessions.list:datatable.header.3') }}
            </th>
            <th class="th">
              {{ $t('sessions.list:datatable.header.5') }}
            </th>
            <th class="th">
              {{ $t('sessions.list:datatable.header.4') }}
            </th>
            <th
              class="th"
              width="40"
            />
          </tr>
        </template>
        <template
          slot="tbody"
          slot-scope="props"
        >
          <tr>
            <td class="td">
              <span class="td-text bolder">{{ props.item.name }}</span>
            </td>
            <td class="td">
              <span class="td-text">{{ formatDateTime(props.item.initDate) }}</span>
            </td>
            <td class="td">
              <span class="td-text">{{ formatDateTime(props.item.finishDate) }}</span>
            </td>
            <td class="td">
              <span class="td-text">{{ props.item.enrollments }} / {{ props.item.vacancies }}</span>
            </td>
            <td class="td">
              <span
                v-if="!props.item.canceled"
                class="td-tag active-ribbon"
              >{{ $t('sessions.list:active') }}</span>
              <span
                v-else
                class="td-tag inactive-ribbon"
              >{{ $t('sessions.list:canceled') }}</span>
            </td>
            <td
              class="td td-actions"
              width="40"
            >
              <Dropdown
                v-if="canWrite && !props.item.canceled"
                icon="dots-vertical"
                right
              >
                <DropdownLink
                  :text="$t('global:edit')"
                  @click="edit(props.item.id)"
                />
                <DropdownLink
                  v-if="props.item.solutionType === 'videoconferencia'"
                  :text="$t('sessions.manage:access')"
                  @click="goToAccess(props.item.accessUrl)"
                />
                <DropdownLink
                  v-if="props.item.solutionType === 'videoconferencia'"
                  :text="$t('session.attendance')"
                  @click="goToAttendance(props.item.id)"
                />
                <DropdownLink
                  v-if="!props.item.canceled && canWrite"
                  :text="$t('global:cancel')"
                  @click="confirmRemove(props.item.id, props.index)"
                />

                <DropdownLink
                  :text="$t('sessions.manage:menu.direct-url')"
                  @click="doCopy(props.item.solutionId, props.item.id)"
                />
              </Dropdown>
            </td>
          </tr>
        </template>
      </Datatable>
      <Pagination
        :active-page="pagination.page"
        :page-count="pagination.lastPage"
        @firstPage="firstPage"
        @lastPage="lastPage"
        @nextPage="nextPage"
        @previousPage="prevPage"
      />
    </div>
    <div v-else>
      <EmptyMessage>
        <h2>{{ $t('sessions:list.empty.title') }}</h2>
        <p>{{ $t('sessions:list.empty.message') }}</p>
      </EmptyMessage>
    </div>
    <ModalConfirm
      :active="modalRemoveOpen"
      :title="$t('sessions.manage:cancel.title')"
      :description="$t('sessions.manage:cancel.description')"
      @confirmAction="remove"
      @cancelAction="cancelRemove"
    />
    <Modal
      class="modal-sessions-filters"
      :active="modalSessionsFiltersOpen"
      :cancel="true"
      @close="closeFilterModal"
    >
      <div class="modal-form modal-sessions-filters">
        <h2 class="modal-title">
          {{ $t('sessions.list:filter.title') }}
        </h2>
        <span class="modal-subtitle">{{ $t('sessions.list:filter.description') }}</span>
        <form @submit.prevent="filter">
          <Autocomplete
            v-model="pagination.filter.id_solution"
            :label="$t('sessions.list:solution.name')"
            :items="solutionsList"
            async
            dark
            enable-emit-inside
            class="sessions-solutions-field"
            :max-value="9999"
            option-property="label"
            @search="fetchSolutions"
          />
          <Datepicker
            v-model="pagination.filter.init_date"
            dark
            class="sessions-date"
            hide-optional-text
            :label="$t('sessions.list:start')"
            min="1900-01-01"
          />
          <Datepicker
            v-model="pagination.filter.finish_date"
            dark
            class="sessions-date"
            hide-optional-text
            :label="$t('sessions.list:end')"
            min="1900-01-01"
          />
          <div class="form-buttons">
            <Action
              primary
              large
              dark
              fixed-width
              type="button"
              :text="$t('sessions.list:btn.clear.filter.action')"
              @click="clearFilters"
            />
            <Action
              secondary
              large
              dark
              submit
              fixed-width
              type="button"
              :text="$t('sessions.list:btn.filter.action')"
            />
          </div>
        </form>
      </div>
    </Modal>
  </div>
</template>

<style src="@/assets/styles/themes/default/sessions.css"></style>
