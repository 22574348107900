export const getUserToken = getters => {
  return getters.userToken
}

export const getUserRefreshToken = getters => {
  return getters.userRefreshToken
}

export const getUserData = getters => {
  return getters.userData
}
