<script>
import { mapActions } from 'vuex'
import { required, requiredIf } from 'vuelidate/lib/validators'
export default {
  name: 'ManagementBannersEdit',
  components: {
    Action: () => import('@/components/general/Action'),
    ActionBar: () => import('@/components/general/ActionBar'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    FormSection: () => import('@/components/general/FormSection'),
    InputField: () => import('@/components/general/InputField'),
    Upload: () => import('@/components/general/Upload'),
    Radio: () => import('@/components/general/Radio'),
    SelectField: () => import('@/components/general/SelectField')
  },

  data () {
    return {
      backUrl: {
        name: 'management.banners',
        params: { activeTab: this.$route.params.location }
      },

      formData: {
        id: null,
        title: null,
        description: null,
        order: 0,
        image: null,
        type: 'noAction',
        actionsOptions: [
          {
            label: this.$t('global:form.management.banner.edit:no.action'),
            value: 'noAction'
          },
          {
            label: this.$t('global:form.management.banner.edit:image'),
            value: 'image'
          }
        ],

        link: null,
        active: 0,
        location: this.$route.params.location
      },

      locationList: [
        {
          text: 'Conteúdos',
          value: 'content'
        },
        {
          text: 'Ferramentas',
          value: 'tools'
        }
      ]
    }
  },

  validations: {
    formData: {
      title: { required: false },
      description: { required: false },
      order: { required },
      image: { required },
      type: { required },
      link: {
        required: requiredIf(function () {
          return this.formData.type !== 'noAction'
        })
      },

      location: { required }
    }
  },

  computed: {
    isEditing () {
      return this.$route.params.id
    },

    descriptionBannerAction () {
      return this.formData.type === 'button' ? this.$t('global:form.management.banner.edit:action.label') : ''
    }
  },

  created () {
    if (this.isEditing) {
      this.setFetching(true)

      this.attemptManageBanner(this.$route.params.id)
        .then(() => {
          this.formData = {
            ...this.formData,
            ...this.$store.getters.getManagingBanner
          }

          this.formData.active = this.formData.active ? 1 : 0
        })
        .catch(() => {
          this.setFeedback({ message: this.$t('management.banner.load:fetch.error') })
          this.$router.push(this.backUrl)
        })
        .finally(() => {
          this.setFetching(false)
        })
    }
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptManageBanner',
      'attemptSaveBanner',
      'attemptUpdateBanner'
    ]),

    leave () {
      this.$nextTick(() => {
        this.$router.push(this.backUrl)
      })
    },

    submitCreation () {
      this.setFetching(true)
      const formData = this.formatFormData()

      this.attemptSaveBanner(formData)
        .then(() => {
          this.setFeedback({ message: this.$t('management.banner.save:feedback.success') })
        })
        .catch(() => {
          this.setFeedback({ message: this.$t('management.banner.save:feedback.error') })
        })
        .finally(() => {
          this.setFetching(false)
          this.$router.push(this.backUrl)
        })
    },

    submitUpdate () {
      this.setFetching(true)
      const formData = this.formatFormData()

      this.attemptUpdateBanner(formData)
        .then(() => {
          this.setFeedback({ message: this.$t('management.banner.save:feedback.success') })
        })
        .catch(() => {
          this.setFeedback({ message: this.$t('management.banner.save:feedback.error') })
        })
        .finally(() => {
          this.setFetching(false)
          this.$router.push(this.backUrl)
        })
    },

    submit () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.formData.id ? this.submitUpdate() : this.submitCreation()
      } else {
        this.setFeedback({ message: this.$t('global:feedback.validation.error') })
      }
    },

    formatFormData () {
      const data = {}

      data.id = this.formData.id || null
      data.title = this.formData.title
      data.description = this.formData.description
      data.order = this.formData.order
      data.image = this.formData.image
      data.type = this.formData.type
      data.link = this.formData.type !== 'noAction' ? this.formData.link : null
      data.active = this.formData.active ? 1 : 0
      data.location = this.formData.location

      return data
    }
  }
}
</script>

<template>
  <div class="main-content management-banner-create">
    <ContentHeader
      :title="isEditing ? formData.title : $t('management.banner.edit:header.title')"
      dark-theme
      fixed
    >
      <Action
        slot="back"
        type="button"
        :text="$t('global:back.management')"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave()"
      />
      <ActionBar slot="actionbar" />
      <template slot="buttons">
        <Action
          :text="$t('global:form.save')"
          type="button"
          dark
          flat
          @click="submit()"
        />
      </template>
    </ContentHeader>

    <div class="main-content-inner">
      <div class="center">
        <div class="management-banner-create-header">
          <h2 class="management-banner-create-title">
            {{ $t('management.banner.edit:main.title') }}
          </h2>
        </div>
        <form
          class="form"
          @submit.prevent="submit()"
        >
          <InputField
            v-model="formData.title"
            :label="$t('global:form.management.banner.edit:title')"
            :validation="$v.formData.title"
            :counter="50"
          />

          <InputField
            v-model="formData.description"
            :label="$t('global:form.management.banner.edit:description')"
            :validation="$v.formData.description"
            :counter="100"
          />

          <InputField
            v-model="formData.order"
            :under-description="$t('global.form.management.banner.edit:order.description')"
            type="number"
            :min="0"
            :label="$t('global:form.management.banner.edit:order')"
            :validation="$v.formData.order"
          />

          <SelectField
            v-model="formData.location"
            :label="$t('global:form.management.banner.edit:banner.location.label')"
            :items="locationList"
            :validation="$v.formData.location"
            :under-description="$t('global:form.management.banner.edit:banner.location.under-description')"
          />

          <FormSection :title="$t('global:form.management.banner.edit:banner.image')">
            <Upload
              v-model="formData.image"
              icon="image-multiple"
              :label="$t('global:form.management.banner.edit:banner.image.add')"
              :description="$t('global:form.management.banner.edit:background.size')"
              cropper
              :width="1900"
              :height="500"
              :preview="0.3"
              :validation="$v.formData.image"
            />
          </FormSection>

          <FormSection :title="$t('global:form.management.banner.edit:action')">
            <Radio
              v-model="formData.type"
              :items="formData.actionsOptions"
              horizontal
              :validation="$v.formData.type"
            />

            <InputField
              v-if="formData.type !== 'noAction'"
              v-model="formData.link"
              :under-description="descriptionBannerAction"
              :label="$t('global:form.management.banner.edit:action.link')"
              :validation="$v.formData.link"
              :counter="150"
            />
          </FormSection>
        </form>
      </div>
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/banners.css"></style>
