<script>
export default {
  name: 'PointsRule',
  components: {
    Datatable: () => import('@/components/general/Datatable'),
    Pagination: () => import('@/components/general/Pagination')
  },

  data () {
    return {
      mockTableItems: [
        {
          rule: 'Cadastro Completo e Validado',
          interval: 'Pontuação única',
          points: 10
        },
        {
          rule: 'Iniciar um Curso',
          interval: '1x a cada 15 dias',
          points: 10
        },
        {
          rule: 'Iniciar uma Trilha',
          interval: '1x por mês',
          points: 15
        },
        {
          rule: 'Iniciar um Programa',
          interval: '1x por mês',
          points: 20
        },
        {
          rule: 'Finalizar um Curso',
          interval: '-',
          points: 25
        },
        {
          rule: 'Finalizar uma Trilha',
          interval: '-',
          points: 30
        },
        {
          rule: 'Finalizar um Programa',
          interval: '-',
          points: 50
        },
        {
          rule: 'Responder uma Pesquisas de Satisfação',
          interval: '-',
          points: 80
        }
      ],

      pagination: {
        page: 1
      }
    }
  },

  computed: {
    pageItems () {
      return this.mockTableItems.slice((this.pagination.page - 1) * 12, ((this.pagination.page - 1) * 12) + 12)
    }
  },

  methods: {
    firstPage () {
      this.pagination.page = 1
    },

    lastPage () {
      this.pagination.page = this.getLastPage
    },

    nextPage () {
      if (this.pagination.page < this.getLastPage) {
        this.pagination.page += 1
      }
    },

    prevPage () {
      if (this.pagination.page > 1) {
        this.pagination.page -= 1
      }
    },

    getLastPage () {
      return this.countPages(this.mockTableItems.length, 12)
    }
  }
}
</script>

<template>
  <div
    v-if="pageItems.length > 0"
    class="center rule-table"
  >
    <Datatable
      :class="{'first-page': pagination.page === 1}"
      :items="pageItems"
    >
      <template slot="thead">
        <tr>
          <th class="th">
            {{ $t('rule.list:datatable.header.1') }}
          </th>
          <th class="th">
            {{ $t('rule.list:datatable.header.2') }}
          </th>
          <th class="th">
            {{ $t('rule.list:datatable.header.3') }}
          </th>
        </tr>
      </template>
      <template
        slot="tbody"
        slot-scope="props"
      >
        <tr>
          <td class="td">
            <span class="td-text">{{ props.item.rule }}</span>
          </td>
          <td class="td">
            <span class="td-text">{{ props.item.interval }}</span>
          </td>
          <td class="td">
            <span class="td-text">{{ props.item.points }}</span>
          </td>
        </tr>
      </template>
    </Datatable>
    <Pagination
      :active-page="pagination.page"
      :page-count="getLastPage"
      @firstPage="firstPage"
      @lastPage="lastPage"
      @nextPage="nextPage"
      @previousPage="prevPage"
    />
  </div>
</template>

<style>
.rule-table .datatable .th {
  padding-left: 30px;
}
.rule-table .datatable .td {
  padding: 16px 80px 16px 30px;
}
</style>
