import { app } from '@/main'

export const HEADER_TAB_LINKS = [
  {
    location: { name: 'notifications.emails' },
    text: app.$t('notifications.tabs:link.1')
  },

  {
    location: { name: 'notifications.mediation-plan' },
    text: app.$t('notifications.tabs:link.2')
  },

  {
    location: { name: 'notifications.filters' },
    text: app.$t('notifications.tabs:link.3')
  }
]
