import { http } from '@/support/http'
import { createQuery } from '@/support/utils/paginatorQueryBuilder'

export const getShowcaseThemeList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`showcase/themes?${queryString}`)
}

export const getShowcaseEntrepreneurProfileList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`showcase/entrepreneur_profiles?${queryString}`)
}

export const getShowcaseLearningPathList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`showcase/learning_paths?${queryString}`)
}

export const getShowcaseThematicPageList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`showcase/thematic_pages?${queryString}`)
}

export const getShowcaseSolutionTypeList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`showcase/solutions/types?${queryString}`)
}

export const getShowcaseViewSystemEmail = (idSystemEmail) => {
  return http.get(`showcase/notification/system_email/${idSystemEmail}/preview`)
}

export const getShowcaseThematicPageTypeList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`showcase/thematic_pages/types?${queryString}`)
}

export const getShowcaseAddressByCep = (cep) => {
  return http.get('showcase/cep/' + cep + '/address')
}

export const getShowcaseBusinessSectorList = () => {
  return http.get('showcase/business_sectors')
}

export const getShowcaseBusinessSizeList = () => {
  return http.get('showcase/business_sizes')
}

export const getShowcaseCnaeList = (description) => {
  return http.get(`showcase/cnaes?query[description]=${description}&filter=&order=&page=1&limit=`)
}

export const getShowcaseEducationLevelList = () => {
  return http.get('showcase/education_levels')
}

export const getShowcaseStateList = () => {
  return http.get('showcase/states')
}

export const getShowcaseCityList = (stateId) => {
  return http.get('showcase/state/' + stateId + '/cities')
}

export const getShowcaseCompanyDataByCnpj = (cnpj) => {
  return http.get('/showcase/cnpj/' + cnpj + '/company')
}

export const getShowcaseThematicPageTypesList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`showcase/thematic_pages/types?${queryString}`)
}
