import { http } from '@/support/http'
import { createQuery } from '@/support/utils/paginatorQueryBuilder'
import { parseToFormDataWithArray } from '@/support/payloadParser'

export const getSessionsList = (pagination) => {
  const queryString = createQuery(pagination)
  return http.get(`api/sessions/manage?${queryString}`)
}

export const getSession = (idSession) => {
  return http.get(`api/session/${idSession}`)
}

export const getCertificatesList = (pagination) => {
  const queryString = createQuery(pagination)
  return http.get(`api/session/certificates?${queryString}`)
}

export const getSurveysList = (pagination) => {
  const queryString = createQuery(pagination)
  return http.get(`api/session/surveys?${queryString}`)
}

export const getThemesList = (pagination) => {
  const queryString = createQuery(pagination)
  return http.get(`showcase/themes?${queryString}`)
}

export const getEntrepreneurProfilesList = (pagination) => {
  const queryString = createQuery(pagination)
  return http.get(`showcase/entrepreneur_profiles?${queryString}`)
}

export const getLearningPathsList = (pagination) => {
  const queryString = createQuery(pagination)
  return http.get(`showcase/learning_paths?${queryString}`)
}

export const getThematicPagesList = (pagination) => {
  const queryString = createQuery(pagination)
  return http.get(`showcase/thematic_pages?${queryString}`)
}

export const getSessionStudentsList = (idSession, pagination) => {
  return http.get(`/api/session/${idSession}/attendances?${createQuery(pagination)}`)
}

export const saveSessionAttendanceList = (idSession, studentList) => {
  return http.post(`/api/session/${idSession}/attendances`, studentList)
}

export const saveSession = (data) => {
  const form = parseToFormDataWithArray(data)
  // if (data.image && typeof data.image == 'object' ) {
  //   form.append('image', data.image, data.image.name)
  // }
  // if (data.card_image && typeof data.card_image == 'object' ) {
  //   form.append('card_image', data.card_image, data.card_image.name)
  // }
  return http.post('api/session', form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const updateSession = ({ id, data }) => {
  const form = parseToFormDataWithArray(data)
  return http.post(`api/session/${id}`, form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const cancelSession = (idSession) => {
  return http.post(`api/session/cancel/${idSession}`)
}

export const listClosedSolutions = () => {
  const pagination = {
    query: {},
    filter: {
      session_type: 'closed'
    },
    order: { name: 'ASC' },
    page: 1,
    limit: 999999,
    lastPage: null
  }
  const queryString = createQuery(pagination)
  return http.get(`api/solutions?${queryString}`)
}
