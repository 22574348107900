const FinanceModule = () => import('./FinanceModule.vue')
const ListPage = () => import('./pages/ListPage.vue')
const ViewerPage = () => import('./pages/ViewerPage.vue')

export const routes = [
  {
    path: '/',
    component: FinanceModule,
    children: [
      {
        path: '/finance',
        name: 'finance.list',
        component: ListPage
        // requiresAuth: true,
        // permission: {
        //   context: 'finances',
        //   access: 'read'
        // }
      },

      {
        path: 'finance/:id?',
        name: 'finance.view',
        component: ViewerPage
        // requiresAuth: true,
        // permission: {
        //   context: 'finances',
        //   access: 'read'
        // }
      }
    ]
  }
]
