import * as services from '../services'
import * as TYPES from './mutations-types'

export const attemptGetTracksList = ({ commit }, pagination) => {
  return services.getTracksList(pagination)
    .then(tracksList => {
      tracksList.data.data = tracksList.data.data.map(track => {
        if (track.thematicPageLearningPaths.length > 0) {
          track.thematicPageLearningPaths = track.thematicPageLearningPaths.filter(thematicPage =>
            thematicPage.thematicPage.active === 1
          )
        }

        if (track.learningPathSolutions.length > 0) {
          track.learningPathSolutions = track.learningPathSolutions.filter(solution =>
            solution.solution.active === 1
          )
        }

        return track
      })

      commit(TYPES.SET_TRACKS_LIST, tracksList.data.data)

      return {
        firstPage: tracksList.data.firstPage,
        lastPage: tracksList.data.lastPage,
        nextPage: tracksList.data.nextPage,
        previousPage: tracksList.data.previousPage,
        total: tracksList.data.total,
        data: tracksList.data.data
      }
    })
}

export const attemptGetTracksSearch = ({ commit }, pagination) => {
  return services.getTracksSearch(pagination)
    .then(tracksList => {
      tracksList.data.data = tracksList.data.data.map(track => {
        if (track.thematicPageLearningPaths.length > 0) {
          track.thematicPageLearningPaths = track.thematicPageLearningPaths.filter(thematicPage =>
            thematicPage.thematicPage.active === 1
          )
        }

        if (track.learningPathSolutions.length > 0) {
          track.learningPathSolutions = track.learningPathSolutions.filter(solution =>
            solution.solution.active === 1
          )
        }

        return track
      })

      commit(TYPES.SET_TRACKS_LIST, tracksList.data.data)

      return {
        firstPage: tracksList.data.firstPage,
        lastPage: tracksList.data.lastPage,
        nextPage: tracksList.data.nextPage,
        previousPage: tracksList.data.previousPage,
        total: tracksList.data.total
      }
    })
}

export const attemptSaveTrack = (_, data) => {
  return services.saveTrack(data)
    .then(response => {
      return response
    })
}

export const attemptUpdateActiveTrack = ({ commit }, track) => {
  return services.updateTrack(track)
    .then(response => {
      commit(TYPES.UPDATE_ACTIVE_TRACK, response.data)

      return response
    })
}

export const attemptSaveActiveTrack = (_, track) => {
  return services.saveTrack(track)
    .then(response => {
      return response
    })
}

export const attemptUpdateTrack = (_, data) => {
  return services.updateTrack(data)
    .then(response => {
      return response
    })
}

export const attemptManageTrack = ({ commit }, trackId) => {
  if (trackId == null) {
    commit(TYPES.SET_MANAGE_TRACK, null)

    return
  }

  return services.getTrack(trackId)
    .then(response => {
      commit(TYPES.SET_MANAGE_TRACK, response.data || null)

      return response
    })
}

export const attemptToggleActiveTrack = (_, data) => {
  return services.toggleActiveTrack(data)
    .then(response => {
      return response
    })
}

export const attemptUpdateTracksHighlights = (_, data) => {
  return services.updateTracksHighlights(data)
}

export const attemptGetListTracksHighlights = (_, data) => {
  return services.getListTracksHighlights(data)
}
