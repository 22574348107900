<script>
import { app } from '@/main'
import { mapActions } from 'vuex'
import { required, requiredIf, url } from 'vuelidate/lib/validators'
import { formatSlug } from '@/support/utils/stringHelper'
import { solutionTypeFormatter } from '@/support/utils/solutionTypeFormatter'

const TRANSLATION = {
  description: {
    description: app.$t('global:form.tracks.description'),
    audience: app.$t('global:form.tracks.audience'),
    differentials: app.$t('global:form.tracks.differentials')
  }
}

const RICH_TEXT_EDITOR_TOOLBAR = [
  [
    'bold',
    'italic',
    'underline',
    'strike'
  ],
  [ 'clean' ],
  [ 'link' ]
]

const isUrlYoutubeOrVimeo = value => {
  // eslint-disable-next-line no-useless-escape
  return !value || /^(http:\/\/|https:\/\/)(vimeo\.com|youtu\.be|www\.youtube\.com)\/([\w\/]+)([\?].*)?$/.test(value)
}

export default {
  name: 'TracksManage',
  components: {
    Tabs: () => import('@/components/general/Tabs'),
    Radio: () => import('@/components/general/Radio'),
    Upload: () => import('@/components/general/Upload'),
    Action: () => import('@/components/general/Action'),
    Divisor: () => import('@/components/general/Divisor'),
    Datatable: () => import('@/components/general/Datatable'),
    Icon: () => import('@/components/general/Icon'),
    ActionBar: () => import('@/components/general/ActionBar'),
    InputField: () => import('@/components/general/InputField'),
    Pagination: () => import('@/components/general/Pagination'),
    FormSection: () => import('@/components/general/FormSection'),
    SelectField: () => import('@/components/general/SelectField'),
    TextareaField: () => import('@/components/general/TextareaField'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    FilterListSearch: () => import('@/components/general/FilterListSearch'),
    RichTextEditor: () => import('@/components/general/RichTextEditor'),
    MediaPreview: () => import('@/components/general/MediaPreview')
  },

  data () {
    return {
      activeTab: 1,
      linkToTrackTabActive: 0,
      linkToTrackTab: [
        { text: this.$t('track.manage:link.tab.1') },
        { text: this.$t('track.manage:link.tab.2') }
      ],

      showSolutions: false,
      solutions: [],
      showThematicPages: false,
      thematicPages: [],
      trackTypeList: [
        {
          text: this.$t('tracks.manage:trackTypeList.ead'),
          value: 'curso_ead'
        },
        {
          text: this.$t('tracks.manage:trackTypeList.presential'),
          value: 'presencial'
        },
        {
          text: this.$t('tracks.manage:trackTypeList.hybrid'),
          value: 'hybrid'
        }
      ],

      themeList: [],
      entrepreneurProfileList: [],
      certificateTypeList: [],
      certificateOptions: [
        {
          label: this.$t('global:form.tracks.yes'),
          value: true
        },
        {
          label: this.$t('global:form.tracks.no'),
          value: false
        }
      ],

      timeDurationList: [
        {
          text: this.$t('global.time:select.m'),
          value: 'min'
        },
        {
          text: this.$t('global.time:select.h'),
          value: 'h'
        },
        {
          text: this.$t('global.time:select.d'),
          value: 'd'
        },
        {
          text: this.$t('global.time:select.s'),
          value: 's'
        }
      ],

      yesNoOptions: [
        {
          label: this.$t('global:yes'),
          value: true
        },
        {
          label: this.$t('global:no'),
          value: false
        }
      ],

      hasCertificate: false,
      formData: {
        id: null,
        name: null,
        slug: null,
        description: null,
        image: null,
        cardImage: null,
        callText: null,
        previewUrl: null,
        audience: null,
        differentials: null,
        showOnPortal: false,
        exclusiveToCompany: false,
        seoTitle: null,
        seoDescription: null,
        seoKeywords: null,
        certificate: null,
        learningPathThemes: [],
        learningPathEntrepreneurProfiles: [],
        learningPathSolutions: [],
        thematicPageLearningPaths: [],
        automaticWorkload: false,
        workload: {
          type: null,
          number: null
        },

        tags: null,
        registrationStatus: 'open'
      },

      pagination: {
        page: 1,
        lastPage: 4
      },

      isLoadingMoreThematicPages: false,
      isLoadingMoreSolutions: false,

      solutionsPagination: {
        query: {
          name: null,
          limit: 300
        },

        filter: {
          active: 1
        },

        order: {
          name: 'ASC'
        },

        lastPage: null,
        limit: 6,
        page: 1
      },

      thematicPagesPagination: {
        query: {
          name: null,
          limit: 300
        },

        filter: {
          active: 1
        },

        order: {
          name: 'ASC'
        },

        lastPage: null,
        limit: 6,
        page: 1
      },

      backUrl: { name: 'tracks.index' },
      modalLeave: false,
      modalActiveTrackOpen: false,
      isSlugDuplicate: false,
      formatedUrl: '',
      learningPathThemesInactives: [],
      solutionInfiniteScrollId: new Date()
        .getTime(),

      thematicPageInfiniteScrollId: new Date()
        .getTime(),

      registrationStatusOptions: [
        {
          label: this.$t('global:registration.status.option.open'),
          value: 'open'
        },
        {
          label: this.$t('global:registration.status.option.suspended'),
          value: 'suspended'
        }
      ]
    }
  },

  validations () {
    return {
      hasCertificate: {
        required: (event) => {
          return event !== null && event !== undefined
        }
      },

      formData: {
        name: { required },
        slug: {
          required,
          regexSlug: (event) => {
            const regexSlug = new RegExp('^[a-z0-9]+(?:-[a-z0-9]+)*$')

            return regexSlug.test(event)
          },

          slugDuplicate: () => {
            return this.isSlugDuplicate
          }
        },

        description: { required },
        image: { required },
        cardImage: { required },
        callText: { required },
        previewUrl: {
          url,
          urlYoutubeVimeoValidator: isUrlYoutubeOrVimeo
        },

        audience: { required },
        differentials: { required },
        showOnPortal: {
          required: (event) => {
            return event !== null && event !== undefined
          }
        },

        exclusiveToCompany: {
          required: (event) => {
            return event !== null && event !== undefined
          }
        },

        certificate: {
          required: requiredIf(function () {
            return !!this.hasCertificate
          })
        },

        automaticWorkload: {
          required: (event) => {
            return event !== null && event !== undefined
          }
        },

        workload: {
          number: {
            required: requiredIf(function () {
              return !this.formData.automaticWorkload && this.activeTab === 2
            }),

            valueBiggerZero: function (valorDigitado) {
              return this.formData.automaticWorkload || this.activeTab !== 2 ? true : valorDigitado > 0
            }
          },

          type: {
            required: requiredIf(function () {
              return !this.formData.automaticWorkload && this.activeTab === 2
            })
          }
        }
      }
    }
  },

  computed: {
    availableSolutions () {
      return this.solutions.filter(solution => !this.formData.learningPathSolutions.find(findSolution => solution.id === findSolution.id))
        .sort((a, b) => {
          if (a > b) return 1
          if (b > a) return -1

          return 0
        })
    },

    availableThematicPages () {
      return this.thematicPages.filter(thematicPage => !this.formData.thematicPageLearningPaths.find(findThematicPage => thematicPage.id === findThematicPage.id))
        .sort((a, b) => {
          if (a > b) return 1
          if (b > a) return -1

          return 0
        })
    },

    isEditing () {
      return this.$route.params.id || this.formData.id
    },

    linkedSolutionsLabel () {
      return this.solutions.length ? this.$t('solutions.list.link:datatable.header.1') : this.$t('solutions.list.link:datatable.header.1.without-solutions')
    }
  },

  watch: {
    'formData.name' () {
      if (!this.$v.formData.slug.$dirty && !this.isEditing) {
        this.formData.slug = formatSlug(this.formData.name)
      }
    },

    'formData.slug' (event) {
      this.formData.slug = event.replace(new RegExp('[-]{2,}', 'g'), '-')

      this.debounceEvent(async () => {
        this.setFetching(true)
        const search = { slug: event }

        if (this.isEditing) {
          search.not_id = [ this.formData.id ]
        }

        const tracks = await this.attemptGetTracksList({ filter: search })
          .then(pagination => { return pagination.data })

        this.isSlugDuplicate = !(tracks.length > 0)

        if (!this.isSlugDuplicate) {
          this.$v.formData.slug.$touch()
        }

        this.setFetching(false)
      }, 1000)

      this.formatedUrl = `${process.env.VUE_APP_PORTAL_URL}trilha-de-aprendizagem/${this.formData.slug}`
    }
  },

  mounted () {
    if (this.$route.params.page) {
      this.activeTab = 2
    }
  },

  created () {
    this.setup()
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptUpdateTrack',
      'attemptManageTrack',
      'attemptGetThemesList',
      'attemptSaveActiveTrack',
      'attemptGetThematicPagesList',
      'attemptGetSolutionsList',
      'attemptUpdateActiveTrack',
      'attemptGetCertificatesList',
      'attemptGetEntrepreneurProfilesList',
      'attemptToggleActiveTrack',
      'attemptGetTracksList'
    ]),

    setup () {
      this.TRANSLATION = TRANSLATION
      this.RICH_TEXT_EDITOR_TOOLBAR = RICH_TEXT_EDITOR_TOOLBAR
      this.setFetching(true)
      this.activeTab = this.$route.params.tab || 1

      this.fetchData()
        .then(() => {
          if (this.isEditing) {
            this.attemptManageTrack(this.$route.params.id)
              .then(({ data }) => {
                this.formData = {
                  ...this.formData,
                  ...data
                }

                this.formData.certificate = this.formData.certificate && this.certificateTypeList.find(certificate => {
                  return certificate.value === this.formData.certificate.id
                }).value

                this.hasCertificate = !!this.formData.certificate
                this.formData.previewUrl = this.formData.learningPathAsset.length ? this.formData.learningPathAsset[0].asset.meta.video_url : null

                this.formData.learningPathSolutions = this.formData.learningPathSolutions && this.formData.learningPathSolutions.map(({ solution }) => {
                  return {
                    id: solution.id,
                    name: solution.name,
                    active: solution.active
                  }
                })

                this.formData.thematicPageLearningPaths = this.formData.thematicPageLearningPaths && this.formData.thematicPageLearningPaths.map(({ thematicPage }) => {
                  return {
                    id: thematicPage.id,
                    name: thematicPage.name,
                    active: thematicPage.active
                  }
                })

                this.formData.workload = this.mountWorkloadObject(this.formData.workload)

                if (this.formData.learningPathThemes.length) {
                  const activeThemes = []
                  const inactiveThemes = []

                  this.formData.learningPathThemes.forEach(learningPath => {
                    if (learningPath.theme.active) {
                      activeThemes.push(learningPath.theme.alias)
                    } else {
                      inactiveThemes.push(learningPath.theme.alias)
                    }
                  })

                  this.formData.learningPathThemes = activeThemes
                  this.learningPathThemesInactives = inactiveThemes
                }

                if (this.formData.learningPathEntrepreneurProfiles.length) {
                  this.formData.learningPathEntrepreneurProfiles = this.formData.learningPathEntrepreneurProfiles.map(entrepreneur => {
                    return entrepreneur.entrepreneurProfile.id
                  })
                }

                this.showSolutions = !!(this.formData.learningPathSolutions && this.formData.learningPathSolutions.length > 0)

                this.showThematicPages = !!(this.formData.thematicPageLearningPaths && this.formData.thematicPageLearningPaths.length > 0)

                this.formData.showOnPortal = !!this.formData.showOnPortal

                this.formData.exclusiveToCompany = !!this.formData.exclusiveToCompany

                this.formData.automaticWorkload = !!this.formData.automaticWorkload
              })
          }
        })
        .catch(() => {
          this.setFeedback({ message: this.$t('tracks.load:fetch.error') })
          this.$router.push({ name: 'tracks.index' })
        })
        .finally(() => {
          setTimeout(() => {
            this.setFetching(false)
          }, 500)
        })
    },

    prevPage () {
      this.submit()
        .then(() => {
          if (this.activeTab > 1) this.activeTab -= 1

          if (this.activeTab === 3) {
            this.setSeoValues()
          }
        })
    },

    nextPage () {
      this.submit()
        .then(() => {
          this.activeTab += 1

          if (this.activeTab === 3) {
            this.setSeoValues()
          }
        })
    },

    setSeoValues () {
      this.formData.seoTitle = this.formData.seoTitle || this.formData.name
      this.formData.seoDescription = this.formData.seoDescription || this.formData.description
      this.formData.seoKeywords = this.formData.seoKeywords || ''
    },

    mountWorkloadObject (workload) {
      return {
        type: workload ? workload.match(/[hdw|min]+/gi) && workload.match(/[hdw|min]+/gi)[0] : 'h',
        number: workload ? workload.match(/[0-9]+/gi) && workload.match(/[0-9]+/gi)[0] : 0
      }
    },

    formatFormData () {
      const data = {}

      data.id = this.formData.id
      data.name = this.formData.name
      data.slug = this.formData.slug
      data.description = this.formData.description
      data.status = this.formData.status

      data.active = 0

      if (this.isEditing) {
        data.active = this.formData.active
      }

      const filterSolutions = this.formData.learningPathSolutions.filter(solution => {
        return solution.active === 1
      })

      data.workload = `${this.formData.workload.number || ''}${this.formData.workload.type || ''}`
      data.automaticWorkload = this.formData.automaticWorkload ? 1 : 0
      data.image = this.formData.image
      data.cardImage = this.formData.cardImage
      data.call_text = this.formData.callText
      data.preview_video = this.formData.previewUrl
      data.audience = this.formData.audience
      data.differentials = this.formData.differentials
      data.show_on_portal = this.formData.showOnPortal ? 1 : 0
      data.exclusiveToCompany = this.formData.exclusiveToCompany ? 1 : 0

      data.seoTitle = this.formData.seoTitle || this.formData.name
      data.seoDescription = this.formData.seoDescription || this.formData.description
      data.seoKeywords = this.formData.seoKeywords || ''
      data.certificate_id = this.hasCertificate ? this.formData.certificate : null

      data.themes = [
        ...this.formData.learningPathThemes,
        ...this.learningPathThemesInactives
      ]

      data.entrepreneur_profiles = this.formData.learningPathEntrepreneurProfiles
      data.solutions = filterSolutions.map(solution => solution.id)
      data.thematicPages = this.formData.thematicPageLearningPaths.map(thematicPage => thematicPage.id)
      data.tags = this.formData.tags
      data.registrationStatus = this.formData.registrationStatus

      return data
    },

    addToLinkedThematicPage (thematicPageId) {
      const thematicPage = this.thematicPages.find(thematicPage => thematicPage.id === thematicPageId)

      thematicPage.active = 1
      this.formData.thematicPageLearningPaths.push(thematicPage)
    },

    removeLinkedThematicPage (thematicPageId) {
      this.formData.thematicPageLearningPaths = this.formData.thematicPageLearningPaths.filter(thematicPage => thematicPage.id !== thematicPageId)
    },

    clearLinkedThematicPages (event) {
      if (event) return

      this.formData.thematicPageLearningPaths = this.formData.thematicPageLearningPaths.filter(thematicPage =>
        thematicPage.active !== 1
      )
    },

    clearLinkedSolutions (event) {
      if (event) return

      this.formData.learningPathSolutions = this.formData.learningPathSolutions.filter(solution =>
        solution.active !== 1
      )
    },

    addToLinkedSolution (solutionId) {
      const solution = this.solutions.find(solution => solution.id === solutionId)

      solution.active = 1
      this.formData.learningPathSolutions.push(solution)
    },

    removeLinkedSolution (solutionId) {
      this.formData.learningPathSolutions = this.formData.learningPathSolutions.filter(solution => solution.id !== solutionId)
    },

    moveUpLinkedSolution (solutionId) {
      const index = this.formData.learningPathSolutions.findIndex(solution => solution.id === solutionId)

      if (index > 0) {
        const swapSolution = this.formData.learningPathSolutions[index - 1]

        this.$set(this.formData.learningPathSolutions, (index - 1), this.formData.learningPathSolutions[index])
        this.$set(this.formData.learningPathSolutions, index, swapSolution)
      }
    },

    moveDownLinkedSolution (solutionId) {
      const index = this.formData.learningPathSolutions.findIndex(solution => solution.id === solutionId)

      if (index < (this.formData.learningPathSolutions.length - 1)) {
        const swapSolution = this.formData.learningPathSolutions[index + 1]

        this.$set(this.formData.learningPathSolutions, (index + 1), this.formData.learningPathSolutions[index])
        this.$set(this.formData.learningPathSolutions, index, swapSolution)
      }
    },

    submitCreation () {
      this.setFetching(true)
      const formData = this.formatFormData()

      return this.attemptSaveActiveTrack(formData)
        .then(({ data }) => {
          this.formData.id = data.id
          this.formData.cardImage = data.cardImage
          this.formData.image = data.image
          this.formData.active = data.active
          this.setFeedback({ message: this.$t('tracks:feedback.created.success') })

          this.$router.push({
            name: 'tracks.manage',
            params: {
              id: data.id,
              page: 2
            }
          })
        })
        .catch(e => {
          this.setFeedback({ message: this.$t('tracks:feedback.created.error') })
          throw e
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    submitUpdate () {
      this.setFetching(true)
      const formData = this.formatFormData()
      const id = this.$route.params.id || this.formData.id

      return this.attemptUpdateTrack({
        id: id,
        data: formData,
        isLinkedTab: this.activeTab === 4 // tab de vinculos
      })
        .then(({ data }) => {
          if (data.imagePath) {
            data.imagePath = data.imagePath + '?v=' + Math.random()
          }

          this.formData.cardImage = data.cardImage
          this.formData.image = data.image
          this.formData.active = data.active
          this.setFeedback({ message: this.$t('tracks:feedback.updated.success') })
        })
        .catch((e) => {
          if (e.data.error.message === 'learning_path_solution_not_found') {
            this.setFeedback({ message: this.$t('global:form.tracks.toggle.learning_path.solution.error') })
          } else {
            this.setFeedback({ message: this.$t('tracks:feedback.updated.error') })
          }

          throw e
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    submit () {
      return new Promise((resolve, reject) => {
        this.$v.$touch()

        if (!this.$v.$invalid) {
          const submit = this.isEditing ? this.submitUpdate() : this.submitCreation()

          submit.then(() => {
            resolve(true)
          })
            .catch(e => {
              reject(e)
            })
        } else {
          this.setFeedback({ message: this.$t('tracks:feedback.validation.error') })
          reject(new Error('Error'))
        }
      })
    },

    leave () {
      this.modalConfirm = false

      this.$nextTick(() => {
        this.$router.push(this.backUrl)
      })
    },

    saveAll () {
      this.submit(true)
    },

    async fetchData (params = {}) {
      const builded = {
        thematicPages: this.thematicPagesPagination,
        solutions: this.solutionsPagination,
        certificates: {
          filter: { active: 1 }
        },

        themes: {
          filter: { active: 1 }
        },

        entrepreneurProfiles: {
          filter: { active: 1 }
        }
      }

      Object.keys(builded)
        .map(key => {
          if (params && params[key]) {
            builded[key] = {
              ...builded[key],
              ...params[key]
            }
          }

          return key
        })

      return Promise.all([
        this.attemptGetThematicPagesList(builded.thematicPages),
        this.attemptGetSolutionsList(builded.solutions),
        this.attemptGetCertificatesList(builded.certificates),
        this.attemptGetThemesList(builded.themes),
        this.attemptGetEntrepreneurProfilesList(builded.entrepreneurProfiles)
      ])
        .then(([
          thematicPages,
          solutions,
          certificates,
          themesList,
          entrepreneurList
        ]) => {
          if (solutions.data) {
            this.solutions = []
            this.solutionsPagination.lastPage = solutions.lastPage
            this.solutionsPagination.page = solutions.actualPage

            solutions.data.forEach(element => {
              this.solutions.push({
                name: element.name,
                id: element.id,
                workload: element.workload,
                solutionType: element.solutionType
              })
            })
          }

          if (thematicPages.data) {
            this.thematicPages = []
            this.thematicPagesPagination.lastPage = thematicPages.lastPage
            this.thematicPagesPagination.page = thematicPages.actualPage

            thematicPages.data.forEach(element => {
              this.thematicPages.push({
                name: element.name,
                id: element.id,
                thematicPagesProgram: element.thematicPagesProgram,
                thematicPageLearningPaths: element.thematicPageLearningPaths,
                thematicPageSolutions: element.thematicPageSolutions
              })
            })
          }

          if (certificates.data) {
            certificates.data.forEach(element => {
              this.certificateTypeList.push({
                text: element.name,
                value: element.id
              })
            })
          }

          if (themesList.data) {
            themesList.data.forEach(element => {
              this.themeList.push({
                text: element.name,
                value: element.alias
              })
            })
          }

          if (entrepreneurList.data) {
            entrepreneurList.data.forEach(element => {
              this.entrepreneurProfileList.push({
                text: element.name,
                value: element.id
              })
            })
          }
        })
    },

    searchSolution (search) {
      this.solutionsPagination.query.name = search
      this.solutionsPagination.page = 1
      this.solutionsPagination.lastPage = null
      this.solutionsPagination.query.only_name = true
      this.fetchSolutionsData()
    },

    loadMoreSolutions () {
      this.isLoadingMoreSolutions = true

      if (this.solutionsPagination.lastPage) {
        if (this.solutionsPagination.lastPage > this.solutionsPagination.page) {
          this.solutionsPagination.page += 1
        } else {
          this.isLoadingMoreSolutions = false

          return
        }
      }

      this.fetchSolutionsData(true)
        .then(() => {
          this.isLoadingMoreSolutions = false
        })
    },

    async fetchSolutionsData (isLoadMore = false) {
      return this.attemptGetSolutionsList(this.solutionsPagination)
        .then((pagination) => {
          this.solutionInfiniteScrollId += 1
          this.solutionsPagination.lastPage = pagination.lastPage
          this.solutionsPagination.page = pagination.actualPage

          if (pagination.data) {
            if (!isLoadMore) {
              this.solutions = []
            }

            pagination.data.forEach(element => {
              this.solutions.push({
                name: element.name,
                id: element.id,
                workload: element.workload,
                solutionType: element.solutionType
              })
            })
          }

          return pagination
        })
    },

    searchThematicPage (search) {
      this.thematicPagesPagination.query.name = search
      this.thematicPagesPagination.page = 1
      this.thematicPagesPagination.lastPage = null
      this.fetchThematicPagesData()
    },

    loadMoreThematicPages () {
      this.isLoadingMoreThematicPages = true

      if (this.thematicPagesPagination.lastPage) {
        if (this.thematicPagesPagination.lastPage > this.thematicPagesPagination.page) {
          this.thematicPagesPagination.page += 1
        } else {
          this.isLoadingMoreThematicPages = false

          return
        }
      }

      this.fetchThematicPagesData(true)
        .then(() => {
          this.isLoadingMoreThematicPages = false
        })
    },

    async fetchThematicPagesData (isLoadMore = false) {
      return this.attemptGetThematicPagesList(this.thematicPagesPagination)
        .then((pagination) => {
          this.thematicPageInfiniteScrollId += 1
          this.thematicPagesPagination.lastPage = pagination.lastPage
          this.thematicPagesPagination.page = pagination.actualPage

          if (pagination.data) {
            if (!isLoadMore) {
              this.thematicPages = []
            }

            pagination.data.forEach(element => {
              this.thematicPages.push({
                name: element.name,
                id: element.id,
                thematicPageLearningPaths: element.thematicPageLearningPaths,
                thematicPageSolutions: element.thematicPageSolutions
              })
            })
          }

          return pagination
        })
    },

    getSolutionTypeFormattedName (solutionType) {
      return solutionTypeFormatter(solutionType)
    }
  }
}
</script>

<template>
  <div class="main-content tracks-create">
    <ContentHeader
      :title="isEditing ? formData.name : $t('tracks.create:header.title')"
      dark-theme
    >
      <Action
        slot="back"
        type="button"
        :text="$t('global:back.tracks')"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave()"
      />
      <ActionBar slot="actionbar" />
      <template slot="buttons">
        <Action
          :text="$t('global:form.save')"
          type="button"
          flat
          dark
          @click="saveAll()"
        />
      </template>
    </ContentHeader>
    <div
      v-if="activeTab === 1"
      class="main-content-inner"
    >
      <div class="center">
        <div class="tracks-create-header">
          <h2 class="tracks-create-title">
            {{ $t('global:form.step', {'num': activeTab}) }}
          </h2>
          <p
            class="tracks-create-description"
            v-html="$t('tracks.create:header.description')"
          />
        </div>

        <form
          class="form"
          @submit.prevent="submit()"
        >
          <InputField
            v-model="formData.name"
            :label="$t('global:form.tracks.title')"
            :validation="$v.formData.name"
            :counter="80"
          />

          <InputField
            v-model="formData.slug"
            :under-description="formatedUrl"
            :label="$t('global:form.tracks.slug')"
            :validation="$v.formData.slug"
            :counter="100"
          />

          <InputField
            v-model="formData.callText"
            :under-description="$t('global:form.tracks.callText.under-description')"
            :label="$t('global:form.tracks.callText')"
            :validation="$v.formData.callText"
            :counter="250"
          />

          <InputField
            v-model="formData.previewUrl"
            :under-description="$t('solution.manage:field.description.video')"
            :label="$t('global:preview.url')"
            :validation="$v.formData.previewUrl"
          />

          <MediaPreview
            v-if="formData.previewUrl"
            :media-url="formData.previewUrl"
          />

          <FormSection :title="TRANSLATION.description.description">
            <RichTextEditor
              v-model="formData.description"
              :validation="$v.formData.description"
              :toolbar="RICH_TEXT_EDITOR_TOOLBAR"
              class="rich-text"
            />
          </FormSection>

          <FormSection :title="TRANSLATION.description.audience">
            <RichTextEditor
              v-model="formData.audience"
              :toolbar="RICH_TEXT_EDITOR_TOOLBAR"
              :validation="$v.formData.audience"
              class="rich-text"
            />
          </FormSection>

          <FormSection :title="TRANSLATION.description.differentials">
            <RichTextEditor
              v-model="formData.differentials"
              :validation="$v.formData.differentials"
              :toolbar="RICH_TEXT_EDITOR_TOOLBAR"
              class="rich-text"
            />
          </FormSection>

          <FormSection :title="$t('global:form.tracks.feature.title')">
            <Upload
              v-model="formData.image"
              icon="image-multiple"
              :label="$t('global:upload.add.image')"
              :description="$t('global:form.tracks.image.description')"
              cropper
              :width="2215"
              :height="830"
              :preview="0.217"
              :validation="$v.formData.image"
            />
          </FormSection>

          <FormSection :title="$t('global:form.tracks.card.image.title')">
            <Upload
              v-model="formData.cardImage"
              icon="image-multiple"
              :label="$t('global:upload.add.image')"
              :description="$t('global:form.tracks.card.image.description')"
              cropper
              :width="811"
              :height="270"
              :preview="0.60"
              :validation="$v.formData.cardImage"
            />
          </FormSection>

          <FormSection :title="$t('global:form.tracks.show.portal')">
            <Radio
              v-model="formData.showOnPortal"
              :under-description="$t('global:form.tracks.show.portal.under-description')"
              :items="yesNoOptions"
              horizontal
              :validation="$v.formData.showOnPortal"
            />
          </FormSection>

          <FormSection :title="$t('global:form.tracks.exclusiveToCompany')">
            <Radio
              v-model="formData.exclusiveToCompany"
              :under-description="$t('global:form.tracks.exclusiveToCompany.under-description')"
              :items="yesNoOptions"
              horizontal
              :validation="$v.formData.exclusiveToCompany"
            />
          </FormSection>

          <FormSection :title="$t('global:form.tracks.has-certificate')">
            <Radio
              v-model="hasCertificate"
              :under-description="$t('global:form.tracks.has-certificate.under-description')"
              :items="certificateOptions"
              horizontal
              :validation="$v.hasCertificate"
            />

            <SelectField
              v-if="hasCertificate"
              v-model="formData.certificate"
              :label="$t('global:form.tracks.certificate')"
              :items="certificateTypeList"
              :validation="$v.formData.certificate"
            />
          </FormSection>

          <FormSection
            :title="$t('global:form.registration.status')"
          >
            <Radio
              v-model="formData.registrationStatus"
              :under-description="$t('paths.manage:field.description.registration.status')"
              :items="registrationStatusOptions"
              horizontal
            />
          </FormSection>
        </form>
      </div>
    </div>
    <div
      v-if="activeTab === 2"
      class="main-content-inner"
    >
      <div class="center">
        <div class="tracks-create-header">
          <h2 class="tracks-create-title">
            {{ $t('global:form.step', {'num': activeTab}) }}
          </h2>
          <p
            class="tracks-create-description"
            v-html="$t('tracks.create:header.description.2')"
          />
        </div>
        <form
          class="form"
          @submit.prevent="submit()"
        >
          <FormSection :title="$t('global:form.themes')">
            <SelectField
              v-model="formData.learningPathThemes"
              :label="$t('global:form.theme')"
              multiple
              :items="themeList"
            />
          </FormSection>
          <FormSection :title="$t('global:form.profiles')">
            <SelectField
              v-model="formData.learningPathEntrepreneurProfiles"
              :label="$t('global:form.profile')"
              multiple
              :items="entrepreneurProfileList"
            />
          </FormSection>
          <FormSection :title="$t('global:form.tracks.tags')">
            <TextareaField
              v-model="formData.tags"
              :label="$t('global:form.tracks.tags')"
              :validation="$v.formData.tags"
              auto-grow
              :counter="255"
            />
          </FormSection>

          <FormSection :title="$t('global:form.tracks.auto.workload')">
            <Radio
              v-model="formData.automaticWorkload"
              :under-description="$t('global:form.tracks.auto.workload.under-description')"
              :items="yesNoOptions"
              horizontal
              :validation="$v.formData.automaticWorkload"
            />
          </FormSection>
          <div
            class="form-group clearfix"
            data-gap="40"
            data-cols="2"
          >
            <InputField
              v-model="formData.workload.number"
              type="number"
              :min="0"
              :label="$t('global:solution.workload')"
              :disabled="formData.automaticWorkload"
              :validation="$v.formData.workload.number"
            />
            <SelectField
              v-model="formData.workload.type"
              :label="$t('global:form.type')"
              :items="timeDurationList"
              :disabled="formData.automaticWorkload"
              :validation="$v.formData.workload.type"
            />
          </div>
        </form>
      </div>
    </div>
    <div
      v-if="activeTab === 3"
      class="main-content-inner"
    >
      <div class="center">
        <div class="tracks-create-header">
          <h2 class="tracks-create-title">
            {{ $t('global:form.step', {'num': activeTab}) }}
          </h2>
          <p
            class="tracks-create-description"
            v-html="$t('tracks.create:header.description.3')"
          />
          <span class="under-description">{{ $t('tracks.create:header.description.3.under-description') }}</span>
        </div>
        <form
          class="form"
          @submit.prevent="submit()"
        >
          <InputField
            v-model="formData.seoTitle"
            :label="$t('global:form.seo.title')"
            hide-optional
          />
          <InputField
            v-model="formData.seoDescription"
            :label="$t('global:form.seo.description')"
            hide-optional
          />
          <InputField
            v-model="formData.seoKeywords"
            :label="$t('global:form.seo.keywords')"
            :hint="$t('global:form.solutions.seo.keywords.hint')"
            hide-optional
          />
        </form>
      </div>
    </div>
    <div
      v-if="activeTab === 4"
      class="main-content-inner"
    >
      <div class="center">
        <div class="tracks-create-header">
          <h2 class="tracks-create-title">
            {{ $t('global:form.step', {'num': activeTab}) }}
          </h2>
          <p
            class="tracks-create-description"
            v-html="$t('tracks.create:header.description.4')"
          />
          <span
            v-if="formData.active"
            class="td-tag active-ribbon"
          >{{ $t('global.status:active') }}</span>
          <span
            v-else
            class="td-tag inactive-ribbon"
          >{{ $t('global.status:inactive') }}</span>
        </div>
        <form
          class="center"
          @submit.prevent="submit()"
        >
          <Tabs
            slot="tabs"
            :links="linkToTrackTab"
            :index-active="linkToTrackTabActive"
            @tabChange="linkToTrackTabActive = $event"
          />

          <div
            v-show="linkToTrackTabActive === 0"
            class="link-selection"
          >
            <FormSection :title="$t('show.in.Solutions')">
              <Radio
                v-model="showSolutions"
                :items="yesNoOptions"
                horizontal
                @input="clearLinkedSolutions()"
              />
            </FormSection>

            <div v-if="showSolutions">
              <div class="link-table">
                <div class="linkable-items">
                  <Datatable
                    :is-loading-more="isLoadingMoreSolutions"
                    :items="availableSolutions"
                    hide-horizontal-line
                    has-infinite-scroll
                    has-fade
                    empty-message="no_result"
                    @loadMore="loadMoreSolutions"
                  >
                    <template slot="thead">
                      <th
                        class="th"
                        width="300"
                      >
                        {{ linkedSolutionsLabel }}
                      </th>
                      <th
                        class="th"
                        width="40"
                      />
                    </template>

                    <template slot="tsearch">
                      <FilterListSearch
                        slot="search"
                        class="filter-search"
                        :prepend-label="$t('global:form.solution-search')"
                        :hide-error-details="true"
                        @search="searchSolution"
                      />
                    </template>

                    <template
                      slot="tbody"
                      slot-scope="props"
                    >
                      <tr
                        :key="props.index"
                        @click="addToLinkedSolution(props.item.id)"
                      >
                        <td class="td td-full">
                          <span class="td-text bolder">{{ props.item.name }}</span>
                          <br>
                          <span class="td-text td-small">{{ getSolutionTypeFormattedName(props.item.solutionType) }} | {{ props.item.workload }}</span>
                        </td>
                        <td class="td td-button td-small text-right">
                          <Action
                            type="button"
                            icon="keyboard_arrow_right"
                            flat
                          />
                        </td>
                      </tr>
                    </template>
                  </Datatable>
                </div>

                <Divisor orientation="vertical" />

                <div class="linked-items">
                  <Datatable
                    has-fade
                    drag
                    :items="formData.learningPathSolutions"
                    hide-horizontal-line
                  >
                    <template slot="thead">
                      <th class="th">
                        {{ $t('solutions.list.link:datatable.header.3') }}
                      </th>
                      <th
                        class="th"
                        width="40"
                      />
                    </template>
                    <template
                      slot="tbody"
                      slot-scope="props"
                    >
                      <tr
                        v-if="props.item.active"
                        :key="props.index"
                        class="tr-draggable"
                      >
                        <td class="td td-drag">
                          <Icon
                            name="drag"
                            size="is-small"
                          />
                        </td>
                        <td class="td td-full">
                          <span class="td-text bolder">{{ props.item.name }}</span>
                        </td>
                        <td class="td td-button td-small text-right">
                          <Action
                            class="order-action"
                            type="button"
                            icon="arrow_drop_down"
                            flat
                            @click="moveDownLinkedSolution(props.item.id)"
                          />
                          <Action
                            class="order-action"
                            type="button"
                            icon="arrow_drop_up"
                            flat
                            @click="moveUpLinkedSolution(props.item.id)"
                          />
                          <Action
                            class="order-action"
                            type="button"
                            icon="close"
                            flat
                            @click="removeLinkedSolution(props.item.id)"
                          />
                        </td>
                      </tr>
                    </template>
                  </Datatable>
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="linkToTrackTabActive === 1"
            class="link-selection"
          >
            <FormSection :title="$t('show.in.ThematicPages')">
              <Radio
                v-model="showThematicPages"
                :items="yesNoOptions"
                horizontal
                @input="clearLinkedThematicPages()"
              />
            </FormSection>
            <div
              v-if="showThematicPages"
              class="link-table"
            >
              <div class="linkable-items">
                <Datatable
                  :is-loading-more="isLoadingMoreThematicPages"
                  :items="availableThematicPages"
                  hide-horizontal-line
                  has-infinite-scroll
                  has-fade
                  empty-message="no_result"
                  @loadMore="loadMoreThematicPages"
                >
                  <template slot="thead">
                    <th
                      class="th"
                      width="300"
                    >
                      {{ $t('themes.list:datatable.header.1') }}
                    </th>
                    <th
                      class="th"
                      width="40"
                    />
                  </template>

                  <template slot="tsearch">
                    <FilterListSearch
                      slot="search"
                      :prepend-label="$t('global:form.theme-search')"
                      :hide-error-details="true"
                      class="filter-search"
                      @search="searchThematicPage"
                    />
                  </template>

                  <template
                    slot="tbody"
                    slot-scope="props"
                  >
                    <tr
                      :key="props.index"
                      @click="addToLinkedThematicPage(props.item.id)"
                    >
                      <td class="td td-full">
                        <span class="td-text bolder">{{ props.item.name }}</span>
                        <br>
                        <span class="td-text td-small">{{ props.item.thematicPageLearningPaths.length }} {{ props.item.thematicPageLearningPaths.length === 1 ? 'Trilha' : 'Trilhas' }} | {{ props.item.thematicPageSolutions.length }} {{ props.item.thematicPageSolutions.length === 1 ? 'Solução' : 'Soluções' }}</span>
                      </td>

                      <td class="td td-button td-small text-right">
                        <Action
                          flat
                          type="button"
                          icon="keyboard_arrow_right"
                        />
                      </td>
                    </tr>
                  </template>
                </Datatable>
              </div>
              <Divisor orientation="vertical" />
              <div class="linked-items">
                <Datatable
                  has-fade
                  :items="formData.thematicPageLearningPaths"
                  hide-horizontal-line
                >
                  <template slot="thead">
                    <th class="th">
                      {{ $t('themes.list:datatable.header.2') }}
                    </th>
                    <th
                      class="th"
                      width="40"
                    />
                  </template>
                  <template
                    slot="tbody"
                    slot-scope="props"
                  >
                    <tr
                      v-if="props.item.active"
                      :key="props.index"
                    >
                      <td class="td td-full">
                        <span class="td-text bolder">{{ props.item.name }}</span>
                      </td>
                      <td class="td td-button td-small text-right">
                        <Action
                          type="button"
                          icon="close"
                          flat
                          @click="removeLinkedThematicPage(props.item.id)"
                        />
                      </td>
                    </tr>
                  </template>
                </Datatable>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <Pagination
      class="center"
      :active-first-last="false"
      :active-page="activeTab"
      :page-count="4"
      @nextPage="nextPage()"
      @previousPage="prevPage()"
    />
  </div>
</template>

<style src="@/assets/styles/themes/default/tracks.css"></style>

<style>
.form-input-prepend {
  width: unset;
}

.under-description {
  display: inline-block;
  font-size: 1.2em;
  color: rgba(0,0,0,0.5);
  padding: 5px 0;
  max-width: 480px;
  text-transform: none;
}

.td-tag.active-ribbon {
  background: #beffbe;
}

.td-tag.inactive-ribbon {
  background: #ffadad;
}

.td-tag {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.2em;
  color: rgba(0,0,0,0.5);
  line-height: 30px;
  padding: 0 10px;
  min-width: 70px;
  text-align: center;
  background: #F3F4F7;
  border-radius: 50px;
  text-transform: none;
}

.td-tag.active-ribbon {
  background: #beffbe;
}

.td-tag.inactive-ribbon {
  background: #ffadad;
}
</style>
