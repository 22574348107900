const FilterModule = () => import('./FilterModule.vue')
const ListPage = () => import('./pages/ListPage.vue')
const ManagePage = () => import('./pages/ManagePage.vue')

export const routes = [
  {
    path: '/notifications/filters',
    component: FilterModule,
    children: [
      {
        path: '/',
        name: 'notifications.filters',
        component: ListPage,
        meta: {
          hideNavbar: false,
          requiresAuth: true
        }
      },

      {
        path: '/manage/:id?',
        name: 'notifications.filters.manage',
        component: ManagePage,
        meta: {
          hideNavbar: false,
          requiresAuth: true,
          permission: {
            context: 'filters',
            access: 'write'
          }
        }
      }
    ]
  }
]
