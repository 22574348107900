<script>
import { mapActions } from 'vuex'

export default {
  name: 'ToolsList',
  components: {
    Action: () => import('@/components/general/Action'),
    Dropdown: () => import('@/components/general/Dropdown'),
    Datatable: () => import('@/components/general/Datatable'),
    FilterList: () => import('@/components/general/FilterList'),
    Pagination: () => import('@/components/general/Pagination'),
    DropdownLink: () => import('@/components/general/DropdownLink'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    FilterListSearch: () => import('@/components/general/FilterListSearch'),
    Tabs: () => import('@/components/general/Tabs')
  },

  data () {
    return {
      pagination: {
        query: {},
        filter: {},
        page: 1,
        limit: 12,
        lastPage: null,
        order: {
          active: 'desc',
          title: 'asc'
        }
      },

      tabLinks: [
        {
          location: { name: 'tools.index' },
          text: 'Ferramentas'
        },
        {
          location: { name: 'tools.type_tools.index' },
          text: 'Tipos de Ferramentas'
        }
      ],

      toolId: null
    }
  },

  computed: {
    toolsList () {
      return this.$store.getters.toolsList
    },

    canWrite () {
      return this.getContextPermission('tools') === 'write'
    }
  },

  watch: {
    'pagination.page' () {
      this.getToolsList()
    }
  },

  created () {
    this.getToolsList()
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptToggleToolActive',
      'attemptGetToolsList'
    ]),

    prevPage () {
      if (this.pagination.page > 1) {
        this.pagination.page -= 1
      }
    },

    nextPage () {
      this.pagination.page += 1
    },

    firstPage () {
      this.pagination.page = 1
    },

    lastPage () {
      this.pagination.page = this.pagination.lastPage
    },

    getToolsList () {
      this.setFetching(true)

      this.attemptGetToolsList(this.pagination).then(({ data }) => {
        this.pagination.lastPage = data.lastPage
      }).finally(() => {
        this.setFetching(false)
      })
    },

    createNewTool () {
      this.$router.push({ name: 'tools.manage' })
    },

    searchToolsPage (search) {
      this.pagination.query = {
        title: search
      }

      this.getToolsList()
    },

    edit (toolId) {
      this.$router.push({
        name: 'tools.manage',
        params: { id: toolId }
      })
    },

    toggleActive (tool) {
      this.setFetching(true)

      const data = {}

      data.title = tool.title
      data.callText = tool.callText
      data.active = !tool.active
      data.id = tool.id
      data.partnerUrl = tool.partnerUrl
      data.idToolType = tool.toolType.id
      data.benefits = tool.benefits
      data.objectives = []
      data.tags = tool.tags
      data.cardImage = tool.cardImage

      tool.toolTypeObjective.forEach(item => {
        if (item.theme.active) {
          data.objectives.push(item.theme.alias)
        }
      })

      this.attemptToggleToolActive(data).then((response) => {
        this.setFeedback({
          message: response.data.active
            ? this.$t('tool:feedback.active.success')
            : this.$t('tool:feedback.deactivate.success')
        })

        this.getToolsList()
      }).catch(({ data }) => {
        if (data?.error?.validation?.objectives === 'invalid_objectives_array_required') {
          this.setFeedback({ message: this.$t('tool:feedback.deactivate.error.objectives.inactives') })

          this.$router.push({
            name: 'tools.manage',
            params: { id: tool.id }
          })
        } else {
          this.setFeedback({ message: this.$t('global.error') })
        }
      }).finally(() => {
        this.setFetching(false)
      })
    }
  }
}
</script>

<template>
  <div class="main-content">
    <ContentHeader
      :title="$t('tools:header.title')"
      :description="$t('tools:header.description')"
      background="/assets/images/themes/default/png/partners_banner.png"
    >
      <Tabs
        slot="tabs"
        dark
        :links="tabLinks"
      />
    </ContentHeader>
    <FilterList>
      <Action
        v-if="canWrite"
        slot="button"
        primary
        large
        fixed-width
        type="button"
        :text="$t('tool.list:btn.add')"
        @click="createNewTool"
      />
      <FilterListSearch
        slot="search"
        :hide-error-details="true"
        @search="searchToolsPage"
      />
    </FilterList>
    <div
      v-if="toolsList.length"
      class="center thematic-pages-table"
    >
      <Datatable :items="toolsList">
        <template slot="thead">
          <tr>
            <th class="th">
              {{ $t('tools:datatable.header.1') }}
            </th>
            <th
              class="th"
              width="200"
            >
              {{ $t('tools:datatable.header.2') }}
            </th>
            <th
              class="th"
              width="100"
            >
              {{ $t('tools:datatable.header.6') }}
            </th>
            <th
              class="th"
              width="40"
            />
          </tr>
        </template>
        <template
          slot="tbody"
          slot-scope="props"
        >
          <tr>
            <td class="td">
              <span class="td-text bolder">{{ props.item.title }}</span>
            </td>
            <td class="td">
              <span class="td-text bolder">{{ props.item.toolType.title }}</span>
            </td>
            <td class="td">
              <span
                v-if="!!props.item.active"
                class="td-tag active-ribbon"
              >{{ $t('global.status:active') }}</span>
              <span
                v-else
                class="td-tag inactive-ribbon"
              >{{ $t('global.status:inactive') }}</span>
            </td>
            <td
              class="td td-actions"
              width="40"
            >
              <Dropdown
                icon="dots-vertical"
                right
              >
                <DropdownLink
                  v-if="canWrite"
                  :text="$t('global:edit')"
                  @click="edit(props.item.id)"
                />
                <DropdownLink
                  v-if="!props.item.active && canWrite"
                  :text="$t('global:activate')"
                  @click="toggleActive(props.item)"
                />
                <DropdownLink
                  v-else-if="canWrite"
                  :text="$t('global:deactivate')"
                  @click="toggleActive(props.item)"
                />
              </Dropdown>
            </td>
          </tr>
        </template>
      </Datatable>
      <Pagination
        :active-page="pagination.page"
        :page-count="pagination.lastPage"
        @firstPage="firstPage"
        @lastPage="lastPage"
        @nextPage="nextPage"
        @previousPage="prevPage"
      />
    </div>
    <div
      v-else
      class="center thematic-pages-table"
    >
      <p>Nenhuma ferramenta encontrada para o termo pesquisado</p>
    </div>
  </div>
</template>
