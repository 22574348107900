<script>

import { app } from '@/main'

const TRANSLATION = {
  no_result: app.$t('global:datatable.no.result')
}

export default {
  name: 'Datatable',
  components: {
    Draggable: () => import('vuedraggable'),
    InfiniteScroll: () => import('@/components/general/InfiniteScroll')
  },

  props: {
    items: {
      type: Array,
      default () {
        return []
      }
    },

    title: {
      type: String,
      default: null
    },

    drag: {
      type: Boolean,
      default: false
    },

    hasFade: {
      type: Boolean,
      default: false
    },

    hasInfiniteScroll: {
      type: Boolean,
      default: false
    },

    card: {
      type: Boolean,
      default: false
    },

    dark: {
      type: Boolean,
      default: false
    },

    light: {
      type: Boolean,
      default: false
    },

    hideHorizontalLine: {
      type: Boolean,
      default: false
    },

    isLoadingMore: {
      type: Boolean,
      default: false
    },

    emptyMessage: {
      type: String
    }
  },

  computed: {
    dragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: !this.drag,
        ghostClass: 'ghost'
      }
    }
  },
 
  watch: {
    items: {
      deep: true,
      immediate: true,
      handler (value) {
        // TODO: Change the fixed value of 5 for a prop passed to the Datatable component
        if (this.hasInfiniteScroll && !this.isLoadingMore && value.length < 5) {
          this.$emit('loadMore')
        }
      }
    }
  },

  created () {
    this.setup()
  },

  mounted () {
    if (this.hasInfiniteScroll) {
      this.$refs.datatable.addEventListener('scroll', (event) => {
        if (this.isLoadingMore) return
        const tbody = event.target
        const tbodyHeight = tbody.offsetHeight
        const tbodyScrollHeight = tbody.scrollHeight
        const tbodyScrollTop = tbody.scrollTop

        if (tbodyScrollTop >= (tbodyScrollHeight - tbodyHeight)) {
          this.$emit('loadMore')
        }
      })
    }
  },

  methods: {
    setup () {
      this.TRANSLATION = TRANSLATION
    }
  }
}
</script>

<template>
  <div
    class="datatable-wrapper"
    :class="{
      'theme-dark': dark,
      'theme-light': light,
      'datatable-wrapper-card': card,
      'hide-h-line': hideHorizontalLine
    }"
  >
    <span
      v-if="title"
      class="datatable-title"
    >{{ title }}</span>
    <div class="datatable-inner">
      <table class="datatable">
        <thead v-if="$slots.thead">
          <slot name="thead" />
        </thead>
        <slot name="tsearch" />
        <tbody ref="datatable">
          <Draggable
            v-if="drag"
            class="drag-container"
            :list="items"
            v-bind="dragOptions"
          >
            <template v-if="hasFade">
              <TransitionGroup name="fade">
                <slot
                  v-for="(item, index) in items"
                  name="tbody"
                  :item="item"
                  :index="index"
                />
              </TransitionGroup>
            </template>

            <template v-else>
              <slot
                v-for="(item, index) in items"
                name="tbody"
                :item="item"
                :index="index"
              />
            </template>
          </Draggable>

          <template v-if="!drag">
            <template v-if="hasFade">
              <TransitionGroup name="fade">
                <slot
                  v-for="(item, index) in items"
                  name="tbody"
                  :item="item"
                  :index="index"
                />
              </TransitionGroup>
            </template>

            <template v-else>
              <slot
                v-for="(item, index) in items"
                name="tbody"
                :item="item"
                :index="index"
              />
            </template>
          </template>

          <template v-if="!items || (items && items.length === 0) && emptyMessage">
            <td class="td td-full">
              <span class="td-text">{{ TRANSLATION[emptyMessage] }}</span>
            </td>
          </template>

          <Transition name="fade">
            <InfiniteScroll
              v-if="hasInfiniteScroll && isLoadingMore"
            />
          </Transition>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/datatable.css"></style>

<style>
.link-selection > div {
  width: 100%;
}

/* Linkable items */
.linkable-items .datatable .filter-search {
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  margin-bottom: 8px;
  border-radius: 8px;
  padding: 8px 16px;
  display: block;
  width: 100%;
}

.linkable-items .datatable .filter-search .filter-search-form,
.linkable-items .datatable .filter-search .filter-search-form.opened {
  width: 100%;
}

.linkable-items .datatable .filter-search-form .form-input-prepend-label {
  color: rgba(0,0,0,0.7);
  font-weight: 400;
  text-align: left;
  width: 960px;
}

.linkable-items .datatable .filter-search .icon-wrapper {
  color: rgba(0,0,0,0.7);
}

.linkable-items .datatable tbody {
  border: 1px solid #00000024;
  box-sizing: border-box;
  border-radius: 8px;
  overflow-x: hidden;
  max-height: 400px;
  margin-top: 12px;
  overflow-y: auto;
  display: block;
  padding: 12px;
}

/* width */
.linkable-items .datatable tbody::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.linkable-items .datatable tbody::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px #dddddd55;
}

/* Handle */
.linkable-items .datatable tbody::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 4px;
}

/* Handle on hover */
.linkable-items .datatable tbody::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

.linkable-items .datatable tr {
  border: 1px solid rgba(0, 0, 0, 0.16);
  transition: all 200ms ease;
  box-sizing: border-box;
  margin-bottom: 8px;
  border-radius: 8px;
  padding: 16px 8px;
  box-shadow: none;
  cursor: pointer;
  display: block;
}

.linkable-items .datatable tr td.td-button {
  transition: all 200ms ease;
}

.linkable-items .datatable tr td.td-small {
  font-size: 15px;
  margin-top: 4px;
}

.linkable-items .datatable tr:hover {
  background: rgba(0, 0, 0, 0.04);
  transform: translateX(8px);
}

.linkable-items .datatable tr:hover td.td-button {
  transform: scale(1.5);
}

.linkable-items .datatable th,
.linked-items .datatable th {
  text-align: center;
}

/* Linked items */
.linkable-items,
.linked-items {
  min-width: calc(50% - 41px);
  width: calc(50% - 41px);
}

.linked-items .datatable tr {
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: none;
  display: block;
  padding: 8px;
}

.linked-items .datatable tr.tr-draggable {
  cursor: grabbing;
}

/* Animations */
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0
}
</style>
