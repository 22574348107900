export const toolsList = getters => {
  return getters.toolsList
}

export const typeToolsList = getters => {
  return getters.typeToolsList
}

export const getManageTool = getters => {
  return getters.manageTool
}

export const getManageTypeTool = getters => {
  return getters.manageTypeTool
}
