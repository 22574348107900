<script>
import { mapActions } from 'vuex'
import { required, minValue, maxValue } from 'vuelidate/lib/validators'
import { DateTime } from 'luxon'
import moment from 'moment'
import { isBefore } from 'date-fns'

export default {
  name: 'SessionsManage',
  components: {
    AttendanceList: () => import('@/modules/Sessions/components/AttendanceList'),
    Tabs: () => import('@/components/general/Tabs'),
    Radio: () => import('@/components/general/Radio'),
    Action: () => import('@/components/general/Action'),
    ActionBar: () => import('@/components/general/ActionBar'),
    Datepicker: () => import('@/components/general/Datepicker'),
    InputField: () => import('@/components/general/InputField'),
    FormSection: () => import('@/components/general/FormSection'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Autocomplete: () => import('@/components/general/Autocomplete')
  },

  data () {
    return {
      tabLinks: [
        {
          text: 'sessions.manage:tab.session'
        },
        {
          text: 'sessions.manage:tab.attendance'
        }
      ],

      studentsList: [],
      studentListPagination: {
        page: 1,
        lastPage: 1,
        limit: 12
      },

      tabLinkActive: 0,
      formData: {
        showOnPortal: 0,
        solutionFound: null,
        startDate: null,
        vacancies: null,
        endDate: null,
        name: null,
        allowEnrollInitDate: null,
        allowEnrollFinishDate: null
      },

      allSolutions: [],
      solutionsFiltered: [],
      backUrl: { name: 'sessions.index' },
      modalConfirm: false,
      modalLeave: false,
      showOnPortal: null,
      showOnPortalOptions: [
        {
          label: this.$t('global:form.sessions.enrollment.option.true'),
          value: 1
        },
        {
          label: this.$t('global:form.sessions.enrollment.option.false'),
          value: 0
        }
      ]
    }
  },

  validations: {
    formData: {
      showOnPortal: { required },
      solutionFound: { required },
      vacancies: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(999999999)
      },

      startDate: {
        required,
        dateBefore: function (event) {
          if (!this.isEditing) {
            const startDate = moment(event)
            const finalDate = moment()

            return startDate.isValid() ? startDate.isSameOrAfter(finalDate) : true
          }

          return true
        },

        dateAfter: function (event) {
          if (!this.isEditing) {
            const startDate = moment(event)
            const finalDate = moment(this.formData.endDate)

            return finalDate.isValid() ? finalDate.isAfter(startDate) : true
          }

          return true
        }
      },

      endDate: {
        required,
        dateBefore: function (event) {
          const startDate = moment(this.formData.startDate)
          const finalDate = moment(event)

          return startDate.isValid() ? startDate.isBefore(finalDate) : true
        }
      },

      allowEnrollInitDate: {
        required,
        dateBefore: function (event) {
          if (!this.isEditing) {
            const enrollStartDate = moment(event)
            const finalDate = moment()
            const startDate = moment(this.formData.startDate)
            const currentDate = enrollStartDate.isValid() ? enrollStartDate.isSameOrAfter(finalDate) : true
            const sessionDate = startDate.isValid() ? enrollStartDate.isSameOrBefore(startDate) : true

            return currentDate && sessionDate
          }

          return true
        },

        dateAfter: function (event) {
          if (!this.isEditing) {
            const startDate = moment(event)
            const finalDate = moment(this.formData.allowEnrollFinishDate)

            return finalDate.isValid() ? finalDate.isAfter(startDate) : true
          }

          return true
        }
      },

      allowEnrollFinishDate: {
        required,
        dateBefore: function (event) {
          const startDate = moment(this.formData.allowEnrollInitDate)
          const finalDate = moment(event)
          const endDate = moment(this.formData.endDate)
          const enrollDate = startDate.isValid() ? startDate.isBefore(finalDate) : true
          const sessionDate = endDate.isValid() ? finalDate.isSameOrBefore(endDate) : true

          return enrollDate && sessionDate
        }
      },

      name: { required }
    }
  },

  computed: {
    isEditing () {
      return !!(this.$route.params.id || this.formData.id)
    },

    isFinished () {
      if (!this.formData.endDate) {
        return false
      }

      const endDate = new Date(this.formData.endDate.replace(/-/g, '/')
        .replace(/T.+/, ''))

      return isBefore(endDate, new Date())
    },

    solutionId () {
      if (this.formData.solutionFound) {
        return !this.isEditing ? this.formData.solutionFound[0].value : this.formData.solutionFound.value
      }

      return 0
    },

    isLiveCourse () {
      return this.formData.solutionFound !== null &&
      this.formData.solutionFound[0] &&
      this.formData.solutionFound[0].alias === 'videoconferencia'
    },

    isCourseNotLive () {
      return this.formData.solutionFound !== null &&
      this.formData.solutionFound[0] &&
      this.formData.solutionFound[0].alias !== 'videoconferencia'
    }
  },

  created () {
    if (this.$route.meta.indexTab) {
      this.changeTab(this.$route.meta.indexTab)
    }

    this.setFetching(true)

    this.attemptListClosedSolutions()
      .then((response) => {
        this.allSolutions = response.data.data.map(solution => {
          return {
            label: solution.name,
            value: solution.id,
            name: solution.solutionType.name,
            alias: solution.solutionType.alias
          }
        })

        this.solutionsFiltered = this.allSolutions
      })
      .finally(() => {
        if (this.isEditing || this.$route.params.id) {
          if (this.$store.getters.getManagingSession &&
          this.$store.getters.getManagingSession.id.toString() === this.$route.params.id.toString()) {
            this.formData = {
              ...this.formData,
              ...this.$store.getters.getManagingSession
            }

            const solutionId = this.formData.solution.id

            this.formData.solutionFound = this.allSolutions.find(filtered => filtered.value === solutionId)
            this.formData.showOnPortal = this.formData.showOnPortal ? 1 : 0

            this.formData.startDate = DateTime.fromISO(this.formData.initDate)
              .toFormat('yyyy-MM-dd HH:mm')

            this.formData.endDate = DateTime.fromISO(this.formData.finishDate)
              .toFormat('yyyy-MM-dd HH:mm')

            this.formData.allowEnrollInitDate = DateTime.fromISO(this.formData.allowEnrollInitDate)
              .toFormat('yyyy-MM-dd HH:mm')

            this.formData.allowEnrollFinishDate = DateTime.fromISO(this.formData.allowEnrollFinishDate)
              .toFormat('yyyy-MM-dd HH:mm')
          } else {
            this.setFeedback({ message: this.$t('sessions:feedback.editing.error') })
            this.$router.push({ name: 'sessions.index' })
          }
        }

        this.setFetching(false)
      })
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptUpdateSession',
      'attemptSaveActiveSession',
      'attemptListClosedSolutions',
      'attemptGetSessionStudentsList',
      'attemptSaveSessionAttendanceList'
    ]),

    changeTab (index) {
      if (index === 1) {
        this.$router.push({
          name: 'sessions.attendance',
          params: { id: this.$route.params.id }
        })
          .catch(() => {})

        this.attemptGetStudents()
      }

      if (index === 0) {
        this.$router.push({
          name: 'sessions.manage',
          params: { id: this.$route.params.id }
        })
          .catch(() => {})
      }

      this.tabLinkActive = index
    },

    formatFormData () {
      const data = {}

      data.solution_id = this.solutionId
      data.name = this.formData.name
      data.vacancies = this.formData.vacancies
      data.ini_date = this.formData.startDate
      data.finish_date = this.formData.endDate
      data.show_on_portal = this.formData.showOnPortal
      data.allow_enroll_init_date = this.formData.allowEnrollInitDate
      data.allow_enroll_finish_date = this.formData.allowEnrollFinishDate

      return data
    },

    submitCreation () {
      this.setFetching(true)

      this.attemptSaveActiveSession(this.formatFormData())
        .then(() => {
          this.setFeedback({ message: this.$t('sessions:feedback.created.success') })
          this.$router.push({ name: 'sessions.index' })
        })
        .catch(() => {
          this.setFeedback({ message: this.$t('sessions:feedback.created.error') })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    submitUpdate () {
      const formData = this.formatFormData()

      this.setFetching(true)

      this.attemptUpdateSession({
        id: this.$route.params.id,
        data: formData
      })
        .then(() => {
          this.setFeedback({ message: this.$t('sessions:feedback.updated.success') })
          this.$router.push({ name: 'sessions.index' })
        })
        .catch(() => {
          this.setFeedback({ message: this.$t('sessions:feedback.updated.error') })
          this.$router.push({ name: 'sessions.index' })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    submit (next) {
      switch (this.tabLinkActive) {
        case 0:
          this.sessionInfoSubmit(next)
          break
        case 1:
          this.attendanceSubmit()
          break
      }
    },

    sessionInfoSubmit (next) {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.$route.params.id ? this.submitUpdate(next) : this.submitCreation(next)
      } else {
        this.setFeedback({ message: this.$t('sessions:feedback.validation.error') })
      }
    },

    attendanceSubmit () {
      this.setFetching(true)
      const sessionId = this.$route.params.id

      const students = this.studentsList
        .filter(student => {
          return !!student.attendance
        })
        .map(student => {
          return student.user.id
        })

      this.attemptSaveSessionAttendanceList({
        sessionId,
        students: { users: students }
      })
        .then(() => {
          this.$router.push({ name: 'sessions.index' })
          this.setFeedback({ message: this.$t('session.attendance:save.success') })
        })
        .catch(() => {
          this.setFeedback({ message: this.$t('session.attendance:save.error') })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    loadMoreStudents () {
      if (this.studentListPagination.page < this.studentListPagination.lastPage) {
        this.studentListPagination.page += 1
        this.attemptGetStudents()
      }
    },

    attemptGetStudents () {
      if (!this.$route.params.id) {
        this.$router.push({ name: 'sessions.index' })
      }

      this.setFetching(true)

      this.attemptGetSessionStudentsList({
        session_id: this.$route.params.id,
        pagination: this.studentListPagination
      })
        .then(({ data }) => {
          data.data.forEach(student => {
            this.studentsList.push({
              ...student,
              attendance: student.status === 'finished'
            })
          })

          this.studentListPagination.page = data.actualPage
          this.studentListPagination.lastPage = data.lastPage
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    leave () {
      this.modalConfirm = false

      this.$nextTick(() => {
        this.$router.push(this.backUrl)
      })
    },

    fetchSolutions (search) {
      const searchToLower = search.toLowerCase()

      this.solutionsFiltered = this.allSolutions.filter((solution) => {
        const labelSearchable = solution.label.toLowerCase()

        return labelSearchable.match(searchToLower)
      })
    },

    isVideoconferencia () {
      return this.$store.getters.getManagingSession.solution.solutionType.alias === 'videoconferencia'
    }
  }
}
</script>

<template>
  <div class="main-content sessions-create">
    <ContentHeader
      :title="$t('sessions.create:header.title')"
      dark-theme
    >
      <Action
        slot="back"
        type="button"
        :text="$t('global:back.sessions')"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave()"
      />
      <ActionBar slot="actionbar" />
      <template slot="buttons">
        <Action
          :text="$t('global:form.save')"
          type="button"
          flat
          dark
          @click="submit(false)"
        />
      </template>
    </ContentHeader>
    <div class="main-content-inner">
      <div class="center">
        <div class="sessions-create-header">
          <p
            class="sessions-create-description"
            v-html="$t('sessions.create:header.description')"
          />
          <Tabs
            v-if="getContextPermission('session_attendance') === 'write' && isEditing && isVideoconferencia()"
            class="sessions-manage-tabs"
            :links="tabLinks"
            :index-active="tabLinkActive"
            @tabChange="changeTab($event)"
          />
        </div>
        <form
          v-if="tabLinkActive === 0"
          class="form"
          @submit.prevent="submit()"
        >
          <h2 class="text-center">
            {{ $t('sessions.create:section.header.1') }}
          </h2>
          <FormSection>
            <Autocomplete
              :key="isEditing && solutionId"
              v-model="formData.solutionFound"
              :disabled="isEditing"
              :label="$t('sessions.create:found.solutions')"
              :items="solutionsFiltered"
              async
              show-itens-on-focus
              option-property="label"
              :validation="$v.formData.solutionFound"
              :under-description="$t('sessions.manage:field.description.solutions.found')"
              @search="fetchSolutions"
            />
            <div
              v-if="isLiveCourse"
              class="info-instructions"
            >
              <h2 class="info">
                {{ $t('sessions.create:found.type') }} {{ formData.solutionFound[0].name }}
              </h2>
              <div class="instructions">
                <h2>{{ $t('sessions.create:found.instructions') }}</h2>
                <p>{{ $t('sessions.create:found.instructions.step.1') }}</p>
                <p>{{ $t('sessions.create:found.instructions.step.2') }}</p>
                <p>{{ $t('sessions.create:found.instructions.step.3') }}</p>
                <p>{{ $t('sessions.create:found.instructions.step.4') }}</p>
              </div>
            </div>
            <div
              v-else-if="isCourseNotLive"
              class="info-instructions"
            >
              <h2 class="info">
                {{ $t('sessions.create:found.type') }} {{ formData.solutionFound[0].name }}
              </h2>
              <div class="instructions">
                <h2>{{ $t('sessions.create:found.instructions') }}</h2>
                <p>{{ $t('sessions.create:found.instructions.step.1') }}</p>
                <p>{{ $t('sessions.create:found.instructions.step.2') }}</p>
              </div>
            </div>
          </FormSection>
          <h2 class="text-center">
            {{ $t('sessions.create:section.header.2') }}
          </h2>
          <InputField
            v-model="formData.name"
            :label="$t('global:form.sessions.name')"
            :validation="$v.formData.name"
            :counter="90"
            :disabled="isEditing"
          />
          <div class="form-dates">
            <Datepicker
              v-model="formData.allowEnrollInitDate"
              class="col-5"
              :label="$t('global:form.sessions.enrollStartDate')"
              minutes-in-thirty
              min="2020-01-01"
              :validation="$v.formData.allowEnrollInitDate"
              time
              :disabled="isEditing"
            />
            <Datepicker
              v-model="formData.allowEnrollFinishDate"
              class="col-5"
              :label="$t('global:form.sessions.enrollEndDate')"
              minutes-in-thirty
              min="2020-01-01"
              :validation="$v.formData.allowEnrollFinishDate"
              time
              :disabled="isFinished"
            />
          </div>
          <div class="form-dates">
            <Datepicker
              v-model="formData.startDate"
              class="col-5"
              :label="$t('global:form.sessions.startDate')"
              minutes-in-thirty
              min="2020-01-01"
              :validation="$v.formData.startDate"
              time
              :disabled="isEditing"
            />
            <Datepicker
              v-model="formData.endDate"
              class="col-5"
              :label="$t('global:form.sessions.endDate')"
              minutes-in-thirty
              min="2020-01-01"
              :validation="$v.formData.endDate"
              time
              :disabled="isFinished"
            />
          </div>
          <InputField
            v-model="formData.vacancies"
            type="number"
            :min="0"
            :max="999999999"
            :label="$t('global:form.sessions.vacancies')"
            :validation="$v.formData.vacancies"
            :disabled="isFinished"
            @wheel.prevent="$event.target.blur()"
          />
          <FormSection :title="$t('sessions.create:section.header.3')">
            <Radio
              v-model="formData.showOnPortal"
              :items="showOnPortalOptions"
              horizontal
              :validation="$v.formData.showOnPortal"
            />
          </FormSection>
        </form>
        <AttendanceList
          v-if="tabLinkActive === 1"
          :students-list="studentsList"
          :has-more="studentListPagination.page < studentListPagination.lastPage"
          @loadMore="loadMoreStudents()"
        />
      </div>
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/sessions.css"></style>

<style>
.info-instructions {
  margin-bottom: 40px;
}

.info-instructions .info {
  color: #005EB8;
  padding: 0;
  margin: 20px 0;
  text-transform: none;
}

.info-instructions .instructions {
  color: #fff;
  background: #005EB8;
  border-radius: 15px;
  padding: 20px;
}

.info-instructions .instructions h2 {
  color: #fff;
  padding: 0;
  margin: 0 0 20px 0;
}

.info-instructions .instructions p {
  font-size: 15px;
  padding: 2px 0 2px 25px;
}
</style>
