<script>
import { required } from 'vuelidate/lib/validators'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ManagementUserProfilesManage',

  components: {
    Modal: () => import('@/components/general/Modal'),
    InputField: () => import('@/components/general/InputField'),
    FormSection: () => import('@/components/general/FormSection'),
    Radio: () => import('@/components/general/Radio'),
    Action: () => import('@/components/general/Action')
  },

  data () {
    return {
      formData: {
        id: null,
        name: null,
        permissions: {
          solution: null,
          tracks: null,
          programs: null,
          // themes: null,
          sessions: null,
          session_attendance: null,
          users: null,
          enrollment: null,
          entrepreneur_profiles: null,
          profiles: null,
          solution_themes: null,
          surveys: null,
          certificates: null,
          banners: null,
          gamification: null,
          reports: null,
          reports_students: null,
          reports_enrollments: null,
          reports_surveys_answers: null,
          reports_canvas_discussion: null,
          reports_learning_path_resume: null,
          reports_solutions_resume: null,
          reports_rae_services: null,
          reports_solution_alerts: null,
          reports_sas_events: null,
          reports_financial: null,
          reports_comments: null,
          configurations: null,
          dashboard: null,
          impersonate: null,
          search_engine: null,
          notification: null,
          thematic_pages: null,
          filters: null,
          mediation_plans: null,
          comments: null,
          tools: null
        }
      },

      basicOptions: [
        {
          label: this.$t('management.user.profiles:modal.form.permissions.view'),
          value: 'view'
        },
        {
          label: this.$t('management.user.profiles:modal.form.permissions.add.edit'),
          value: 'add.edit'
        },
        {
          label: this.$t('management.user.profiles:modal.form.permissions.disable'),
          value: 'disable'
        }
      ],

      dashboardOptions: [
        {
          label: this.$t('management.user.profiles:modal.form.permissions.view'),
          value: 'view'
        },
        {
          label: this.$t('management.user.profiles:modal.form.permissions.disable'),
          value: 'disable'
        }
      ],

      searchEngineOptions: [
        {
          label: this.$t('management.user.profiles:modal.form.permissions.add.edit'),
          value: 'add.edit'
        },
        {
          label: this.$t('management.user.profiles:modal.form.permissions.disable'),
          value: 'disable'
        }
      ],

      impersonateOptions: [
        {
          label: this.$t('management.user.profiles:modal.form.permissions.view'),
          value: 'view'
        },
        {
          label: this.$t('management.user.profiles:modal.form.permissions.disable'),
          value: 'disable'
        }
      ]
    }
  },

  validations: {
    formData: {
      name: {
        required
      },

      permissions: {
        solution: {
          required
        },

        tracks: {
          required
        },

        programs: {
          required
        },

        // themes: {
        //   required
        // },
        sessions: {
          required
        },

        session_attendance: {
          required
        },

        users: {
          required
        },

        enrollment: {
          required
        },

        entrepreneur_profiles: {
          required
        },

        profiles: {
          required
        },

        solution_themes: {
          required
        },

        surveys: {
          required
        },

        certificates: {
          required
        },

        banners: {
          required
        },

        gamification: {
          required
        },

        reports: {
          required
        },

        reports_students: {
          required
        },

        reports_enrollments: {
          required
        },

        reports_surveys_answers: {
          required
        },

        reports_canvas_discussion: {
          required
        },

        reports_learning_path_resume: {
          required
        },

        reports_solutions_resume: {
          required
        },

        reports_rae_services: {
          required
        },

        reports_solution_alerts: {
          required
        },

        reports_sas_events: {
          required
        },

        reports_financial: {
          required
        },

        reports_comments: {
          required
        },

        configurations: {
          required
        },

        dashboard: {
          required
        },

        search_engine: {
          required
        },

        impersonate: {
          required
        },

        notification: {
          required
        },

        thematic_pages: {
          required
        },

        filters: {
          required
        },

        mediation_plans: {
          required
        },

        comments: {
          required
        },

        tools: {
          required
        }
      }
    }
  },

  computed: {
    ...mapState([ 'fetching' ]),

    isEditing () {
      return !!this.$route.params.id
    },

    title () {
      if (this.isEditing) {
        return this.$t('management.user.profiles:modal.title.editing')
      }

      return this.$t('management.user.profiles:modal.title.add')
    },

    sections () {
      return [
        {
          tag: 'solution'
        },
        {
          tag: 'tracks'
        },
        {
          tag: 'programs'
        },
        // {
        //   tag: 'themes'
        // },
        {
          tag: 'sessions'
        },
        {
          tag: 'session_attendance'
        },
        {
          tag: 'users'
        },
        {
          tag: 'enrollment'
        },
        {
          tag: 'entrepreneur_profiles'
        },
        {
          tag: 'profiles'
        },
        {
          tag: 'solution_themes'
        },
        {
          tag: 'surveys'
        },
        {
          tag: 'certificates'
        },
        {
          tag: 'banners'
        },
        {
          tag: 'gamification'
        },
        {
          tag: 'reports'
        },
        {
          tag: 'reports_students'
        },
        {
          tag: 'reports_enrollments'
        },
        {
          tag: 'reports_surveys_answers'
        },
        {
          tag: 'reports_canvas_discussion'
        },
        {
          tag: 'reports_learning_path_resume'
        },
        {
          tag: 'reports_solutions_resume'
        },
        {
          tag: 'reports_rae_services'
        },
        {
          tag: 'reports_solution_alerts'
        },
        {
          tag: 'reports_sas_events'
        },
        {
          tag: 'reports_financial'
        },
        {
          tag: 'reports_comments'
        },
        {
          tag: 'configurations'
        },
        {
          tag: 'dashboard',
          items: this.dashboardOptions
        },
        {
          tag: 'search_engine',
          items: this.searchEngineOptions
        },
        {
          tag: 'impersonate',
          items: this.impersonateOptions
        },
        {
          tag: 'notification'
        },
        {
          tag: 'thematic_pages'
        },
        {
          tag: 'filters'
        },
        {
          tag: 'mediation_plans'
        },
        {
          tag: 'comments'
        },
        {
          tag: 'tools'
        }
      ]
    }
  },

  created () {
    if (this.isEditing) {
      this.setup()
    }
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetProfile',
      'attemptCreateProfile',
      'attemptUpdateProfile',
      'attemptGetLoggedUser'
    ]),

    formatFormData () {
      const formData = {
        id: this.formData.id,
        name: this.formData.name,
        permissions: {}
      }

      for (const i in this.formData.permissions) {
        if (this.formData.permissions[i] === 'add.edit') {
          formData.permissions[i] = 'write'
        }

        if (this.formData.permissions[i] === 'view') {
          formData.permissions[i] = 'read'
        }
      }

      return formData
    },

    // leave () {
    //   this.modalConfirm = false
    //   this.$nextTick(() => {
    //     this.$router.push(this.backUrl)
    //   })
    // },

    submitCreation () {
      this.setFetching(true)

      this.attemptCreateProfile(this.formatFormData()).then(() => {
        this.setFeedback({ message: this.$t('management.user.profile:feedback.created.success') })
        this.$router.push({ name: 'management.user.profiles' })
      }).catch(() => {
        this.setFeedback({ message: this.$t('management.user.profile:feedback.created.error') })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    submitUpdate () {
      this.setFetching(true)

      this.attemptUpdateProfile(this.formatFormData()).then(() => {
        this.setFeedback({ message: this.$t('management.user.profile:feedback.update.success') })

        this.attemptGetLoggedUser().then(() => {
          this.$router.push({ name: 'management.user.profiles' })
        })
      }).catch(() => {
        this.setFeedback({ message: this.$t('management.user.profile:feedback.update.error') })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    submit (next) {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.isEditing ? this.submitUpdate(next) : this.submitCreation(next)
      } else {
        this.setFeedback({ message: this.$t('management.user.profile:feedback.validation.error') })
      }
    },

    closeModal () {
      this.$router.push({ name: 'management.user.profiles' })
    },

    setup () {
      this.setFetching(true)

      this.attemptGetProfile(this.$route.params.id).then((data) => {
        this.formData = data
      })
        .finally(() => {
          this.setFetching(false)
        })
    }
  }
}
</script>

<template>
  <Modal
    :active="!fetching"
    @close="closeModal"
  >
    <div class="modal-form modal-add-user">
      <span class="modal-subtitle">{{ $t('management:modal.subtitle') }}</span>
      <h2 class="modal-title">
        {{ title }}
      </h2>
      <span class="modal-form-description">
        <strong>{{ $t('management.user.profiles:modal.description_bold') }}</strong>
        {{ $t('management.user.profiles:modal.description') }}
      </span>

      <form @submit.prevent="submit()">
        <InputField
          v-model="formData.name"
          dark
          :label="$t('management.user.profiles:modal.form.name')"
          :validation="$v.formData.name"
        />

        <p class="form-group-title">
          {{ $t('management.user.profiles:modal.form.permissions') }}
        </p>

        <FormSection
          v-for="section in sections"
          :key="section.title"
          uppercase
          :title="$t('management.user.profiles:modal.form.' + section.tag)"
        >
          <Radio
            v-model="formData.permissions[section.tag]"
            dark
            :items="section.items ? section.items : basicOptions"
            :validation="$v.formData.permissions[section.tag]"
          />
        </FormSection>

        <div class="form-submit text-center">
          <Action
            type="button"
            secondary
            large
            submit
            fixed-width
            :text="$t('global:form.save')"
          />
        </div>
      </form>
    </div>
  </Modal>
</template>

<style>
.form-group-title {
  font-family: 'Lato';
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  margin-top: 80px;
  margin-bottom: 50px;
}
</style>
