<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import { mapState, mapActions } from 'vuex'
import { formatSlug } from '@/support/utils/stringHelper'

const RICH_TEXT_EDITOR_DESCRIPTION_MAX_LENGTH = 600

const customMaxLength = (value) => {
  const tmp = document.createElement('DIV')

  tmp.innerHTML = value

  return (tmp.textContent || tmp.innerText || '').length <= RICH_TEXT_EDITOR_DESCRIPTION_MAX_LENGTH
}

const RICH_TEXT_EDITOR_TOOLBAR = [
  [
    'bold',
    'italic',
    'underline',
    'strike'
  ],
  [ 'clean' ],
  [ 'link' ],
  [
    {
      size: [
        'small',
        false,
        'large',
        'huge'
      ]
    }
  ]
]

export default {
  name: 'ManagementManage',

  components: {
    Action: () => import('@/components/general/Action'),
    FormSection: () => import('@/components/general/FormSection'),
    InputField: () => import('@/components/general/InputField'),
    Modal: () => import('@/components/general/Modal'),
    Radio: () => import('@/components/general/Radio'),
    SelectField: () => import('@/components/general/SelectField'),
    RichTextEditor: () => import('@/components/general/RichTextEditor'),
    ValidationMessage: () => import('@/components/general/ValidationMessage'),
    Upload: () => import('@/components/general/Upload'),
    ColorPicker: () => import('@/components/general/ColorPicker'),
    IconPicker: () => import('@/components/general/IconPicker')
  },

  data () {
    return {
      formData: {
        id: null,
        name: null,
        slug: null,
        callText: null,
        description: null,
        active: null,
        color: null,
        icon: null,
        banner: null,
        cardImage: null,
        hasExtraField: false,
        featured: 0,
        extraFields: []
      },

      formatedUrl: '',
      isSlugDuplicate: false,

      yesNoOptions: [
        {
          label: this.$t('global:yes'),
          value: true
        },
        {
          label: this.$t('global:no'),
          value: false
        }
      ],

      extraFieldTypeOptions: [
        {
          text: this.$t('management.index:modal.form.type.simple_text'),
          value: 'text',
          disabled: false
        },
        {
          text: this.$t('management.index:modal.form.type.unique'),
          value: 'unique',
          disabled: false
        },
        {
          text: this.$t('management.index:modal.form.type.multiple'),
          value: 'multiple',
          disabled: true
        }
      ],

      colorPickerOptions: [
        '#01AFE2',
        '#005EB8',
        '#8F82F6',
        '#C65FE2',
        '#2C8C99',
        '#36C4B0',
        '#1E90FF',
        '#FF4500',
        '#8A2BE2',
        '#2E8B57'
      ],

      iconPickerOptions: []
    }
  },

  validations: {
    formData: {
      name: {
        required
      },

      slug: {
        required,
        regexSlug: (event) => {
          const regexSlug = new RegExp('^[a-z0-9_]+(?:-[a-z0-9_]+)*$')

          return regexSlug.test(event)
        },

        slugDuplicate: function () {
          return !this.isSlugDuplicate
        }
      },

      callText: {
        required
      },

      description: {
        required,
        customMaxLength
      },

      banner: {
        required
      },

      cardImage: {
        required
      },

      extraFields: {
        required: requiredIf(function () {
          return this.formData.hasExtraField
        }),

        $each: {
          title: { required },
          type: { required },
          options: {
            required: requiredIf(function (item) {
              return [ 'unique' ].indexOf(item.type) > -1
            }),

            $each: {
              name: {
                required: requiredIf(function (item) {
                  return [ 'unique' ].indexOf(item.type) > -1
                })
              }
            }
          }
        }
      }
    }
  },

  computed: {
    ...mapState([ 'fetching' ]),
    isEditing () {
      return !!this.$route.params.id
    },

    title () {
      if (this.isEditing) {
        return this.$t('management.index:modal.title.editing')
      }

      return this.$t('management.index:modal.title.add')
    }
  },

  watch: {
    'formData.name' () {
      if (!this.$v.formData.slug.$dirty && !this.isEditing) {
        this.formData.slug = formatSlug(this.formData.name)
      }
    },

    'formData.slug' (event) {
      this.formData.slug = event.replace(new RegExp('[-]{2,}', 'g'), '-')

      this.debounceEvent(async () => {
        this.setFetching(true)

        this.setFetching(false)
      }, 1000)

      if (!this.isSlugDuplicate) return
      this.isSlugDuplicate = false

      this.formatedUrl = `${process.env.VUE_APP_PORTAL_URL}perfil/${this.formData.slug}`
    }
  },

  created () {
    this.RICH_TEXT_EDITOR_TOOLBAR = RICH_TEXT_EDITOR_TOOLBAR
    this.RICH_TEXT_EDITOR_DESCRIPTION_MAX_LENGTH = RICH_TEXT_EDITOR_DESCRIPTION_MAX_LENGTH

    this.populateIcons()

    if (this.isEditing) {
      const entrepreneurProfileManage = this.$store.state.Management.entrepreneurProfiles.filter((entrepreneur) => {
        return entrepreneur.id === this.$route.params.id
      })[0]

      if (!entrepreneurProfileManage) {
        this.setFeedback({ message: this.$t('management:feedback.edit.error') })
        this.closeModal()
      } else {
        this.formData = {
          ...this.formData,
          ...entrepreneurProfileManage
        }

        if (this.formData.color === '#34343400') {
          this.formData.color = '#8A2BE2'
        }

        if (this.formData.icon === 'image.jpg') {
          this.formData.icon = '/assets/images/themes/default/svg/themes/light.svg'
        }

        if (this.formData.cardImage === 'image.jpg') {
          this.formData.cardImage = '/assets/images/themes/default/imgs/profile-banner.webp'
        }

        if (this.formData.banner === 'image.jpg') {
          this.formData.banner = '/assets/images/themes/default/imgs/profile-banner.webp'
        }

        if (this.formData.hasExtraField) {
          this.formData.extraFields = this.formatOptions(entrepreneurProfileManage.entrepreneurProfileQuestion)
        }
      }
    }
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptSaveEntrepreneurProfile',
      'attemptEditEntrepreneurProfile'
    ]),

    submit () {
      this.$v.$touch()

      if (this.$v.$invalid) {
        this.debounceEvent(() => {
          const hasErrorElements = document.querySelector('.has-error')
          const top = hasErrorElements.offsetTop - 100

          document.querySelector('.modal-blocker')
            .scroll({
              top: top,
              behavior: 'smooth'
            })
        }, 100)

        return
      }

      this.isEditing ? this.submitEdit() : this.submitCreation()
    },

    submitCreation () {
      this.setFetching(true)
      this.active = true

      const data = {
        ...this.formData
      }

      if (!data.icon) {
        data.icon = '/assets/images/themes/default/svg/themes/light.svg'
      }

      if (!data.color) {
        data.color = '#8A2BE2'
      }

      return this.attemptSaveEntrepreneurProfile(data).then(() => {
        this.setFeedback({ message: this.$t('management:feedback.created.success') })
        this.closeModal()
      }).catch(e => {
        if (this.getErrorMessage(e) === 'entrepreneur_profile_already_exists_with_name') {
          this.setFeedback({ message: this.$t('management.ent.profile:feedback.already.exists.error') })
        } else if (this.getErrorMessage(e) === 'entrepreneur_profile_slug_already_in_use') {
          this.setFeedback({ message: this.$t('management.ent.profile:feedback.slug.already.exists.error') })
          this.isSlugDuplicate = true
          this.$v.formData.slug.$touch()
        } else {
          this.setFeedback({ message: this.$t('management:feedback.created.error') })
        }
      }).finally(() => {
        this.setFetching(false)
      })
    },

    async submitEdit () {
      this.setFetching(true)
      this.formData.id = this.$route.params.id

      const data = {
        ...this.formData
      }

      if (!data.icon) {
        data.icon = '/assets/images/themes/default/svg/themes/light.svg'
      }

      if (!data.color) {
        data.color = '#1595CE'
      }

      try {
        if (data.banner && typeof data.banner !== 'object') {
          const bannerBlob = await this.urlToBlob(data.banner)

          data.banner = bannerBlob
        }

        if (data.cardImage && typeof data.cardImage !== 'object') {
          const cardImageBlob = await this.urlToBlob(data.cardImage)

          data.cardImage = cardImageBlob
        }

        await this.attemptEditEntrepreneurProfile(data)
        this.setFeedback({ message: this.$t('management:feedback.edit.success') })
        this.closeModal()
      } catch (e) {
        if (this.getErrorMessage(e) === 'entrepreneur_profile_already_exists_with_name') {
          this.setFeedback({ message: this.$t('management.ent.profile:feedback.already.exists.error') })
        } else if (this.getErrorMessage(e) === 'entrepreneur_profile_slug_already_in_use') {
          this.setFeedback({ message: this.$t('management.ent.profile:feedback.slug.already.exists.error') })
          this.isSlugDuplicate = true
          this.$v.formData.slug.$touch()
        } else {
          this.setFeedback({ message: this.$t('management:feedback.edit.error') })
        }
      } finally {
        this.setFetching(false)
      }
    },

    closeModal () {
      this.$router.push({ name: 'management.index' })
    },

    getErrorMessage (e) {
      if (e.data && e.data.error && e.data.error.message) {
        return e.data.error.message
      }

      return null
    },

    addExtraField () {
      if (this.formData.extraFields.length < 3) {
        this.formData.extraFields.push({
          required: true,
          type: 'text',
          title: null,
          options: []
        })
      }
    },

    removeExtraField (index) {
      this.formData.extraFields.splice(index, 1)
    },

    addOption (field) {
      field.options.push({
        name: null,
        value: null
      })

      this.$forceUpdate()
    },

    removeOption (field, index) {
      field.options.splice(index, 1)
      this.$forceUpdate()
    },

    formatOptions (options) {
      return options.map(item => {
        item.options = item.entrepreneurProfileQuestionOption

        return item
      })
    },

    populateIcons () {
      const images = require.context(
        '/public/assets/images/themes/default/svg/themes/',
        false,
        /\.(svg)$/
      )

      images.keys().forEach((value) => {
        this.iconPickerOptions.push(value.slice(2))
      })
    },

    async urlToBlob (url) {
      const response = await fetch(url)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const blob = await response.blob()

      return blob
    }
  }
}
</script>

<template>
  <Modal
    :active="!fetching"
    @close="closeModal"
  >
    <div class="modal-form modal-add-user">
      <span class="modal-subtitle">{{ $t('management:modal.subtitle') }}</span>
      <h2 class="modal-title">
        {{ title }}
      </h2>
      <!--
 <span class="modal-form-description">
        <I18n path="management.index:modal.description">
          <strong>{{ $t('management.index:modal.description_bold') }}</strong>
        </I18n>
      </span>
-->

      <form @submit.prevent="submit()">
        <InputField
          v-model="formData.name"
          dark
          :validation="$v.formData.name"
          :label="$t('management.index:modal.form.title')"
          :counter="30"
        />

        <InputField
          v-model="formData.slug"
          dark
          :validation="$v.formData.slug"
          :under-description="formatedUrl"
          :counter="100"
          :label="$t('management.index:modal.form.slug')"
        />

        <InputField
          v-model="formData.callText"
          dark
          :validation="$v.formData.callText"
          :label="$t('management.index:modal.form.callText')"
          :counter="250"
        />

        <FormSection :title="$t('management.index:modal.form.description')">
          <RichTextEditor
            v-model="formData.description"
            :validation="$v.formData.description"
            :toolbar="RICH_TEXT_EDITOR_TOOLBAR"
            :max-length="RICH_TEXT_EDITOR_DESCRIPTION_MAX_LENGTH"
            class="rich-text"
            dark
          />
        </FormSection>

        <ColorPicker
          v-model="formData.color"
          :label="$t('management.index:modal.form.color')"
          :validation="$v.formData.color"
          :options="colorPickerOptions"
          class="form-item"
          dark
        />

        <IconPicker
          v-model="formData.icon"
          :label="$t('management.index:modal.form.icon')"
          :validation="$v.formData.icon"
          :options="iconPickerOptions"
          context="/assets/images/themes/default/svg/themes/"
          class="form-item"
          dark
        />

        <FormSection
          class="upload"
          :title="$t('global:upload.add.image.spotlight')"
        >
          <Upload
            v-model="formData.banner"
            icon="image-multiple"
            :label="$t('global:upload.add.image')"
            :description="$t('management.index:modal.form.image.banner')"
            cropper
            :width="1920"
            :height="640"
            :preview="0.22"
            :validation="$v.formData.banner"
            dark
          />
        </FormSection>

        <FormSection
          class="upload"
          :title="$t('global:upload.add.image.card')"
        >
          <Upload
            v-model="formData.cardImage"
            icon="image-multiple"
            :label="$t('global:upload.add.image')"
            :description="$t('management.index:modal.form.card.description')"
            cropper
            :width="987"
            :height="545"
            :preview="0.4"
            :validation="$v.formData.cardImage"
            dark
            exact-crop-image-size
          />
        </FormSection>

        <FormSection :title="$t('management.index:modal.form.has_extra_fields')">
          <Radio
            v-model="formData.hasExtraField"
            dark
            horizontal
            class="radio-extra-fields"
            :items="yesNoOptions"
            :under-description="$t('management.index:modal.form.has_extra_fields.under_description')"
          />
        </FormSection>

        <FormSection v-if="formData.hasExtraField">
          <ul class="extra-fields">
            <li
              v-for="(field, fieldIndex) in formData.extraFields"
              :key="fieldIndex"
            >
              <FormSection :title="field.title ? field.title : $tc('management.index:modal.form.extra_field_number', fieldIndex, { index: fieldIndex + 1 })">
                <SelectField
                  v-model="field.type"
                  :label="$t('management.index:modal.form.select_type')"
                  :validation="$v.formData.extraFields.$each[fieldIndex].type"
                  :items="extraFieldTypeOptions"
                  dark
                />

                <InputField
                  v-model="field.title"
                  :under-description="$t('management.index:modal.form.title.under_description')"
                  :validation="$v.formData.extraFields.$each[fieldIndex].title"
                  :label="$t('management.index:modal.form.extra_field_title')"
                  dark
                />

                <template v-if="field.type === 'unique'">
                  <Action
                    :text="$t('management.index:modal.form.add_extra_field_option')"
                    primary
                    dark
                    tiny
                    class="add-option"
                    icon="add"
                    icon-size="small"
                    type="button"
                    @click="addOption(field)"
                  />

                  <ul
                    v-if="field.options.length > 0"
                    class="extra-field-items"
                  >
                    <li
                      v-for="(option, optionIndex) in field.options"
                      :key="optionIndex"
                      class="extra-field-item flex-row"
                    >
                      <!--
 :validation="$v.formData.extraFields.$each[fieldIndex].options.$each[optionIndex].name"
-->
                      <InputField
                        v-model="option.name"
                        :label="`Item ${optionIndex + 1}`"
                        :disabled="field.disabled == 1"
                        :counter="100"
                        hide-optional
                        dark
                      />
                      <Action
                        icon="delete"
                        icon-right
                        dark
                        flat
                        type="button"
                        @click="removeOption(field, optionIndex)"
                      />
                    </li>
                  </ul>

                  <div
                    v-if="formData.extraFields[fieldIndex].options.length === 0"
                    class="form-item theme-dark"
                  >
                    <ValidationMessage :validation="$v.formData.extraFields.$each[fieldIndex].options" />
                  </div>
                </template>

                <Radio
                  v-model="field.required"
                  dark
                  horizontal
                  :items="yesNoOptions"
                  :under-description="$t('management.index:modal.form.extra_field_required')"
                />

                <Action
                  type="button"
                  dark
                  primary
                  icon="delete"
                  :text="$t('management.index:modal.form.remove_extra_field')"
                  @click="removeExtraField(fieldIndex)"
                />
              </FormSection>
            </li>
          </ul>

          <div
            v-if="formData.extraFields.length === 0"
            class="form-item theme-dark"
          >
            <ValidationMessage :validation="$v.formData.extraFields" />
          </div>

          <Action
            type="button"
            dark
            primary
            medium
            icon="add"
            :disabled="formData.extraFields.length >= 3"
            :text="formData.extraFields.length < 3 ? $t('management.index:modal.form.add_extra_field') : $t('management.index:modal.form.limit_reached')"
            @click="addExtraField"
          />
        </FormSection>

        <div class="form-submit text-center">
          <Action
            type="button"
            secondary
            large
            submit
            fixed-width
            :text="$t('global:form.save')"
          />
        </div>
      </form>
    </div>
  </Modal>
</template>

<style lang="scss">
.modal-add-user {
  .ql-toolbar.ql-snow {
    .ql-stroke, .ql-fill{
      stroke: #fff;
    }

    .ql-strike {
      .ql-fill {
        fill: #fff;
        stroke: none;
      }
    }

    .ql-picker.ql-size .ql-picker-label::before {
      color: white;
    }
  }

  .extra-fields {
    .form-section .form-item {
      display: block;
    }
  }

  .form-section .form-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.rich-text {
      display: block;
    }
  }

  form {
    text-align: center;

    .radio-extra-fields .form-input-subtext {
      padding-right: 0;
    }

    .form-input-subtext {
      font-size: 1.6em;
      font-weight: 200;
      margin: 10px 0;
    }
  }
}
</style>

<style src="@/assets/styles/themes/default/management.css"></style>
