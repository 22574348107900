import 'url-search-params-polyfill'

export const parse = payload => {
  const params = new URLSearchParams() // eslint-disable-line no-undef

  if (typeof payload !== 'object') return null

  const keys = Object.keys(payload)
  const length = keys.length

  for (let i = 0; i < length; i += 1) {
    let value = payload[keys[i]]
    const key = keys[i]

    if (value instanceof Array) {
      value.forEach((arrValue) => {
        params.append(key + '[]', arrValue)
      })
    } else {
      value = String(value)

      if (value !== null && value !== 'null' && value !== 'undefined' && value !== undefined && value.length) {
        params.append(key, value)
      }
    }
  }

  return params
}

export const parseToFormData = payload => {
  const params = new FormData() // eslint-disable-line no-undef

  if (typeof payload !== 'object') return null

  const keys = Object.keys(payload)
  const length = keys.length

  for (let i = 0; i < length; i += 1) {
    let value = payload[keys[i]]
    const key = keys[i]

    value = String(value)

    if (value !== null && value !== 'undefined' && value !== undefined && value.length) {
      params.append(key, value)
    }
  }

  return params
}

export const parseToFormDataWithArray = payload => {
  const params = new FormData() // eslint-disable-line no-undef

  if (typeof payload !== 'object') return null

  const keys = Object.keys(payload)
  const length = keys.length

  for (let i = 0; i < length; i += 1) {
    let value = payload[keys[i]]
    const key = keys[i]

    value = Array.isArray(value) ? value : String(value)

    if (value !== null && value !== 'null' && value !== 'undefined' && value !== undefined && value.length) {
      if (Array.isArray(value)) {
        value.forEach((element, index) => {
          if (typeof element === 'object') {
            const elKeys = Object.keys(element)

            elKeys.forEach(elKey => {
              params.append(key + '[' + index + '][' + elKey + ']', element[elKey])
            })
          } else {
            params.append(key + '[]', element)
          }
        })
      } else {
        params.append(key, value)
      }
    }
  }

  return params
}

export const parseToFormDataWithArrayExtraFields = payload => {
  const params = new FormData()

  const handlePayload = (payload, prefix = '') => {
    Object.entries(payload).forEach(([
      key,
      value
    ]) => {
      const formKey = prefix ? `${prefix}[${key}]` : key

      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          if (typeof item === 'object' && item !== null) {
            handlePayload(item, `${formKey}[${index}]`)
          } else {
            params.append(`${formKey}[${index}]`, item)
          }
        })
      } else {
        params.append(formKey, value)
      }
    })
  }

  handlePayload(payload)

  return params
}
