import { http } from '@/support/http'
import { createQuery } from '../../../support/utils/paginatorQueryBuilder'
import { parseToFormData } from '@/support/payloadParser'

export const getUsersList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/users?${queryString}`)
}

export const getUser = (userId) => {
  return http.get(`api/user/${userId}`)
}

export const getAddressByCep = (cep) => {
  return http.get('showcase/cep/' + cep + '/address')
}

export const updateUser = (userPayload) => {
  return http.put('api/user', userPayload)
}

export const sendValidationEmail = (userId) => {
  return http.post(`/api/user/${userId}/resend`)
}

export const listBusinessSectors = () => {
  return http.get('showcase/business_sectors')
}

export const listBusinessSizes = () => {
  return http.get('showcase/business_sizes')
}

export const listCnaes = (description) => {
  return http.get(`showcase/cnaes?query[description]=${description}&filter=&order=&page=1&limit=`)
}

export const listEducationLevels = () => {
  return http.get('showcase/education_levels')
}

export const listStates = () => {
  return http.get('showcase/states')
}

export const listCities = (stateId) => {
  return http.get('showcase/state/' + stateId + '/cities')
}

export const getCompanyDataByCnpj = (cnpj) => {
  return http.get('/showcase/cnpj/' + cnpj + '/company')
}

export const createAdmin = (adminPayload) => {
  return http.post('api/user/admin', adminPayload)
}

export const updateAdmin = (adminPayload) => {
  return http.put('api/user/admin/' + adminPayload.id, adminPayload)
}

export const createUserApi = (userApiPayload) => {
  return http.post('api/user/external-api', userApiPayload)
}

export const updateUserApi = (userApiPayload) => {
  return http.put('api/user/external-api/' + userApiPayload.id, userApiPayload)
}

export const removeUser = (userId) => {
  return http.delete('api/user/' + userId)
}

export const getUserSessions = (userId, pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/enrollments/${userId}?${queryString}`)
}

export const getUserLogins = (userId, pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/user/${userId}/logins?${queryString}`)
}

export const getUserEmailLogs = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/notifications/logs?${queryString}`)
}

export const getUserLearningPath = (userId, pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/learning_paths/${userId}?${queryString}`)
}

export const getSessionsBySolutionSlug = (slug, userId) => {
  return http.get(`api/course/${slug}/sessions/${userId}`)
}

export const enrollUserInSession = (sessionId, userId) => {
  return http.post(`api/session/${sessionId}/enroll/${userId}`)
}

export const cancelEnrollUserInSession = (data) => {
  const form = parseToFormData(data)

  form.append('cancellation_reason_id', data.id)
  form.append('other_reason', data.description)

  return http.post(`api/enrollment/${data.sessionUserId}/cancel`, form)
}

export const createUser = (userData) => {
  return http.post('api/users', userData)
}

export const createUserBatch = (data) => {
  const form = parseToFormData(data)

  if (data.file) {
    form.append('file', data.file[0], data.file[0].name)
  }

  return http.post('api/batch/user/register', form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const createEnrollmentBatch = (data) => {
  const form = parseToFormData(data)

  if (data.file) {
    form.append('file', data.file[0], data.file[0].name)
    form.append('solutionId', data.solutionId)
  }

  return http.post('api/batch/enrollment/register', form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const getUserByCpf = (cpf) => {
  return http.get(`api/cpf/${cpf}/user`)
}

export const impersonateUser = (userId) => {
  return http.post('api/impersonate', { user_id: userId })
}

export const getBatchList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/batch?${queryString}`)
}

export const getBatchDetailsList = (batchId) => {
  return http.get(`api/batch/${batchId}/details`)
}
