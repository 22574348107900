<script>
import { minLength, requiredIf } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

export default {
  name: 'TracksConfig',
  components: {
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Tabs: () => import('@/components/general/Tabs'),
    Datatable: () => import('@/components/general/Datatable'),
    Action: () => import('@/components/general/Action'),
    FilterListSearch: () => import('@/components/general/FilterListSearch'),
    FormSection: () => import('@/components/general/FormSection'),
    Divisor: () => import('@/components/general/Divisor'),
    Icon: () => import('@/components/general/Icon'),
    Radio: () => import('@/components/general/Radio')
  },

  data () {
    return {
      formData: {
        listHighlights: [],
        ordenation: 'default'
      },

      tabLinks: [
        {
          location: { name: 'tracks.index' },
          text: this.$t('global:menu.tracks')
        },
        {
          location: { name: 'tracks.configs' },
          text: this.$t('solutions.tabs:link.2')
        }
      ],

      orderOptions: [
        {
          label: this.$t('global:manual'),
          value: 'manual'
        },
        {
          label: this.$t('global:default'),
          value: 'default'
        }
      ],

      pathsPagination: {
        query: {
          name: null,
          limit: 300
        },

        filter: {
          active: 1,
          show_on_portal: 1
        },

        order: {
          name: 'ASC'
        },

        lastPage: null,
        limit: 6,
        page: 1
      },

      isLoadingMorePaths: false,
      paths: []
    }
  },

  validations: {
    formData: {
      listHighlights: {
        required: requiredIf(function () {
          return this.formData.ordenation === 'manual'
        }),

        minLength: minLength(4)
      }
    }
  },

  computed: {
    availablePaths () {
      return this.paths.filter(path => !this.formData.listHighlights.find(findPath => path.id === findPath.id))
    }
  },

  created () {
    this.setFetching(true)

    Promise.all([
      this.fetchTracksHighlights(),
      this.fetchPathsData()
    ]).finally(() => {
      this.setFetching(false)
    })
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetLearningPathsList',
      'attemptGetListTracksHighlights',
      'attemptUpdateTracksHighlights',
      'attemptGetGeneralSettings'
    ]),

    fetchPathsData (isLoadMore = false) {
      this.isLoadingMorePaths = true

      return this.attemptGetLearningPathsList(this.pathsPagination)
        .then((pagination) => {
          this.pathsPagination.lastPage = pagination.lastPage
          this.pathsPagination.page = pagination.actualPage

          if (pagination.data) {
            if (!isLoadMore) {
              this.paths = []
            }

            pagination.data.forEach(element => {
              this.paths.push({
                name: element.name,
                id: element.id,
                learningPathSolutions: element.learningPathSolutions,
                workload: element.workload
              })
            })
          }

          this.isLoadingMorePaths = false

          return pagination
        })
    },

    fetchTracksHighlights () {
      this.setFetching(true)

      this.attemptGetGeneralSettings().then(({ data }) => {
        const ordenation = data.data.find((item) => item.field === 'learning_path_highlight_ordenation')

        this.formData.ordenation = ordenation.value ? ordenation.value : 'default'

        this.attemptGetListTracksHighlights().then(({ data }) => {
          this.formData.listHighlights = data.data.map((highlight) => {
            return {
              active: 1,
              ...highlight.learningPath
            }
          })

          this.setFetching(false)
        })
      })
    },

    searchPaths (search) {
      this.pathsPagination.query.name = search
      this.pathsPagination.page = 1
      this.pathsPagination.lastPage = null
      this.pathsPagination.query.only_name = true
      this.fetchPathsData()
    },

    loadMorePaths () {
      this.isLoadingMorePaths = true

      if (this.pathsPagination.lastPage) {
        if (this.pathsPagination.lastPage > this.pathsPagination.page) {
          this.pathsPagination.page += 1
        } else {
          this.isLoadingMorePaths = false

          return
        }
      }

      this.fetchPathsData(true)
    },

    addToLinkedSolution (pathId) {
      const path = this.paths.find(path => path.id === pathId)

      path.active = 1
      this.formData.listHighlights.push(path)
    },

    removeLinkedSolution (pathId) {
      this.formData.listHighlights = this.formData.listHighlights.filter(path => path.id !== pathId)
    },

    moveUpLinkedSolution (pathId) {
      const index = this.formData.listHighlights.findIndex(path => path.id === pathId)

      if (index > 0) {
        const swapSolution = this.formData.listHighlights[index - 1]

        this.$set(this.formData.listHighlights, (index - 1), this.formData.listHighlights[index])
        this.$set(this.formData.listHighlights, index, swapSolution)
      }
    },

    moveDownLinkedSolution (pathId) {
      const index = this.formData.listHighlights.findIndex(path => path.id === pathId)

      if (index < (this.formData.listHighlights.length - 1)) {
        const swapSolution = this.formData.listHighlights[index + 1]

        this.$set(this.formData.listHighlights, (index + 1), this.formData.listHighlights[index])
        this.$set(this.formData.listHighlights, index, swapSolution)
      }
    },

    updateHighlights () {
      this.$v.$touch()

      const formatHighlights = {
        learningPaths: [],
        ordenation: this.formData.ordenation
      }

      if (this.formData.ordenation === 'manual') {
        formatHighlights.learningPaths = this.formData.listHighlights.map((learningPaths, index) => {
          return {
            learningPathId: learningPaths.id,
            highlightPosition: index
          }
        })
      }

      if (!this.$v.$invalid) {
        this.attemptUpdateTracksHighlights(formatHighlights).then(() => {
          this.setFeedback({ message: this.$t('solutions.config.highlights:feedback.success') })
        }).then(() => {
          this.fetchTracksHighlights()
        }).catch(({ data }) => {
          if (data.error.message === 'too_many_learning_paths') {
            this.setFeedback({ message: this.$t('paths.config.highlights:max.length.message') })

            return
          }

          this.setFeedback({ message: this.$t('solutions.config.highlights:feedback.error') })
        })
      } else {
        this.setFeedback({ message: this.$t('paths.config.highlights:min.length.message') })
      }
    }
  }
}
</script>

<template>
  <div class="main-content">
    <ContentHeader
      :title="$t('tracks:header.title')"
      :description="$t('tracks:header.description')"
      background="/assets/images/themes/default/png/tracks_banner.png"
    >
      <Tabs
        slot="tabs"
        dark
        :links="tabLinks"
      />
    </ContentHeader>
    <div class="center solutions-config">
      <div
        class="solutions-config_highlights"
      >
        <FormSection title="Ordenação">
          <Radio
            v-model="formData.ordenation"
            :items="orderOptions"
            horizontal
          />
        </FormSection>
        <div
          v-if="formData.ordenation === 'manual'"
          class="link-table"
        >
          <div class="linkable-items">
            <Datatable
              :is-loading-more="isLoadingMorePaths"
              :items="availablePaths"
              hide-horizontal-line
              has-infinite-scroll
              has-fade
              empty-message="no_result"
              @loadMore="loadMorePaths"
            >
              <template slot="thead">
                <th
                  class="th"
                  width="300"
                >
                  {{ $t('paths.list:datatable.header.1') }}
                </th>
                <th
                  class="th"
                  width="40"
                />
              </template>

              <template slot="tsearch">
                <FilterListSearch
                  slot="search"
                  class="filter-search"
                  :prepend-label="$t('global:form.track-search')"
                  :hide-error-details="true"
                  @search="searchPaths"
                />
              </template>

              <template
                slot="tbody"
                slot-scope="props"
              >
                <tr
                  :key="props.index"
                  @click="addToLinkedSolution(props.item.id)"
                >
                  <td class="td td-full">
                    <span class="td-text bolder">{{ props.item.name }}</span>
                    <br>
                    <span class="td-text td-small">
                      {{ props.item.learningPathSolutions.length }} {{ props.item.learningPathSolutions.length === 1
                        ? 'Solução'
                        : 'Soluções' }} | {{ props.item.workload ? props.item.workload : '0h' }}
                    </span>
                  </td>
                  <td class="td td-button td-small text-right">
                    <Action
                      type="button"
                      icon="keyboard_arrow_right"
                      flat
                    />
                  </td>
                </tr>
              </template>
            </Datatable>
          </div>

          <Divisor orientation="vertical" />

          <div class="linked-items">
            <Datatable
              has-fade
              drag
              :items="formData.listHighlights"
              hide-horizontal-line
            >
              <template slot="thead">
                <th class="th">
                  {{ $t('paths.config.highlights:datatable.header.2') }}
                </th>
                <th
                  class="th"
                  width="40"
                />
              </template>
              <template
                slot="tbody"
                slot-scope="props"
              >
                <tr
                  v-if="props.item.active"
                  :key="props.index"
                  class="tr-draggable"
                >
                  <td class="td td-drag">
                    <Icon
                      name="drag"
                      size="is-small"
                    />
                  </td>
                  <td class="td td-full">
                    <span class="td-text bolder">{{ props.item.name }}</span>
                  </td>
                  <td class="td td-button td-small text-right">
                    <Action
                      class="order-action"
                      type="button"
                      icon="arrow_drop_down"
                      flat
                      @click="moveDownLinkedSolution(props.item.id)"
                    />
                    <Action
                      class="order-action"
                      type="button"
                      icon="arrow_drop_up"
                      flat
                      @click="moveUpLinkedSolution(props.item.id)"
                    />
                    <Action
                      class="order-action"
                      type="button"
                      icon="close"
                      flat
                      @click="removeLinkedSolution(props.item.id)"
                    />
                  </td>
                </tr>
              </template>
            </Datatable>
          </div>
        </div>
        <Action
          slot="button"
          primary
          fixed-width
          type="button"
          :text="$t('global:save')"
          class="btn-save"
          @click="updateHighlights"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">

.solutions-config {
  padding: 10px;
  display: flex;
  flex-direction: column;

  .datatable-wrapper {
    padding-top: 35px;
  }

  table .td {
    border: none;
  }

  .btn-save{
    align-self: center;
    margin-top: 15px;
  }

  .solutions-config_highlights {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;

    .link-table {
      width: 100%;
    }

    .form-section {
      .form-section-title {
        text-align: center;
      }
    }
  }

}

</style>
