export default {
  data () {
    return {
      tabLinks: [
        {
          location: { name: 'management.index' },
          text: this.$t('management.tabs:link.1')
        },
        {
          location: { name: 'management.user.profiles' },
          text: this.$t('management.tabs:link.2')
        },
        {
          location: { name: 'management.survey' },
          text: this.$t('management.tabs:link.4')
        },
        {
          location: { name: 'management.certificates' },
          text: this.$t('management.tabs:link.5')
        },
        {
          location: {
            name: 'management.banners',
            params: { activeTab: 'tools' }
          },
          text: this.$t('management.tabs:link.6')
        },
        {
          location: { name: 'management.gamification' },
          text: this.$t('management.tabs:link.7')
        },
        {
          location: { name: 'management.settings' },
          text: this.$t('management.tabs:link.settings')
        }
      ]
    }
  }
}
