const EmailModule = () => import('./EmailModule.vue')
const ListPage = () => import('./pages/ListPage.vue')
const ManagePage = () => import('./pages/ManagePage.vue')
const ViewerPage = () => import('./pages/ViewerPage.vue')

export const routes = [
  {
    path: '/notifications/emails',
    component: EmailModule,
    children: [
      {
        path: '/',
        name: 'notifications.emails',
        component: ListPage,
        meta: {
          hideNavbar: false,
          requiresAuth: true
        }
      },

      {
        path: '/manage/:id?',
        name: 'notifications.emails.manage',
        component: ManagePage,
        meta: {
          hideNavbar: false,
          requiresAuth: true
        }
      },

      {
        path: '/viewer/:id?',
        name: 'notifications.emails.viewer',
        component: ViewerPage,
        meta: {
          hideNavbar: false,
          requiresAuth: true
        }
      }
    ]
  }
]
