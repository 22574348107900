import * as services from '../services'
import * as TYPES from './mutations-types'

export const attemptGetToolsList = ({ commit }, pagination) => {
  return services.getToolsList(pagination)
    .then(toolsList => {
      commit(TYPES.SET_TOOLS_LIST, toolsList.data.data)

      return {
        data: toolsList.data,
        actualPage: toolsList.actualPage,
        firstPage: toolsList.firstPage,
        lastPage: toolsList.lastPage,
        nextPage: toolsList.nextPage,
        previousPage: toolsList.previousPage,
        total: toolsList.total
      }
    })
}

export const attemptManageTool = ({ commit }, id) => {
  return services.getTool(id)
    .then(response => {
      commit(TYPES.SET_MANAGE_TOOL, response.data || null)

      return response.data
    })
}

export const attemptSaveTool = (_, tool) => {
  return services.saveTool(tool)
    .then(response => {
      return response
    })
}

export const attemptUpdateTool = (_, tool) => {
  return services.updateTool(tool)
    .then(response => {
      return response
    })
}

export const attemptToggleToolActive = (_, tool) => {
  return services.toggleToolActive(tool)
    .then(response => {
      return response
    })
}

export const attemptGetTypeToolsList = ({ commit }, pagination) => {
  return services.getTypeToolsList(pagination)
    .then(typeToolsList => {
      commit(TYPES.SET_TYPE_TOOLS_LIST, typeToolsList.data.data)

      return {
        data: typeToolsList.data,
        actualPage: typeToolsList.actualPage,
        firstPage: typeToolsList.firstPage,
        lastPage: typeToolsList.lastPage,
        nextPage: typeToolsList.nextPage,
        previousPage: typeToolsList.previousPage,
        total: typeToolsList.total
      }
    })
}

export const attemptManageTypeTool = ({ commit }, id) => {
  return services.getTypeTool(id)
    .then(response => {
      commit(TYPES.SET_MANAGE_TYPE_TOOL, response.data || null)

      return response.data
    })
}

export const attemptSaveTypeTool = (_, tool) => {
  return services.saveTypeTool(tool)
    .then(response => {
      return response
    })
}

export const attemptUpdateTypeTool = (_, tool) => {
  return services.updateTypeTool(tool)
    .then(response => {
      return response
    })
}

export const attemptToggleTypeToolActive = (_, tool) => {
  return services.toggleTypeToolActive(tool)
    .then(response => {
      return response
    })
}
