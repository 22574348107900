const UsersList = () => import('./components/UsersList')
const UsersManage = () => import('./components/UsersManage')
const UsersBatchManage = () => import('./components/UsersBatchManage')
const EnrollmentBatchManage = () => import('./components/EnrollmentBatchManage')
const AdminManage = () => import('./components/AdminManage')
const ApiManage = () => import('./components/ApiManage')
const UserEnrollment = () => import('./components/UserEnrollment')
const BatchList = () => import('./components/BatchList')
const BatchDetails = () => import('./components/BatchDetails')

export default [
  {
    name: 'users.index',
    path: '/users',
    component: UsersList,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'users',
        access: 'read'
      }
    },
    children: [
      {
        name: 'users.manage',
        path: 'manage/user/:id?',
        component: UsersManage,
        meta: {
          hideNavbar: false,
          requiresAuth: true,
          permission: {
            context: 'users',
            access: 'write'
          }
        }
      },
      {
        name: 'users.batch.manage',
        path: 'manage/batch',
        component: UsersBatchManage,
        meta: {
          hideNavbar: false,
          requiresAuth: true,
          permission: {
            context: 'users',
            access: 'write'
          }
        }
      },
      {
        name: 'enrollment.batch.manage',
        path: 'manage/batch/enrollment',
        component: EnrollmentBatchManage,
        meta: {
          hideNavbar: false,
          requiresAuth: true,
          permission: {
            context: 'enrollment',
            access: 'write'
          }
        }
      },
      {
        name: 'batch.list',
        path: 'manage/batch/list',
        component: BatchList,
        meta: {
          hideNavbar: false,
          requiresAuth: true,
          permission: {
            context: 'enrollment',
            access: 'write'
          }
        }
      },
      {
        name: 'batch.details',
        path: 'manage/batch/:id?',
        component: BatchDetails,
        meta: {
          hideNavbar: false,
          requiresAuth: true,
          permission: {
            context: 'enrollment',
            access: 'write'
          }
        }
      },
      {
        name: 'users.admin.manage',
        path: 'manage/admin/:id?',
        component: AdminManage,
        meta: {
          hideNavbar: false,
          requiresAuth: true,
          permission: {
            context: 'users',
            access: 'write'
          }
        }
      },
      {
        name: 'users.api.manage',
        path: 'manage/api/:id?',
        component: ApiManage,
        meta: {
          hideNavbar: false,
          requiresAuth: true,
          permission: {
            context: 'users',
            access: 'write'
          }
        }
      },
      {
        name: 'users.enrollment',
        path: 'manage/enrollment/:id',
        component: UserEnrollment,
        meta: {
          hideNavbar: false,
          requiresAuth: true,
          permission: {
            context: 'enrollment',
            access: 'read'
          }
        }
      }
    ]
  }
]
