<script>
export default {
  name: 'DashboardCard',
  components: {
    Action: () => import('@/components/general/Action')
  },
  props: {
    title: {
      type: String
    },
    dark: {
      type: Boolean,
      default: false
    },
    exportable: {
      type: Object,
      default: () => {}
    },
    size: {
      type: String,
      default: 'medium'
    },
    bottomText: {
      type: String,
      default: null
    }
  },
  methods: {
    hasControllerSlot () {
      return !!this.$slots.controller
    },
    getSizeClass () {
      return this.size === 'full' ? 'is-full' : this.size === 'large' ? 'is-large' : this.size === 'medium' ? 'is-medium' : this.size === 'small' ? 'is-small' : ''
    },
    getUrl () {
      if (this.exportable) {
        return `data:${this.exportable.mimetype};base64,${this.exportable.encoding}`
      }
    },
    emitBottomAction () {
      this.$emit('bottomAction')
    }
  }
}
</script>
<template>
  <div
    class="card-dashboard"
    :class="[getSizeClass(), {'has-controller': hasControllerSlot(), 'card-dark': dark}]"
  >
    <h2
      v-if="title"
      class="card-title"
    >
      {{ title }}
    </h2>
    <!-- <action type="link" :filename="exportable.filename" :download="exportable.download" :url="this.getUrl()" :text="$t('dashboard.card:export.list')" class="btn-export" flat v-if="exportable"></action> -->
    <slot name="controller" />
    <div class="container">
      <slot />
      <slot name="main" />
      <action
        type="button"
        flat
        :text="bottomText"
        class="card-dashboard-bottom-text"
        @click="emitBottomAction"
      />
    </div>
  </div>
</template>
<style src="@/assets/styles/themes/default/dashboardcard.css"></style>
