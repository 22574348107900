<script>
import { app } from '@/main'
import { mapActions, mapGetters } from 'vuex'
import { VMoney } from 'v-money'
import { getStatusClass, getStatusText } from '@/support/utils/statusTagHelper'

const TRANSLATION = {
  header: {
    title: app.$t('finances:header.title'),
    description: app.$t('finances:header.description'),
    configuration: app.$t('finances:header.configuration'),
    configurationTooltip: app.$t('finances:header.configuration.tooltip')
  },

  thead: {
    ID: app.$t('finances:thead.ID'),
    user: app.$t('finances:thead.user'),
    payment: app.$t('finances:thead.payment'),
    value: app.$t('finances:thead.value'),
    status: app.$t('finances:thead.status'),
    rmSynced: app.$t('finances:thead.rm.synced'),
    createdAt: app.$t('finances:thead.createdAt'),
    lastUpdate: app.$t('finances:thead.lastUpdate')
  },

  verticalDots: {
    visualize: app.$t('global:visualize'),
    emit: app.$t('global:emit.receipt'),
    secondCopyBankSlip: app.$t('global.second.copy.bank.slip'),
    synced: app.$t('global:rm.synced')
  },

  list: {
    credit_card: app.$t('finances:list.credit_card'),
    boleto: app.$t('finances:list.boleto')
  },

  receipt: {
    credit_card: app.$t('finances:receipt.credit_card'),
    boleto: app.$t('finances:receipt.boleto'),
    final: app.$t('finances:receipt.final'),
    header: app.$t('finances:receipt.header'),
    installmentPayment: app.$t('finances:receipt.installmentPayment'),
    orderDetails: app.$t('finances:receipt.orderDetails'),
    orderNumber: app.$t('finances:receipt.orderNumber'),
    paymentMethod: app.$t('finances:receipt.paymentMethod'),
    totalPaid: app.$t('finances:receipt.totalPaid'),
    value: app.$t('finances:receipt.value'),
    paymentDate: app.$t('finances:receipt.paymentDate')
  }
}

const BACKGROUND = '/assets/images/themes/default/png/management_banner.png'

export default {
  name: 'ListPage',

  components: {
    Action: () => import('@/components/general/Action'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Datatable: () => import('@/components/general/Datatable'),
    Dropdown: () => import('@/components/general/Dropdown'),
    DropdownLink: () => import('@/components/general/DropdownLink'),
    FilterList: () => import('@/components/general/FilterList'),
    FilterListSearch: () => import('@/components/general/FilterListSearch'),
    Pagination: () => import('@/components/general/Pagination'),
    VueHtml2pdf: () => import('vue-html2pdf')
  },

  directives: { money: VMoney },

  data () {
    return {
      financeList: [],
      financeListPagination: {
        query: {},
        filter: {},

        order: {
          created_at: 'desc'
        },

        limit: 12,
        page: 1,
        lastPage: null
      },

      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false, // doesn't work with directive
        allowBlank: true
      },

      pdfFile: {
        title: 'Recibo ',
        item: {}
      },

      receipt: null
    }
  },

  computed: {
    ...mapGetters('FinanceModule', [ 'getFinanceList' ]),

    // financeListData () {
    //   return this.getFinanceList
    // },

    hasFinanceListData () {
      return this.financeList?.length > 0
      // return this.getFinanceList?.length > 0
    },

    canWrite () {
      // TODO Back: Revert this change
      return true
    }
  },

  watch: {
    'financeListPagination.page' () {
      this.fetchFinancesList()
    }
  },

  created () {
    this.setup()
    this.fetchFinancesList()
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'setPagination'
    ]),

    ...mapActions('FinanceModule', [
      'attemptGetFinanceList',
      'attemptSyncOrder',
      'attemptGenerateReceipt'
    ]),

    setup () {
      this.TRANSLATION = TRANSLATION
      this.BACKGROUND = BACKGROUND
    },

    getStatusTagClass (status) {
      return getStatusClass(status)
    },

    getSyncedTagClass (status) {
      return getStatusClass(status ? 'synced' : 'not-synced')
    },

    getStatusTagText (status) {
      return getStatusText(status)
    },

    searchFinance (search) {
      this.financeListPagination.query = {
        name: search
      }

      const inFirstPage = this.financeListPagination.page === 1

      if (inFirstPage) {
        this.fetchFinancesList()

        return
      }

      this.financeListPagination.page = 1
    },

    view (id) {
      this.$router.push({
        name: 'finance.view',
        params: {
          id: id
        }
      })
    },

    fetchFinancesList () {
      this.setFetching(true)

      this.attemptGetFinanceList(this.financeListPagination)
        .then(response => {
          this.financeListPagination.lastPage = response.lastPage
          this.financeList = response.data
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    firstPage () {
      this.financeListPagination.page = 1
    },

    lastPage () {
      this.financeListPagination.page = this.financeListPagination.lastPage
    },

    prevPage () {
      this.financeListPagination.page--
    },

    nextPage () {
      this.financeListPagination.page++
    },

    emitReceipt (item) {
      this.pdfFile.item = { ...item }
      this.setFetching(true)

      this.attemptGenerateReceipt()
        .then(response => {
          this.receipt = response
        })
        .catch(() => {
          this.setFeedback({ message: 'Ocorreu um erro ao recuperar o recibo.' })
        })
        .finally(() => {
          setTimeout(() => this.$refs[this.receipt].generatePdf(), 3000)
        })

      /* this.pdfFile.item = { ...item }
      this.setFetching(true)
      setTimeout(() => this.$refs.html2Pdf.generatePdf(), 3000) */
    },

    onProgress ($event) {
      if ($event === 100) {
        this.setFetching(false)
      } else {
        this.setFetching(true)
      }
    },

    hasDownloaded () {
      this.setFetching(false)
    },

    getSecondCopyBankSlip (item) {
      window.open(process.env.VUE_APP_GETNET_API_URL + item.data[0].boleto._links[1].href, '_blank')
    },

    SyncRm (orderId) {
      this.setFetching(true)

      this.attemptSyncOrder(orderId).then(() => {
        this.setFeedback({ message: this.$t('financial:feedback.integration.success') })
      }).then(() => {
        this.fetchFinancesList()
      }).catch((e) => {
        this.setFeedback({ message: e.data.error.message })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    goToFinancialConfig () {
      this.$router.push({
        name: 'management.settings',
        params: {
          tab: 'finance'
        }
      })
    },

    getItemPrice (item) {
      return item.price
    }
  }
}
</script>

<template>
  <div class="list-page main-content">
    <ContentHeader
      :title="TRANSLATION.header.title"
      :description="TRANSLATION.header.description"
      :background="BACKGROUND"
    />

    <FilterList>
      <Action
        slot="button"
        primary
        tiny
        icon-size="small"
        icon="settings"
        type="button"
        :text="TRANSLATION.header.configuration"
        :title="TRANSLATION.header.configurationTooltip"
        @click="goToFinancialConfig"
      />
      <FilterListSearch
        slot="search"
        :hide-error-details="true"
        @search="searchFinance"
      />
    </FilterList>

    <div class="center finance-content">
      <div
        v-if="hasFinanceListData"
        class="center"
      >
        <Datatable :items="financeList">
          <template slot="thead">
            <tr>
              <th
                class="th"
                width="130"
              >
                {{ TRANSLATION.thead.ID }}
              </th>
              <th
                class="th"
                width="300"
              >
                {{ TRANSLATION.thead.user }}
              </th>
              <th
                class="th"
                width="130"
              >
                {{ TRANSLATION.thead.payment }}
              </th>
              <th
                class="th text-center"
                width="80"
              >
                {{ TRANSLATION.thead.status }}
              </th>
              <th
                class="th text-center"
                width="200"
              >
                {{ TRANSLATION.thead.rmSynced }}
              </th>
              <th
                class="th text-center"
                width="200"
              >
                {{ TRANSLATION.thead.createdAt }}
              </th>
              <th
                class="th text-center"
                width="40"
              />
            </tr>
          </template>

          <template
            slot="tbody"
            slot-scope="props"
          >
            <tr>
              <td class="td">
                <b class="td-text">{{ formatTransactionId(props.item.id) }}</b>
              </td>

              <td class="td">
                <b class="td-text">{{ props.item.user.name }}</b>
                <br>
                <i class="td-text">{{ props.item.user.cpf }}</i>
              </td>

              <td class="td">
                <b class="money-value">{{ formatCurrency(props.item.value) }}</b>
                <br>
                <i class="td-text">{{ TRANSLATION.list[props.item.paymentMethod] }}</i>
              </td>

              <td class="td text-center">
                <span
                  class="td-tag"
                  :class="getStatusTagClass(props.item.status)"
                >
                  {{ getStatusTagText(props.item.status) }}
                </span>
              </td>

              <td class="td text-center">
                <span
                  class="td-tag"
                  :class="getSyncedTagClass(props.item.synced)"
                >
                  {{ props.item.synced ? $t('global.synced') : $t('global.not.synced') }}
                </span>
              </td>

              <td class="td text-center">
                <span class="td-text">{{ formatDateTime(props.item.createdAt) }}</span>
              </td>

              <td
                class="td td-actions"
                width="40"
              >
                <Dropdown
                  icon="dots-vertical"
                  right
                >
                  <DropdownLink
                    v-if="canWrite"
                    :text="TRANSLATION.verticalDots.visualize"
                    @click="view(props.item.id)"
                  />

                  <DropdownLink
                    v-if="canWrite && (props.item.status === 'finished' || props.item.status === 'paid')"
                    :text="TRANSLATION.verticalDots.emit"
                    @click="emitReceipt(props.item)"
                  />

                  <DropdownLink
                    v-if="canWrite && (props.item.status === 'awaiting_payment'
                      && props.item.paymentMethod === 'boleto')"
                    :text="TRANSLATION.verticalDots.secondCopyBankSlip"
                    @click="getSecondCopyBankSlip(props.item)"
                  />

                  <DropdownLink
                    v-if="!props.item.synced && props.item.status === 'paid'"
                    :text="TRANSLATION.verticalDots.synced"
                    @click="SyncRm(props.item.id)"
                  />
                </Dropdown>
              </td>
            </tr>
          </template>
        </Datatable>

        <Pagination
          :active-page="financeListPagination.page"
          :page-count="financeListPagination.lastPage"
          @firstPage="firstPage()"
          @lastPage="lastPage()"
          @nextPage="nextPage()"
          @previousPage="prevPage()"
        />
      </div>
    </div>

    <VueHtml2pdf
      ref="padrao"
      pdf-format="a4"
      :pdf-quality="2"
      :show-layout="false"
      :float-layout="true"
      :preview-modal="true"
      :enable-download="true"
      pdf-content-width="100%"
      :manual-pagination="false"
      pdf-orientation="landscape"
      :paginate-elements-by-height="1600"
      :filename="`Recibo ${formatTransactionId(pdfFile.item.id)}`"
      @progress="onProgress($event)"
      @hasDownloaded="hasDownloaded($event)"
    >
      <section slot="pdf-content">
        <div class="receipt-header">
          <div class="receipt-padding">
            <h1>{{ TRANSLATION.receipt.header }}</h1>
          </div>
        </div>
        <div class="receipt-body">
          <div class="receipt-padding">
            <h2>{{ TRANSLATION.receipt.orderDetails }}</h2>
            <h3>
              <b>{{ TRANSLATION.receipt.orderNumber }}: {{ formatTransactionId(pdfFile.item.id) }}</b>
              <b
                v-if="pdfFile.item.paymentDate"
              >
                <span class="calendar-range-wrapper">
                  <svg
                    class="w-6 h-6 calendar-range"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                </span>{{ formatDateTime(pdfFile.item.paymentDate) }}
              </b>
            </h3>
            <ul class="receipt-items">
              <li
                v-for="(item, itemIndex) in pdfFile.item.products"
                :key="itemIndex"
                class="receipt-item"
              >
                <div class="item-title">
                  {{ item.solution ? item.solution.name : item.program ? item.program.name : '' }}
                </div>
                <div class="item-text">
                  {{ item.solution ? item.solution.solutionType.name : '' }}
                  <div
                    v-if="item.boughtFor"
                    class="item-text--bought-for"
                  >
                    <br>
                    Comprado para:
                    <ul>
                      <li
                        v-for="(user, userIndex) in item.boughtFor"
                        :key="userIndex"
                      >
                        {{ userIndex + 1 }} - {{ user.name }} | CPF: {{ user.cpf }}
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
            <h2>{{ TRANSLATION.receipt.paymentMethod }}</h2>
            <div class="receipt-payment">
              <div class="item">
                <div class="item-title">
                  {{ TRANSLATION.receipt[pdfFile.item.paymentMethod] }}
                </div>
                <div
                  v-if="pdfFile.item.paymentMethod === 'credit_card'"
                  class="item-text"
                >
                  {{ TRANSLATION.receipt.final }}
                  {{ pdfFile.item.products && pdfFile.item.products.length ? pdfFile.item.products[0].data.cardNumber : '' }}
                </div>
              </div>
              <div class="item">
                <div
                  v-if="pdfFile.item.paymentMethod === 'credit_card'"
                  class="item-title"
                >
                  {{ TRANSLATION.receipt.installmentPayment }}
                </div>
                <div
                  v-if="pdfFile.item.paymentMethod === 'credit_card'"
                  class="item-text"
                >
                  {{ pdfFile.item.products && pdfFile.item.products.length > 0 ? formatInstallments(pdfFile.item.products[0].data) : '' }}
                </div>
              </div>
              <div class="item">
                <div class="item-title">
                  {{ TRANSLATION.receipt.value }}
                </div>
                <div class="item-text item-text-blue">
                  {{ TRANSLATION.receipt.totalPaid }}: {{ formatCurrency(pdfFile.item.value) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </VueHtml2pdf>

    <VueHtml2pdf
      ref="alternativo"
      pdf-format="a4"
      :pdf-quality="2"
      :show-layout="false"
      :float-layout="true"
      :preview-modal="true"
      :enable-download="true"
      pdf-content-width="100%"
      :manual-pagination="false"
      pdf-orientation="portrait"
      :paginate-elements-by-height="1400"
      :filename="`Recibo ${formatTransactionId(pdfFile.item.id)}`"
      @progress="onProgress($event)"
      @hasDownloaded="hasDownloaded($event)"
    >
      <section slot="pdf-content">
        <section class="recibo recibo-b">
          <div class="recibo-wrapper">
            <div class="recibo-header">
              <div class="header-logo">
                <img
                  alt="Vitrine Sebrae"
                  src="/assets/images/themes/default/svg/logo-blue.svg"
                >
              </div>

              <div class="header-middle">
                <h1>RECIBO</h1>

                <h2>Nº {{ formatTransactionId(pdfFile.item.id) }} {{ formatDate(pdfFile.item.createdAt) }}</h2>

                <h2>Movimento: 2.1.14 Recibo de Venda de Serviços</h2>
              </div>

              <div class="header-right">
                <small>{{ formatDate(pdfFile.item.createdAt) }}</small>

                <small>{{ formatTime(pdfFile.item.createdAt) }}</small>

                <small>VITRINESP</small>
              </div>
            </div>

            <div class="recibo-body">
              <h3>1 - LOCAL DO PROCESSAMENTO</h3>

              <p>
                SERV. DE APOIO MIC. E PEQ. EMP. SP SEBRAE SP<br>
                001.0001 SEBRAE - SERVIÇO DE APOIO AS MICRO E PEQUENAS EMPRESAS DE SÃO PAULO - 43.728.245/0001-42<br>
                Endereço: Rua Vergueiro, 1117 - até 1289 - lado ímpar - Liberdade<br>
                Cep: 01504-001 - São Paulo - SP<br>
                Telefone: (11) 3177-4500
              </p>

              <h3>2 - LOCAL DE RECEITA</h3>

              <p>
                001.0001 SEBRAE - SERVIÇO DE APOIO AS MICRO E PEQ. EMPRESAS DE SÃO PAULO {{ pdfFile.item.chaveOrigemInt ? `- Identificador ${pdfFile.item.chaveOrigemInt}` : '' }}
              </p>

              <h3>3 - FONTE PAGADORA</h3>

              <p>
                Recebemos de: <b>{{ pdfFile.item.user ? pdfFile.item.user.name : '' }}</b><br>
                Inscrito(a) no CPF: <b>{{ pdfFile.item.user ? formatMask(pdfFile.item.user.username, 'CPF') : '' }}</b><br>
                O valor total de: <b>{{ formatCurrency(pdfFile.item.value) }}</b>
              </p>

              <h3>4 - CONDIÇÕES DE PAGAMENTO</h3>

              <p>
                Forma de Pagamento: <b>{{ pdfFile.item.paymentMethod === 'credit_card' ? 'Cartão de Crédito' : '' }}<br></b>
                Condição de Pagamento: <b>{{ pdfFile.item.paymentType === 'cash' ? 'À vista' : 'À prazo' }}<br></b>
                POS: <b>{{ formatDate(pdfFile.item.paymentDate) }}<br></b>
                Aut: <b>{{ pdfFile.item.data ? pdfFile.item.data[0].credit.authorization_code : '' }}<br></b>
                Valor: <b>{{ formatCurrency(pdfFile.item.value) }}<br></b>
                Quantidade de Parcelas: <b>{{ pdfFile.item.products ? pdfFile.item.products[0].data.installment : '' }}</b>
              </p>

              <h3>5 - ITENS</h3>

              <div class="recibo-table">
                <div class="table-header">
                  <div class="table-row">
                    <div class="table-cell table-head">
                      Código
                    </div>

                    <div class="table-cell table-head">
                      Produto
                    </div>

                    <div class="table-cell table-head">
                      Qtd.
                    </div>

                    <div class="table-cell table-head">
                      Valor Unitário
                    </div>

                    <div class="table-cell table-head">
                      Valor Total
                    </div>
                  </div>
                </div>

                <div class="table-body">
                  <div
                    v-for="(item, itemIndex) in pdfFile.item.products"
                    :key="itemIndex"
                    class="table-row"
                  >
                    <div class="table-cell">
                      #{{ item.id }}
                    </div>

                    <div class="table-cell">
                      {{ item.solution ? item.solution.name : item.program ? item.program.name : '' }}
                    </div>

                    <div class="table-cell">
                      {{ item.quantity }}
                    </div>

                    <div class="table-cell">
                      {{ formatCurrency(getItemPrice(item)) }}
                    </div>

                    <div class="table-cell">
                      {{ formatCurrency(item.quantity * getItemPrice(item)) }}
                    </div>
                  </div>
                </div>

                <div class="table-footer">
                  <div class="footer-left">
                    <i><b><small>Informações Adicionais:</small></b></i>
                    <i><b><small>* As condições comerciais estão previstas no Contrato de Adesão disponível no Escritório Regional ou no nosso site: www.sebraesp.com.br</small></b></i>
                    <i><b><small>* A quitação do presente recibo está condicionada a liquidação de todas as suas parcelas.</small></b></i>
                    <i><b><small>Dispensada a retenção de IRRF, COFINS, CSLL e PIS/PASEP, serviços prestados por entidade sem fins lucrativos, conforme determina o parágrafo 3º, item VI da Instrução Normativa nº 480/2004 de 15/12/2004, expedida pela Secretaria da Receita Federal</small></b></i>
                  </div>

                  <div class="footer-right">
                    <p>Exclusivo ao Depto. Financeiro</p>
                    <p>Data/Visto</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </VueHtml2pdf>
  </div>
</template>

<style lang="scss">
.list-page {
  .filter {
    .center {
      justify-content: flex-end;
      align-items: center;
      display: flex;
      gap: 24px;

      .btn-primary {
        font-size: 1em;
        padding: 0 10px;

        .icon {
          margin-right: 4px;
        }
      }

      .filter-options {
        margin: 0;
      }
    }
  }
}

.finance-content {
  .datatable .td {
    .money-value {
      font-weight: 400;
      margin: 8px 0 0;
    }

    i {
      margin-top: 4px;
    }
  }
}

.calendar-range-wrapper {
  vertical-align: middle;
}

.calendar-range {
  height: 20px;
  width: 20px;
  padding: 0;
  margin:0;
}
</style>

<style lang="scss" src="../assets/scss/Receipt.scss"></style>
