import { mixin } from '../../../mixins'
import * as TYPES from './mutations-types'

export default {
  [TYPES.SET_TOOLS_LIST] (state, loadedList) {
    state.toolsList = loadedList
  },
  [TYPES.SET_TYPE_TOOLS_LIST] (state, loadedList) {
    state.typeToolsList = loadedList
  },
  [TYPES.SET_MANAGE_TOOL] (state, tool) {
    state.manageTool = mixin.methods.deepClone(tool)
  },
  [TYPES.SET_MANAGE_TYPE_TOOL] (state, typeTool) {
    state.manageTypeTool = mixin.methods.deepClone(typeTool)
  }
}
