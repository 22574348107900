const SolutionsList = () => import('./components/SolutionsList')
const SolutionsManage = () => import('./components/SolutionsManage')
const SolutionsConfig = () => import('./components/SolutionsConfig')

export default [
  {
    name: 'solutions.index',
    path: '/solutions/list',
    component: SolutionsList,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'solution',
        access: 'read'
      }
    }
  },
  {
    name: 'solutions.configs',
    path: '/solutions/configs',
    component: SolutionsConfig,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'solution',
        access: 'write'
      }
    }
  },
  {
    name: 'solutions.manage',
    path: '/solutions/manage/:id?',
    component: SolutionsManage,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'solution',
        access: 'write'
      }
    }
  }
]
