import { solutionTypeFormatter } from '../../../support/utils/solutionTypeFormatter'
import * as services from '../services'
import * as TYPES from './mutations-types'

export const attemptGetSolutionsList = ({ commit }, pagination) => {
  return services.getSolutionsList(pagination)
    .then(solutionsList => {
      commit(TYPES.SET_SOLUTIONS_LIST, solutionsList.data.data)

      return {
        data: solutionsList.data.data,
        actualPage: solutionsList.data.actualPage,
        firstPage: solutionsList.data.firstPage,
        lastPage: solutionsList.data.lastPage,
        nextPage: solutionsList.data.nextPage,
        previousPage: solutionsList.data.previousPage,
        total: solutionsList.data.total
      }
    })
}

export const attemptGetSolutionsListOnly = (_, params) => {
  return services.getSolutionsListOnly(params)
    .then(solutionsList => {
      return {
        data: solutionsList.data.data
      }
    })
}

export const attemptGetSolutionsSearch = ({ commit }, pagination) => {
  return services.getSolutionsSearch(pagination)
    .then(solutionsList => {
      commit(TYPES.SET_SOLUTIONS_LIST, solutionsList.data.data)

      return {
        data: solutionsList.data.data,
        firstPage: solutionsList.data.firstPage,
        lastPage: solutionsList.data.lastPage,
        nextPage: solutionsList.data.nextPage,
        previousPage: solutionsList.data.previousPage,
        total: solutionsList.data.total
      }
    })
}

export const attemptGetCertificatesList = (_, pagination) => {
  return services.getCertificatesList(pagination)
    .then(certificatesList => {
      return {
        data: certificatesList.data.data,
        firstPage: certificatesList.data.firstPage,
        lastPage: certificatesList.data.lastPage,
        nextPage: certificatesList.data.nextPage,
        previousPage: certificatesList.data.previousPage,
        total: certificatesList.data.total
      }
    })
}

export const attemptGetSurveysList = (_, pagination) => {
  return services.getSurveysList(pagination)
    .then(surveysList => {
      return {
        data: surveysList.data.data,
        firstPage: surveysList.data.firstPage,
        lastPage: surveysList.data.lastPage,
        nextPage: surveysList.data.nextPage,
        previousPage: surveysList.data.previousPage,
        total: surveysList.data.total
      }
    })
}

export const attemptGetThemesList = (_, pagination) => {
  return services.getThemesList(pagination)
    .then(themesList => {
      return {
        data: themesList.data.data,
        firstPage: themesList.data.firstPage,
        lastPage: themesList.data.lastPage,
        nextPage: themesList.data.nextPage,
        previousPage: themesList.data.previousPage,
        total: themesList.data.total
      }
    })
}

export const attemptGetEntrepreneurProfilesList = (_, pagination) => {
  return services.getEntrepreneurProfilesList(pagination)
    .then(entrepreneurProfilesList => {
      return {
        data: entrepreneurProfilesList.data.data,
        firstPage: entrepreneurProfilesList.data.firstPage,
        lastPage: entrepreneurProfilesList.data.lastPage,
        nextPage: entrepreneurProfilesList.data.nextPage,
        previousPage: entrepreneurProfilesList.data.previousPage,
        total: entrepreneurProfilesList.data.total
      }
    })
}

export const attemptGetLearningPathsList = (_, pagination) => {
  return services.getLearningPathsList(pagination)
    .then(learningPathsList => {
      return {
        data: learningPathsList.data.data,
        firstPage: learningPathsList.data.firstPage,
        lastPage: learningPathsList.data.lastPage,
        nextPage: learningPathsList.data.nextPage,
        actualPage: learningPathsList.data.actualPage,
        previousPage: learningPathsList.data.previousPage,
        total: learningPathsList.data.total
      }
    })
}

export const attemptGetSolutionsTypesList = (_, pagination) => {
  return services.getSolutionsTypesList(pagination)
    .then(solutionsTypesList => {
      return {
        data: solutionsTypesList.data.data.map((solutionType) => {
          return {
            ...solutionType,
            name: solutionTypeFormatter(solutionType)
          }
        }),
        firstPage: solutionsTypesList.data.firstPage,
        lastPage: solutionsTypesList.data.lastPage,
        nextPage: solutionsTypesList.data.nextPage,
        previousPage: solutionsTypesList.data.previousPage,
        total: solutionsTypesList.data.total
      }
    })
}

export const attemptGetSasUnityList = (_, pagination) => {
  return services.getSasUnityList(pagination)
    .then(sasUnity => {
      return {
        data: sasUnity.data.data,
        firstPage: sasUnity.data.firstPage,
        lastPage: sasUnity.data.lastPage,
        nextPage: sasUnity.data.nextPage,
        previousPage: sasUnity.data.previousPage,
        total: sasUnity.data.total
      }
    })
}

export const attemptGetSasActionList = (_, pagination) => {
  return services.getSasActionList(pagination)
    .then(sasAction => {
      return {
        data: sasAction.data.data,
        firstPage: sasAction.data.firstPage,
        lastPage: sasAction.data.lastPage,
        nextPage: sasAction.data.nextPage,
        previousPage: sasAction.data.previousPage,
        total: sasAction.data.total
      }
    })
}

export const attemptGetSasProjectList = (_, pagination) => {
  return services.getSasProjectList(pagination)
    .then(sasProject => {
      return {
        data: sasProject.data.data,
        firstPage: sasProject.data.firstPage,
        lastPage: sasProject.data.lastPage,
        nextPage: sasProject.data.nextPage,
        previousPage: sasProject.data.previousPage,
        total: sasProject.data.total
      }
    })
}

export const attemptGetProviderList = (_, pagination) => {
  return services.getProviderList(pagination)
    .then(provider => {
      return {
        data: provider.data.data,
        firstPage: provider.data.firstPage,
        lastPage: provider.data.lastPage,
        nextPage: provider.data.nextPage,
        previousPage: provider.data.previousPage,
        total: provider.data.total
      }
    })
}

export const attemptGetRmProviderList = (_, pagination) => {
  return services.getRmProviderList(pagination)
    .then(provider => {
      return {
        data: provider.data.data,
        firstPage: provider.data.firstPage,
        lastPage: provider.data.lastPage,
        nextPage: provider.data.nextPage,
        previousPage: provider.data.previousPage,
        total: provider.data.total
      }
    })
}

export const attemptSaveSolution = (_, data) => {
  return services.saveSolution(data)
    .then(response => {
      return response
    })
}

export const attemptUpdateActiveSolution = ({ commit }, solution) => {
  return services.updateSolution(solution)
    .then(response => {
      commit(TYPES.UPDATE_ACTIVE_SOLUTION, response.data)

      return response
    })
}

export const attemptSaveActiveSolution = ({ commit }, solution) => {
  return services.saveSolution(solution)
    .then(response => {
      commit(TYPES.UPDATE_ACTIVE_SOLUTION, response.data)

      return response
    })
}

export const attemptUpdateSolution = (_, data) => {
  return services.updateSolution(data)
    .then(response => {
      return response
    })
}

export const attemptManageSolution = ({ commit }, solutionId) => {
  if (solutionId == null) {
    commit(TYPES.SET_MANAGE_SOLUTION, null)

    return
  }

  return services.getSolution(solutionId)
    .then(response => {
      commit(TYPES.SET_MANAGE_SOLUTION, response.data || null)

      return response.data
    })
}

export const attemptRemoveSolution = (_, data) => {
  return services.removeSolution(data)
    .then(response => {
      return response
    })
}

export const attemptToggleActiveSolution = (_, idSolution) => {
  return services.toggleActiveSolution(idSolution)
}

export const attemptGetCancellationList = () => {
  return services.getCancellationList()
    .then(canceledItems => {
      return canceledItems.data.map(item => ({
        text: item.name,
        value: item.id
      }))
    })
}

export const attemptGetSolutionAsset = (_, idAsset) => {
  return services.getSolutionAsset(idAsset)
    .then(data => {
      return data
    })
}

export const attemptGetVimeoDetails = (_, url) => {
  return services.getVimeoDetails(url)
}

export const setPagination = ({ commit }, filteredParams) => {
  commit(TYPES.SET_PAGINATION, filteredParams)
}

export const resetPagination = ({ commit }) => {
  commit(TYPES.RESET_PAGINATION)
}

export const attemptGetSolutionsForComboBox = () => {
  return services.getSolutionsForComboBox()
    .then(response => {
      return response
    })
}

export const attemptGetSolutionTypeIntegration = (_, solutionType) => {
  return services.getSolutionTypeIntegration(solutionType)
}

export const attemptGetSolutionsEndRules = () => {
  return services.getSolutionEndRules()
}

export const attemptUpdateSolutionsEndRules = (_, data) => {
  return services.updateSolutionEndRules(data)
}

export const attemptUpdateSolutionsHighlights = (_, data) => {
  return services.updateSolutionsHighlights(data)
}

export const attemptGetListSolutionsHighlights = (_, data) => {
  return services.getListSolutionsHighlights(data)
}

export const attemptValidatePortfolio = (_, idPortfolion) => {
  return services.validatePortfolio(idPortfolion)
    .then(response => {
      return response
    })
}
