<script>
export default {
  name: 'Dropdown',
  components: {
    Action: () => import('@/components/general/Action'),
    Icon: () => import('@/components/general/Icon')
  },
  props: {
    classes: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    iconSize: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    right: {
      type: Boolean,
      default: false
    },
    links: {
      type: Array,
      default () {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleMenu () {
      if (this.disabled === true) {
        return
      }
      this.isOpen = !this.isOpen
    },
    outside () {
      this.isOpen = false
    },
    isDisabledClass () {
      if (this.disabled === true) {
        return 'is-disabled'
      }
      return ''
    }
  }
}
</script>

<template>
  <div
    v-click-outside="outside"
    class="dropdown"
  >
    <a
      href="#"
      class="dropdown-link"
      :class="[classes, isDisabledClass()]"
      @click.prevent="toggleMenu"
    >
      <icon
        v-if="icon"
        :name="icon"
        wrapper
        :size="iconSize"
      />
      <span
        v-if="text"
        class="text"
      >{{ text }}</span>
    </a>
    <transition name="fadeInUp">
      <div
        v-if="isOpen"
        class="dropdown-content"
        :class="{ 'open-right': right }"
      >
        <div
          v-if="title"
          class="dropdown-content-title"
        >
          <icon
            v-if="icon"
            :name="icon"
            wrapper
            size="medium"
          />
          <span class="text">{{ title }}</span>
        </div>
        <div class="dropdown-content-list">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<style src="@/assets/styles/themes/default/dropdown.css"></style>
