<script>
import { mapActions } from 'vuex'
import { required, requiredIf } from 'vuelidate/lib/validators'
import moment from 'moment'
import reportsMixin from '@/modules/Reports/reportsMixin'

export default {
  name: 'ReportCommentsList',
  components: {
    Action: () => import('@/components/general/Action'),
    ActionBar: () => import('@/components/general/ActionBar'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Datepicker: () => import('@/components/general/Datepicker'),
    FormSection: () => import('@/components/general/FormSection'),
    SelectField: () => import('@/components/general/SelectField'),
    ModalConfirm: () => import('@/components/macro/ModalConfirm')
  },

  mixins: [ reportsMixin ],
  data () {
    return {
      formData: {
        dateInit: '',
        dateEnd: '',
        status: null,
        grade: [],
        solutions: [],
        programs: [],
        survey: null,
        reportId: null
      },

      filteredFormLabels: {
        status: [
          {
            text: this.$t('comments:status.approved'),
            value: 'approved'
          },
          {
            text: this.$t('comments:status.disapproved'),
            value: 'disapproved'
          },
          {
            text: this.$t('comments:status.pending'),
            value: 'pending'
          }
        ],

        grade: [
          {
            text: 1,
            value: 1
          },
          {
            text: 2,
            value: 2
          },
          {
            text: 3,
            value: 3
          },
          {
            text: 4,
            value: 4
          },
          {
            text: 5,
            value: 5
          }
        ]
      },

      foundSurveys: null,
      toolTypes: [
        {
          text: 'Solução',
          value: 'solution'
        },
        {
          text: 'Programa',
          value: 'program'
        }
      ],

      toolTypeSelected: null,
      allProgramsList: [],
      allSolutionsList: [],
      modalWarning: false
    }
  },

  computed: {

  },

  validations: {
    toolTypeSelected: {
      required
    },

    formData: {
      solutions: {
        required: requiredIf(function () {
          return this.toolTypeSelected === 'solution'
        })
      },

      programs: {
        required: requiredIf(function () {
          return this.toolTypeSelected === 'program'
        })
      },

      grade: {
        required
      },

      status: {
        required
      },

      dateInit: {
        required
      },

      dateEnd: {
        required
      }
    }
  },

  watch: {
    'toolTypeSelected' () {
      switch (this.toolTypeSelected) {
        case 'solution':
          this.formData.programs = []
          break
        case 'program':
          this.formData.solutions = []
          break
      }
    }
  },

  created () {
    this.setFetching(true)

    Promise.all([
      this.getSolutions({
        order: { name: 'ASC' },
        limit: 9999,
        page: 1,
        isDefaultSearch: true
      }),
      this.getPrograms()
    ]).finally(() => {
      this.setFetching(false)
    })
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetSolutionsListOnly',
      'attemptGetProgramsList',
      'attemptGetReportListComments',
      'attemptReportDownload',
      'attemptCheckReport'
    ]),

    getReportCommentsList () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const initDate = moment(this.formData.dateInit)
      const finalDate = moment(this.formData.dateEnd)

      if (finalDate.diff(initDate, 'days') > 31) {
        this.modalWarning = true
      } else {
        this.generateReport()
      }
    },

    generateReport () {
      this.modalWarning = false
      const params = {
        createdDateInit: this.formData.dateInit,
        createdDateEnd: this.formData.dateEnd,
        solutionIds: this.formData.solutions,
        programIds: this.formData.programs,
        grade: this.formData.grade,
        status: this.formData.status
      }

      this.setFetching(true)

      this.attemptGetReportListComments(params).then((response) => {
        this.reportId = response.data.id
        this.checkReport()
      }).catch(() => {
        this.setFetching(false)
        this.setFeedback({ message: this.$t('reports.error:failed.to.fetch') })
      })
    },

    getSolutions (pagination) {
      return new Promise((resolve) => {
        this.attemptGetSolutionsListOnly(pagination).then((paginationResponse) => {
          this.allSolutionsList = paginationResponse.data.map((element) => {
            return {
              text: element.name,
              value: element.id
            }
          })

          if (pagination.page < paginationResponse.lastPage) {
            pagination.page += 1
            resolve(this.getSolutions(pagination))
          } else {
            resolve()
          }
        })
      })
    },

    getPrograms () {
      const pagination = {
        order: { name: 'ASC' },
        limit: 9999,
        page: 1,
        isDefaultSearch: true
      }

      return new Promise((resolve) => {
        this.attemptGetProgramsList(pagination).then((paginationResponse) => {
          this.allProgramsList = paginationResponse.data.map((element) => {
            return {
              text: element.name,
              value: element.id
            }
          })

          if (pagination.page < paginationResponse.lastPage) {
            pagination.page += 1
            resolve(this.getPrograms(pagination))
          } else {
            resolve()
          }
        })
      })
    },

    closeModal () {
      this.modalWarning = false
    },

    leave () {
      this.$router.push({ name: 'reports.index' })
    },

    downloadReport () {
      this.attemptReportDownload(this.reportId).then((response) => {
        const url = window.URL.createObjectURL(new Blob([ response.data ]))
        const link = document.createElement('a')

        link.href = url

        link.setAttribute(
          'download',
          this.$t('reports.generate:report.comments.list.file.name').toString()
        )

        document.body.appendChild(link)
        link.click()
      }).catch(() => {
        this.setFeedback({ message: this.$t('reports.error:failed.to.fetch') })
      })
    },

    checkReport () {
      const interval = setInterval(() => {
        this.attemptCheckReport(this.reportId).then((response) => {
          if (response.data.status === 'generated') {
            this.setFetching(false)
            this.downloadReport()
            clearInterval(interval)
          } else if (response.data.status === 'failed') {
            this.setFetching(false)
            this.setFeedback({ message: this.$t('reports.error:failed.to.fetch') })
            clearInterval(interval)
          }
        })
      }, 5000)
    }
  }
}
</script>

<template>
  <div class="main-content reports-create">
    <ContentHeader
      :title="$t('reports.generate:report.comments.list.title')"
      light-theme
      fixed
    >
      <Action
        slot="back"
        type="button"
        :text="$t('global:back.reports')"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave"
      />
      <ActionBar slot="actionbar" />
    </ContentHeader>
    <div class="main-content-inner">
      <div class="center">
        <div class="solutions-create-header">
          <h2
            class="solutions-create-title"
            v-html="$t('reports.generate:header.description')"
          />
        </div>
        <form class="form">
          <FormSection :title="$t('global:form.filters')">
            <SelectField
              v-model="toolTypeSelected"
              :label="$t('global:form.survey.tool.type')"
              :items="toolTypes"
              :validation="$v.toolTypeSelected"
            />
            <div v-if="toolTypeSelected">
              <SelectField
                v-if="toolTypeSelected === 'solution'"
                v-model="formData.solutions"
                :label="$t('global:form.solution')"
                :items="allSolutionsList"
                :validation="$v.formData.solutions"
                multiple
                :select-all-option="true"
                :searcheable="true"
              />
              <SelectField
                v-if="toolTypeSelected === 'program'"
                v-model="formData.programs"
                :label="$t('global:menu.programs')"
                :items="allProgramsList"
                :validation="$v.formData.programs"
                multiple
                :select-all-option="true"
                :searcheable="true"
              />
              <SelectField
                v-model="formData.status"
                :label="$t('reports.generate:report.comments.list.status.label')"
                :items="filteredFormLabels.status"
                :validation="$v.formData.status"
                multiple
                :select-all-option="true"
              />
              <SelectField
                v-model="formData.grade"
                :label="$t('comments:label.grade')"
                multiple
                :items="filteredFormLabels.grade"
                :validation="$v.formData.grade"
                :select-all-option="true"
              />
              <Datepicker
                v-model="formData.dateInit"
                :label="$t('global:form.filters.date.init')"
                :validation="$v.formData.dateInit"
              />
              <Datepicker
                v-model="formData.dateEnd"
                :label="$t('global:form.filters.date.end')"
                :validation="$v.formData.dateEnd"
              />
            </div>
          </FormSection>
          <Action
            slot="button"
            primary
            large
            fixed-width
            type="button"
            :text="$t('reports.generate:btn.generate')"
            @click="getReportCommentsList"
          />
        </form>
      </div>
    </div>
    <ModalConfirm
      :active="modalWarning"
      :title="$t('reports.generate:report.comments.list.title')"
      :description="$t('reports.generate:report.modal.description')"
      @cancelAction="closeModal"
      @confirmAction="generateReport"
    />
  </div>
</template>

<style src="@/assets/styles/themes/default/solutions.css"></style>
