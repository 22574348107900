<script>
import regexCreator from 'emoji-regex'
import { mask } from 'vue-the-mask'

export default {
  name: 'InputField',
  components: {
    Action: () => import('@/components/general/Action'),
    Icon: () => import('@/components/general/Icon'),
    PasswordMeter: () => import('@/components/general/PasswordMeter'),
    ValidationMessage: () => import('@/components/general/ValidationMessage')
  },

  directives: {
    mask
  },

  props: {
    autocomplete: {
      type: String,
      default: null
    },

    autocapitalize: {
      type: String,
      default: null
    },

    placeholder: {
      type: String,
      default: null
    },

    label: {
      type: String,
      default: null
    },

    floatingLabel: {
      type: Boolean,
      default: true
    },

    disabled: {
      type: Boolean,
      default: false
    },

    readonly: {
      type: Boolean,
      default: false
    },

    value: null,
    validation: {
      type: Object,
      default: function () {
        return {}
      }
    },

    type: {
      type: String,
      default: null
    },

    counter: {
      type: Number,
      default: null
    },

    hint: {
      type: String,
      default: null
    },

    hintTooltip: {
      type: Boolean,
      default: false
    },

    prependIcon: {
      type: String,
      default: null
    },

    prependLabel: {
      type: String,
      default: null
    },

    appendLabel: {
      type: String,
      default: null
    },

    appendIcon: {
      type: String,
      default: null
    },

    showCheck: {
      type: Boolean,
      default: false
    },

    passwordMeter: {
      type: Boolean,
      default: false
    },

    customPrepend: {
      type: Boolean,
      default: false
    },

    dark: {
      type: Boolean,
      default: false
    },

    hideDetails: {
      type: Boolean,
      default: false
    },

    hideErrorDetails: {
      type: Boolean,
      default: false
    },

    min: {
      type: Number,
      default: null
    },

    max: {
      type: Number,
      default: null
    },

    step: {
      type: Number,
      default: 1
    },

    disabledVisibility: {
      type: Boolean,
      default: false
    },

    hideOptional: {
      type: Boolean
    },

    mask: {
      type: [
        String,
        Array
      ],

      default: null
    },

    autofocus: {
      type: Boolean,
      default: false
    },

    hasPercent: {
      type: Boolean,
      default: false
    },

    hideArrows: {
      type: Boolean,
      default: false
    },

    underDescription: {
      type: String,
      default: null
    },

    isRoundValueNumber: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      isMounted: false,
      emojiRegex: null,
      isFocused: false,
      passwordVisible: false,
      internalType: this.type,
      capslock: false,
      autofill: false,
      isDecimalNumber: false,
      invalidKeyCode: [
        109,
        107,
        69,
        189,
        187,
        190
      ]
    }
  },

  computed: {
    hasValue () {
      return (this.value != null && this.value.toString().length > 0) || this.autofill || this.placeholder !== null
    },

    // hasValidation () {
    //   return Object.keys(this.validation).length
    // },

    isValid () {
      return this.validation.$error === false && this.validation.$dirty && this.showCheck
    },

    isPassword () {
      return this.type === 'password'
    },

    isNumber () {
      return this.type === 'number'
    },

    isRequired () {
      return this.validation.$params != null && typeof this.validation.$params.required === 'object'
    },

    isUniqueInsideForm () {
      return this.isMounted && (this.$refs.input.form == null || Array.from(this.$refs.input.form.elements).filter(elem => elem.nodeName !== 'BUTTON').length === 1)
    },

    visibilityIcon () {
      return this.passwordVisible ? 'visibility' : 'visibility_off'
    },

    length () {
      return this.counter && this.value ? this.value.length : 0
    },

    appendSize () {
      return this.isMounted ? this.$refs.append.offsetWidth : 0
    }
  },

  watch: {
    appendSize () {
      this.$refs.input.style.paddingRight = `${this.appendSize}px`
    }
  },

  mounted () {
    this.isDecimalNumber = this.internalType === 'number' && this.step.toString().includes('.')
    this.isMounted = true
    this.emojiRegex = regexCreator()
    this.checkAutoFill()
  },

  methods: {
    updateValue (value) {
      if (!this.isNumber) {
        value = this.removeEmoji(value)
      }

      this.autofill = false
      this.$emit('input', value)
    },

    roundValueNumber () {
      if (this.isNumber && !isNaN(parseFloat(this.value)) && this.isRoundValueNumber) {
        // let newValue = parseFloat(this.value).toFixed(2).replace('.00', '')
        let newValue = this.isDecimalNumber ? parseFloat(this.value).toFixed(2).toString() : parseFloat(this.value).toFixed(0)

        newValue = this.max !== null && newValue > this.max ? this.max : newValue
        this.$emit('input', newValue)
      }
    },

    updateFocus () {
      this.isFocused = true
      typeof this.validation.$reset === 'function' && this.validation.$reset()
    },

    updateBlur (event) {
      this.$emit('blur', event)
      this.isFocused = false
      this.roundValueNumber()
      typeof this.validation.$touch === 'function' && this.validation.$touch()
    },

    removeEmoji (value) {
      return value.replace(this.emojiRegex, '')
    },

    changeVisiblity () {
      this.passwordVisible = !this.passwordVisible
      this.internalType = this.passwordVisible ? 'text' : 'password'
      this.$refs.input.focus()
    },

    checkCapslock (event) {
      this.capslock = event.getModifierState && event.getModifierState('CapsLock')
    },

    keyUp (event) {
      this.$emit('keyup', event)
      this.isPassword && this.checkCapslock(event)
    },

    keyDown (event) {
      if (this.type === 'number' && (this.invalidKeyCode.includes(event.keyCode) || (this.value != null && this.value.toString().indexOf('.') > -1 && event.keyCode === 188))) {
        event.preventDefault()
      }

      this.isPassword && this.checkCapslock(event)
    },

    addNumber () {
      this.roundValueNumber()
      let newValue = (this.value ? parseFloat(this.value) : 0) + this.step

      newValue = this.max !== null && newValue > this.max ? this.max : newValue
      this.updateValue(this.isDecimalNumber ? newValue.toFixed(2) : newValue)
    },

    subtractNumber () {
      this.roundValueNumber()
      let newValue = (this.value ? parseFloat(this.value) : 0) - this.step

      newValue = this.min !== null && newValue < this.min ? this.min : newValue
      this.updateValue(this.isDecimalNumber ? newValue.toFixed(2) : newValue)
    },

    checkAutoFill () {
      const onAnimationStart = ({ animationName }) => {
        if (animationName === 'autofilldark' || animationName === 'autofill') {
          this.autofill = true
        }
      }

      this.$refs.input.addEventListener('animationstart', onAnimationStart, false)
    },

    mousewheel (event) {
      this.$emit('mousewheel', event)
    },

    wheel (event) {
      this.$emit('wheel', event)
    },

    change (event) {
      this.$emit('change', event)
    }
  }
}
</script>

<template>
  <div
    :class="{ 'is-readonly': readonly,'has-error': validation.$error, 'has-value': hasValue, 'has-focus': isFocused, 'is-disabled': disabled, 'is-valid': isValid, 'has-floating-label': floatingLabel, 'has-prepend': prependIcon || prependLabel || customPrepend, 'hide-details': hideDetails, 'theme-dark': dark, 'has-append-icon': isNumber }"
    class="form-item"
  >
    <label
      v-if="label"
      class="form-label"
      :for="'input' + _uid"
    >{{ label }} <span v-if="!hideOptional && !isRequired && !disabled && !isUniqueInsideForm">{{ $t('global:form.optional') }}</span></label>
    <div
      v-if="prependIcon || prependLabel || customPrepend"
      class="form-input-prepend"
    >
      <slot name="prepend" />
      <span
        v-if="prependLabel"
        class="form-input-prepend-label"
        @click="$emit('prependAction')"
      >{{ prependLabel }}</span>
      <Icon
        v-if="prependIcon"
        :name="prependIcon"
        wrapper
        class="form-input-prepend-icon"
      />
    </div>
    <div class="form-input-wrapper">
      <input
        v-if="mask"
        :id="'input' + _uid"
        ref="input"
        v-mask="mask"
        class="form-input"
        spellcheck="false"
        :type="internalType"
        :autocomplete="autocomplete"
        :autocapitalize="autocapitalize"
        :placeholder="placeholder"
        :disabled="disabled"
        :readonly="readonly"
        :value="value"
        :maxLength="counter"
        :min="min"
        :max="max"
        :step="internalType === 'number' ? step : null"
        @keydown="keyDown($event)"
        @keyup="keyUp($event)"
        @input="updateValue($event.target.value)"
        @focus="updateFocus()"
        @blur="updateBlur($event)"
        @mousewheel="mousewheel($event)"
        @wheel="wheel($event)"
        @change="change($event)"
      >
      <input
        v-if="!mask"
        :id="'input' + _uid"
        ref="input"
        class="form-input"
        spellcheck="false"
        :autofocus="autofocus"
        :type="internalType"
        :autocomplete="autocomplete"
        :autocapitalize="autocapitalize"
        :placeholder="placeholder"
        :disabled="disabled"
        :readonly="readonly"
        :value="value"
        :maxLength="counter"
        :min="min"
        :max="max"
        :step="internalType === 'number' ? step : null"
        @keydown="keyDown($event)"
        @keyup="keyUp($event)"
        @input="updateValue($event.target.value)"
        @focus="updateFocus()"
        @blur="updateBlur($event)"
        @mousewheel="mousewheel($event)"
        @wheel="wheel($event)"
        @change="change($event)"
      >
      <div
        ref="append"
        class="form-input-append"
      >
        <span
          v-if="appendLabel && !disabled"
          class="form-input-append-label"
        >{{ appendLabel }}</span>
        <Icon
          v-if="appendIcon"
          :name="appendIcon"
          wrapper
        />
        <Icon
          v-if="capslock"
          name="keyboard_capslock"
          wrapper
        />
        <Action
          v-if="isPassword && !disabledVisibility"
          type="link"
          class="btn-visibility"
          :icon="visibilityIcon"
          @click="changeVisiblity()"
        />
        <Icon
          v-if="isValid"
          name="check"
          wrapper
        />
        <div
          v-if="isNumber && !hideArrows"
          class="input-number-controls"
        >
          <button
            tabindex="-1"
            type="button"
            class="input-number-btn"
            :disabled="disabled"
            @click="addNumber()"
          >
            <Icon
              name="arrow_drop_up"
              size="small"
              wrapper
            />
          </button>
          <button
            tabindex="-1"
            type="button"
            class="input-number-btn"
            :disabled="disabled"
            @click="subtractNumber()"
          >
            <Icon
              name="arrow_drop_down"
              size="small"
              wrapper
            />
          </button>
        </div>
        <span
          v-if="isNumber && hasPercent"
          class="percent"
        >%</span>
        <slot name="button" />
      </div>
    </div>
    <div
      v-if="!hideErrorDetails"
      class="form-input-details"
    >
      <span
        v-if="hint && !validation.$error"
        :class="{'form-input-hint-tooltip': hintTooltip, 'form-input-hint': !hintTooltip}"
      >{{ hint }}</span>
      <span
        v-if="counter"
        class="form-input-counter"
      >{{ length }} / {{ counter }}</span>
      <p
        v-if="underDescription"
        class="form-input-subtext"
      >
        {{ underDescription }}
      </p>
      <ValidationMessage :validation="validation" />
      <PasswordMeter
        v-if="passwordMeter"
        :value="value"
      />
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/form.css"></style>
