import { http } from '@/support/http'
import { createQuery } from '../../../support/utils/paginatorQueryBuilder'
import { parseToFormDataWithArray } from '@/support/payloadParser'

export const getToolsList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/tools?${queryString}`)
}

export const getTool = (idTool) => {
  return http.get(`showcase/tools/${idTool}`)
}

export const toggleToolActive = (data) => {
  const id = data.id
  const form = JSON.stringify(data)

  return http.post(`api/tools/${id}`, form, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export const saveTool = (data) => {
  const form = parseToFormDataWithArray(data)

  if (data.cardImage && typeof data.cardImage === 'object') {
    form.append('cardImage', data.cardImage, data.cardImage.name)
  }

  return http.post('api/tools', form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const updateTool = (data) => {
  const id = data.id

  if (data.cardImage && typeof data.cardImage === 'object') {
    const form = parseToFormDataWithArray(data)

    form.append('cardImage', data.cardImage, data.cardImage.name)

    return http.post(`api/tools/${id}`, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  } else {
    const form = JSON.stringify(data)

    return http.post(`api/tools/${id}`, form, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }
}

export const getTypeToolsList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`showcase/tools/types?${queryString}`)
}

export const getTypeTool = (idTool) => {
  return http.get(`showcase/tools/types/${idTool}`)
}

export const toggleTypeToolActive = (data) => {
  const id = data.id

  const form = JSON.stringify(data)

  return http.put(`api/tools/types/${id}`, form, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export const saveTypeTool = (data) => {
  const form = parseToFormDataWithArray(data)

  return http.post('api/tools/types', form)
}

export const updateTypeTool = (data) => {
  const form = JSON.stringify(data)
  const id = data.id

  return http.put(`api/tools/types/${id}`, form, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
