<script>
import { required, maxLength } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import { formatSlug } from '@/support/utils/stringHelper'

export default {
  name: 'ManageTheme',
  components: {
    Modal: () => import('@/components/general/Modal'),
    Action: () => import('@/components/general/Action'),
    // ColorPicker: () => import('@/components/general/ColorPicker'),
    // IconPicker: () => import('@/components/general/IconPicker'),
    InputField: () => import('@/components/general/InputField'),
    FormSection: () => import('@/components/general/FormSection'),
    Upload: () => import('@/components/general/Upload')
  },

  data () {
    return {
      formData: {
        id: null,
        name: null,
        alias: null,
        callText: null,
        slug: null,
        banner: null,
        cardImage: null,
        active: true
      },

      formatedUrl: '',
      isSlugDuplicate: false,
      isAliasDuplicate: false
    }
  },

  computed: {
    isEditing () {
      return !!this.$route.params.id
    }
  },

  validations: {
    formData: {
      name: {
        required,
        maxLength: maxLength(25)
      },

      slug: {
        required,
        regexSlug: (event) => {
          const regexSlug = new RegExp('^[a-z0-9]+(?:-[a-z0-9]+)*$')

          return regexSlug.test(event)
        },

        slugDuplicate: function () {
          return !this.isSlugDuplicate
        }
      },

      alias: {
        required,
        aliasDuplicate: function () {
          return !this.isAliasDuplicate
        }
      },

      callText: {
        required
      },

      banner: {
        required
      },

      cardImage: {
        required
      }
    }
  },

  watch: {
    'formData.name' () {
      if (!this.$v.formData.slug.$dirty && !this.isEditing) {
        this.formData.slug = formatSlug(this.formData.name)
      }
    },

    'formData.slug' (event) {
      this.formData.slug = event.replace(new RegExp('[-]{2,}', 'g'), '-')

      this.debounceEvent(async () => {
        this.setFetching(true)

        // this.paginationTheme.filter.name = event

        // const themes = await this.attemptListThemes(this.paginationTheme)
        //   .then(pagination => { return pagination.data })

        // this.isSlugDuplicate = !(themes.length > 0)

        // if (!this.isSlugDuplicate) {
        //   this.$v.formData.slug.$touch()
        // }

        this.setFetching(false)
      }, 1000)

      if (!this.isSlugDuplicate) return
      this.isSlugDuplicate = false

      this.formatedUrl = `${process.env.VUE_APP_PORTAL_URL}tema/${this.formData.slug}`
    },

    'formData.alias' () {
      if (!this.isAliasDuplicate) return
      this.isAliasDuplicate = false
    }
  },

  created () {
    if (this.isEditing) {
      if (!this.$route.params.theme) {
        this.setFeedback({ message: this.$t('management.themes:error.loading.failed') })
        this.close()
      } else {
        this.formData = {
          ...this.formData,
          ...this.$route.params.theme
        }

        if (this.formData.cardImage === 'image.jpg') {
          this.formData.cardImage = '/assets/images/themes/default/imgs/theme-card.webp'
        }

        if (this.formData.banner === 'image.jpg') {
          this.formData.banner = '/assets/images/themes/default/imgs/theme-banner.webp'
        }
      }
    }
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptCreateTheme',
      'attemptUpdateTheme'
    ]),

    close () {
      this.$router.push({
        name: 'management.index',
        params: { activeTab: 1 }
      })
    },

    submit () {
      this.$v.$touch()

      if (this.$v.$invalid) {
        this.scrollToError()

        return
      }

      if (this.isEditing) {
        this.submitUpdate()
      } else {
        this.submitCreate()
      }
    },

    submitCreate () {
      this.setFetching(true)

      this.attemptCreateTheme(this.formData)
        .then(({ data }) => {
          this.formData = data
          this.setFeedback({ message: this.$t('themes:feedback.created.success') })

          this.$router.replace({
            name: 'management.themes.manage',
            params: {
              id: data.id,
              theme: data
            }
          })

          this.close()
        })
        .catch(({ data }) => {
          if (data.error.message === 'theme_slug_already_in_use') {
            this.setFeedback({ message: this.$t('management.themes:error.theme_slug_already_exists') })
            this.isSlugDuplicate = true
            this.$v.formData.slug.$touch()
            this.scrollToError()
          } else if (data.error.message === 'theme_alias_already_exists') {
            this.setFeedback({ message: this.$t('management.themes:error.theme_alias_already_exists') })
            this.isAliasDuplicate = true
            this.$v.formData.slug.$touch()
            this.scrollToError()
          } else {
            this.setFeedback({ message: this.$t('management:feedback.theme.edit.error') })
          }
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    async submitUpdate () {
      this.setFetching(true)

      const data = { ...this.formData }

      try {
        if (typeof data.banner !== 'object') {
          const bannerBlob = await this.urlToBlob(data.banner)

          data.banner = bannerBlob
        }

        if (typeof data.cardImage !== 'object') {
          const cardImageBlob = await this.urlToBlob(data.cardImage)

          data.cardImage = cardImageBlob
        }

        const response = await this.attemptUpdateTheme(data)

        this.formData = response.data
        this.setFeedback({ message: this.$t('themes:feedback.edit.success') })
        this.close()
      } catch ({ data }) {
        if (data.error.message === 'theme_slug_already_in_use') {
          this.setFeedback({ message: this.$t('management.themes:error.theme_slug_already_exists') })
          this.isSlugDuplicate = true
          this.$v.formData.slug.$touch()
          this.scrollToError()
        } else if (data.error.message === 'theme_alias_already_exists') {
          this.setFeedback({ message: this.$t('management.themes:error.theme_alias_already_exists') })
          this.isAliasDuplicate = true
          this.$v.formData.slug.$touch()
          this.scrollToError()
        } else {
          this.setFeedback({ message: this.$t('management:feedback.theme.edit.error') })
        }
      } finally {
        this.setFetching(false)
      }
    },

    async urlToBlob (url) {
      const response = await fetch(url)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const blob = await response.blob()

      return blob
    },

    scrollToError () {
      this.debounceEvent(() => {
        const hasErrorElements = document.querySelector('.has-error')
        const top = hasErrorElements.offsetTop - 100

        document.querySelector('.modal-blocker')
          .scroll({
            top: top,
            behavior: 'smooth'
          })
      }, 100)
    }

  }
}
</script>

<template>
  <Modal
    :active="true"
    :cancel="true"
    @close="close"
  >
    <div class="modal-form modal-manage-theme">
      <span class="modal-subtitle">{{ $t('management:themes.manage.title') }}</span>
      <h2 class="modal-title">
        {{ $t(`management:themes.manage.description.editing.${isEditing}`) }}
      </h2>
      <form @submit.prevent="submit">
        <InputField
          v-model="formData.name"
          :label="$t('management:themes.manage.name')"
          :validation="$v.formData.name"
          :counter="25"
          dark
        />

        <InputField
          v-model="formData.slug"
          :label="$t('management:themes.manage.slug')"
          :under-description="formatedUrl"
          :validation="$v.formData.slug"
          :counter="100"
          dark
        />

        <InputField
          v-model="formData.callText"
          :label="$t('management:themes.manage.callText')"
          :validation="$v.formData.callText"
          :counter="250"
          dark
        />

        <InputField
          v-model="formData.alias"
          :label="$t('management:themes.manage.alias')"
          :validation="$v.formData.alias"
          :counter="20"
          dark
        />

        <FormSection :title="$t('global:upload.add.image.spotlight')">
          <Upload
            v-model="formData.banner"
            icon="image-multiple"
            :label="$t('global:upload.add.image')"
            :description="$t('management:themes.manage.image.card.banner')"
            cropper
            :width="1940"
            :height="800"
            :preview="0.22"
            :validation="$v.formData.banner"
            dark
          />
        </FormSection>

        <FormSection :title="$t('global:upload.add.image.card')">
          <Upload
            v-model="formData.cardImage"
            icon="image-multiple"
            :label="$t('global:upload.add.image')"
            :description="$t('management:themes.manage.image.card.description')"
            cropper
            :width="312"
            :height="448"
            :preview="0.75"
            :validation="$v.formData.cardImage"
            dark
          />
        </FormSection>

        <!--
        <ColorPicker
          v-model="formData.color"
          :label="$t('management:themes.manage.color')"
          :validation="$v.formData.color"
          :options="colorPickerOptions"
          class="form-item"
          dark
        />
-->

        <!--
 <IconPicker
          v-model="formData.icon"
          :label="$t('management:themes.manage.icon.description')"
          :validation="$v.formData.icon"
          :options="iconPickerOptions"
          context="/assets/images/themes/default/svg/themes/"
          class="form-item"
          dark
        />
-->

        <Action
          :text="$t('global:form.save')"
          type="button"
          secondary
          large
          submit
          fixed-width
        />
      </form>
    </div>
  </Modal>
</template>

<style>
.modal-manage-theme form {
  text-align: center;
}

.form-section .form-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-input-subtext {
  text-align: left;
}

.modal-manage-theme .btn {
  margin-top: 40px;
}

.upload-description {
  text-align: left;
}
</style>
