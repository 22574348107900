const ToolsList = () => import('./components/ToolsList')
const TypeTools = () => import('./components/TypeToolsList')
const ToolsManage = () => import('./components/ToolsManage')
const TypeToolsManage = () => import('./components/TypeToolsManage')

export default [
  {
    name: 'tools.index',
    path: '/tools/list',
    component: ToolsList,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'tools',
        access: 'read'
      }
    }
  },
  {
    name: 'tools.type_tools.index',
    path: '/type_tools/list',
    component: TypeTools,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'tools',
        access: 'read'
      }
    }
  },
  {
    name: 'tools.manage',
    path: '/tools/manage/:id?',
    component: ToolsManage,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'tools',
        access: 'write'
      }
    }
  },
  {
    name: 'tools.type_tools.manage',
    path: '/type_tools/manage/:id?',
    component: TypeToolsManage,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'tools',
        access: 'write'
      }
    }
  }
]
