<script>
export default {
  name: 'Modal',
  components: {
    Action: () => import('@/components/general/Action')
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    cancel: {
      type: Boolean,
      default: true
    },
    preventESC: {
      type: Boolean,
      default: false
    },
    cancelLabel: {
      type: String,
      default: '',
      required: false
    }
  },
  data () {
    return {
      zIndex: null,
      modalLength: 0
    }
  },
  computed: {
    getCancelLabel () {
      return this.cancelLabel ? this.cancelLabel : this.$t('global:cancel')
    }
  },
  mounted () {
    document.body.appendChild(this.$el)
    this.checkClass()
  },
  updated () {
    this.checkClass()
  },
  destroyed () {
    if (this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el)
    }
    this.checkClass()
  },
  methods: {
    closeModal () {
      if (this.$route.meta.modalCloseLink) {
        this.$router.push(this.$route.meta.modalCloseLink)
      } else {
        if (typeof this.$listeners.close === 'function') {
          this.$emit('close')
        } else {
          this.$emit('update:active', false)
        }
      }
    },
    checkEscape (event) {
      if (event.keyCode === 27 && !this.preventESC) {
        this.closeModal()
      }
    },
    checkClass () {
      this.modalLength = document.body.querySelectorAll('.modal-blocker').length
      this.zIndex = 100 + this.modalLength
      if (this.modalLength) {
        document.body.classList.add('modal-open')
        window.addEventListener('keydown', this.checkEscape)
      } else {
        document.body.classList.remove('modal-open')
        window.removeEventListener('keydown', this.checkEscape)
      }
    }
  }
}
</script>

<template>
  <div
    v-if="active"
    class="modal-blocker"
    tabindex="-1"
    :style="{ 'z-index': zIndex }"
  >
    <div class="modal">
      <action
        v-if="cancel"
        type="button"
        icon="keyboard_backspace"
        icon-size="medium"
        :text="getCancelLabel"
        class="btn-back"
        @click="closeModal"
      />
      <slot />
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/modal.css"></style>
