import { http } from '@/support/http'
import { createQuery } from '@/support/utils/paginatorQueryBuilder'
import { parseToFormDataWithArray } from '@/support/payloadParser'

export const getProgramsList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/programs?${queryString}`)
}

export const getProgram = (idProgram) => {
  return http.get(`api/program/${idProgram}`)
}

export const saveProgram = (data) => {
  const form = parseToFormDataWithArray(data)

  if (data.cardImage && typeof data.cardImage === 'object') {
    form.append('cardImage', data.cardImage, data.cardImage.name)
  }

  if (data.image && typeof data.image === 'object') {
    form.append('image', data.image, data.image.name)
  }

  return http.post('api/program', form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const updateProgram = ({ id, data, isLinkedTab }) => {
  const form = parseToFormDataWithArray(data)

  if (data.image && typeof data.image === 'object') {
    form.append('image', data.image, data.image.name)
  }

  if (data.cardImage && typeof data.cardImage === 'object') {
    form.append('cardImage', data.cardImage, data.cardImage.name)
  }

  form.append('isLinkedTab', isLinkedTab)

  return http.post(`api/program/${id}`, form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const toggleActiveProgram = (idProgram) => {
  return http.put(`api/program/${idProgram}/active/toggle`)
}

export const updateProgramsHighlights = (data) => {
  return http.post('api/program_highlight', data)
}

export const getListProgramsHighlights = () => {
  return http.get('showcase/programs_highlights')
}
