<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ContentHeader',
  components: {
    Action: () => import('@/components/general/Action'),
    Icon: () => import('@/components/general/Icon'),
    Logo: () => import('@/components/general/Logo'),
    Profile: () => import('@/components/general/Profile')
  },
  props: {
    title: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    tag: {
      type: String,
      default: null
    },
    background: {
      type: String,
      default: null
    },
    back: {
      type: Boolean,
      default: false
    },
    backText: {
      type: String,
      default: 'Voltar'
    },
    backUrl: {
      type: Object,
      default: null
    },
    thick: {
      type: Boolean,
      default: false
    },
    retract: {
      type: Boolean,
      default: false
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: false
    },
    lightTheme: {
      type: Boolean,
      default: false
    },
    darkTheme: {
      type: Boolean,
      default: false
    },
    fitTitle: {
      type: Boolean,
      default: false
    },
    float: {
      type: Boolean,
      default: false
    },
    classroom: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      retractActive: false,
      objectStyle: {
        backgroundImage: 'auto'
      }
    }
  },
  computed: {
    ...mapGetters(['isFullscreen'])
  },
  watch: {
    title () {
      this.$nextTick(() => {
        !this.$media.mobile && this.calculateTitleFontSize()
      })
    }
  },
  methods: {
    ...mapActions([
      'setFullscreen'
    ]),
    goBack () {
      if (this.backUrl) {
        this.$router.push(this.backUrl)
      } else {
        this.$router.go(-1)
      }
    },
    goFullscreen () {
      this.setFullscreen()
    },
    retractHeader () {
      this.retractActive = !this.retractActive
      !this.$media.mobile && this.calculateTitleFontSize()
      this.$emit('update-dimension-video', { headerIsRetracted: this.retractActive })
    },
    getBackgroundImage () {
      return this.background ? `linear-gradient(#1941A0AA, #000000AA), url('${this.background}')` : null
    },
    calculateTitleFontSize () {
      if (this.retract) {
        let fontBase = 4
        const minFontSize = 1.6
        const maxHeight = this.retractActive ? 40 : 66
        this.$refs.title.style.fontSize = fontBase + 'em'
        while (this.$refs.title.scrollHeight > maxHeight) {
          if (fontBase <= minFontSize) return
          fontBase = (fontBase - 0.1).toFixed(1)
          this.$refs.title.style.fontSize = fontBase + 'em'
        }
      } else if (this.fitTitle) {
        let fontBase = 5
        const minFontSize = 1.6
        const maxHeight = 115
        this.$refs.title.style.fontSize = fontBase + 'em'
        while (this.$refs.title.scrollHeight > maxHeight) {
          if (fontBase <= minFontSize) return
          fontBase = (fontBase - 0.1).toFixed(1)
          this.$refs.title.style.fontSize = fontBase + 'em'
        }
      }
    },
    openMenu () {
      if (this.$route.params.session_uuid) {
        this.$parent.$parent.$parent.openMenu(true)
      } else {
        this.$parent.$parent.openMenu(true)
      }
    }
  },
  mounted () {
    this.title && !this.$media.mobile && this.calculateTitleFontSize()
  }
}
</script>

<template>
  <header
    class="header"
    :style="{ 'backgroundImage': getBackgroundImage() }"
    :class="{ 'has-background': background, 'has-title': title, 'has-bottom': $slots.tabs || fullscreen || retract, 'is-thick': thick, 'is-retracted': retractActive, 'is-fixed': fixed, 'is-float': float, 'light-theme': lightTheme, 'dark-theme': darkTheme }"
  >
    <div
      v-if="$media.mobile"
      class="mobile-header"
    >
      <action
        type="button"
        icon="menu"
        class="btn-menu"
        @click="openMenu()"
      />
      <span
        v-if="title"
        class="mobile-header-title"
      >{{ title }}</span>
      <logo
        v-else-if="!classroom"
        logo
        :theme="$theme"
      />
      <icon
        v-else
        name="local_library"
        class="icon-image"
      />
      <!--      <profile></profile>-->
    </div>
    <div class="header-content">
      <div class="center">
        <slot
          v-if="!$media.mobile"
          name="actionbar"
        />
        <slot
          v-if="!$media.mobile"
          name="search"
        />
        <slot name="back">
          <action
            v-if="back"
            type="button"
            :text="backText"
            icon="keyboard_backspace"
            class="btn-back"
            @click="goBack()"
          />
        </slot>
        <div
          v-if="tag || title || description || $slots.anything || $slots.progress"
          class="header-content-info"
        >
          <span
            v-if="tag"
            class="header-tag"
          >{{ tag }}</span>
          <h1
            v-if="title"
            ref="title"
            class="header-title"
          >
            {{ title }}
          </h1>
          <p
            v-if="description"
            class="header-description"
          >
            {{ description }}
          </p>
          <slot name="progress" />
          <slot name="anything" />
        </div>
        <div
          v-if="$slots.buttons"
          class="header-form-buttons"
        >
          <slot name="buttons" />
        </div>
        <slot name="header-content" />
      </div>
    </div>
    <div
      v-if="$slots.tabs || fullscreen || retract"
      class="header-bottom"
    >
      <slot name="tabs" />
      <action
        v-if="fullscreen && !$media.mobile"
        type="button"
        :icon="isFullscreen ? 'fullscreen_exit' : 'zoom_out_map'"
        class="btn-fullscreen"
        @click="goFullscreen"
      />
      <action
        v-if="retract && !isFullscreen"
        type="button"
        :icon="retractActive ? 'keyboard_arrow_down' : 'keyboard_arrow_up'"
        class="btn-retract"
        @click="retractHeader"
      />
    </div>
  </header>
</template>

<style src="@/assets/styles/themes/default/contentheader.css"></style>
