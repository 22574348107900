<script>
export default {
  name: 'ValidationMessage',
  components: {
    Icon: () => import('@/components/general/Icon')
  },

  props: {
    validation: {
      type: Object,
      default: null
    },

    currency: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template v-if="validation">
  <div
    v-if="validation.$error"
    class="form-input-messages-container"
  >
    <Icon
      name="error"
      wrapper
      size="small"
    />
    <div
      v-if="
        Object.keys(validation).indexOf('differentPassword') !== -1
          && (validation.required === true) && (validation.maxLength === true)
          && (validation.minLength === true) && !validation.differentPassword"
    >
      <span class="form-input-message">{{ $t('global:validation.different.password') }}

      </span>
    </div>
    <div
      v-if="
        Object.keys(validation).indexOf('equalPassword') !== -1
          && (validation.required === true)
          && (validation.maxLength === true)
          && (validation.minLength === true)
          && !validation.equalPassword"
    >
      <span class="form-input-message">{{ $t('global:validation.equal.password') }}

      </span>
    </div>
    <span
      v-if="!validation.required && validation.required != undefined"
      class="form-input-message"
    >{{ $t('global:validation.required') }}
    </span>

    <span
      v-else-if="!validation.minLength && validation.minLength != undefined"
      class="form-input-message"
    >{{ $t('global:validation.minlength', {'num': validation.$params.minLength.min}) }}
    </span>

    <span
      v-else-if="!validation.maxLength && validation.maxLength != undefined"
      class="form-input-message"
    >{{ $t('global:validation.maxlength', {'num': validation.$params.maxLength.max}) }}
    </span>

    <span
      v-else-if="!validation.customMaxLength && validation.customMaxLength != undefined"
      class="form-input-message"
    >{{ $t('global:validation.customMaxLength') }}
    </span>

    <span
      v-else-if="!validation.alpha && validation.alpha != undefined"
      class="form-input-message"
    >{{ $t('global:validation.alpha') }}
    </span>

    <span
      v-else-if="!validation.alphaNum && validation.alphaNum != undefined"
      class="form-input-message"
    >{{ $t('global:validation.alphanum') }}
    </span>

    <span
      v-else-if="!validation.userEmail && validation.userEmail != undefined"
      class="form-input-message"
    >{{ $t('global:validation.email') }}
    </span>

    <span
      v-else-if="!validation.userEmailExists && validation.userEmailExists != undefined"
      class="form-input-message"
    >{{ $t('community.index:feedback.created.error.user.exists') }}
    </span>

    <span
      v-else-if="!validation.userExistsValidator && validation.userExistsValidator != undefined"
      class="form-input-message"
    >{{ $t('global:validation.email.exists') }}
    </span>

    <span
      v-else-if="!validation.usernameValidator && validation.usernameValidator != undefined"
      class="form-input-message"
    >{{ $t('global:validation.username') }}
    </span>

    <span
      v-else-if="!validation.usernameExistsValidator && validation.usernameExistsValidator != undefined"
      class="form-input-message"
    >{{ $t('global:validation.username.exists') }}
    </span>

    <span
      v-else-if="!validation.email && validation.email != undefined"
      class="form-input-message"
    >{{ $t('global:validation.email') }}
    </span>

    <span
      v-else-if="!validation.minValue && validation.minValue != undefined"
      class="form-input-message"
    >{{ $t('global:validation.minvalue', {'num': validation.$params.minValue.min}) }}
    </span>

    <span
      v-else-if="!validation.valueBiggerZero && validation.valueBiggerZero != undefined"
      class="form-input-message"
    >{{ $t('global:validation.valuebiggerzero') }}
    </span>

    <span
      v-else-if="!validation.emptyRAE && validation.emptyRAE != undefined"
      class="form-input-message"
    > {{ $t('global:validation.emptyRAE') }}
    </span>

    <span
      v-else-if="!validation.validatePortfolio && validation.validatePortfolio != undefined"
      class="form-input-message"
    >{{ $t('solutions:feedback.portfolio.invalid') }}
    </span>

    <span
      v-else-if="!validation.maxValue && validation.maxValue != undefined"
      class="form-input-message"
    >{{ $t('global:validation.maxvalue', {'num': currency
      ? $n(validation.$params.maxValue.max, 'currency')
      : validation.$params.maxValue.max}) }}
    </span>

    <span
      v-else-if="!validation.between && validation.between != undefined"
      class="form-input-message"
    >{{ $t('global:validation.between', {'num1': currency
      ? $n(validation.$params.between.min, 'currency').replace(/^(\D+)/, '$1 ')
      : validation.$params.between.min, 'num2': currency
      ? $n(validation.$params.between.max, 'currency').replace(/^(\D+)/, '$1 ')
      : validation.$params.between.max}) }}
    </span>

    <span
      v-else-if="!validation.url && validation.url != undefined"
      class="form-input-message"
    >{{ $t('global:validation.url') }}
    </span>

    <span
      v-else-if="!validation.validURL && validation.validURL != undefined"
      class="form-input-message"
    >{{ $t('global:validation.validURL') }}
    </span>

    <span
      v-else-if="!validation.fileSize && validation.fileSize != undefined"
      class="form-input-message"
    >{{ $t('global:validation.file.size', {'num':(validation.$params.fileSize.size / 1024 / 1024)}) }}
    </span>

    <span
      v-else-if="validation.required && !validation.mimeType && validation.mimeType != undefined"
      class="form-input-message"
    >{{ $t('global:validation.mimetype') }}
    </span>

    <span
      v-else-if="validation.required
        && !validation.isValidCertificateTypeChange
        && validation.isValidCertificateTypeChange != undefined"
      class="form-input-message"
    >{{ $t('solutions.update:feedback.error:invalid_certificate_type_change') }}
    </span>

    <span
      v-else-if="validation.required && validation.hasKeywordsFormat"
      class="form-input-message"
    >{{ $t('tracks.update:feedback.error:invalid_keywords_format') }}
    </span>

    <span
      v-else-if="validation.required && !validation.dateRange && validation.dateRange !== undefined"
      class="form-input-message"
    >{{ $t('global:validation.date.range') }}
    </span>

    <span
      v-else-if="validation.sameAs !== undefined && !validation.sameAs"
      class="validation-message"
    >{{ $t('global:validation.same.as') }}
    </span>

    <span
      v-else-if="validation.phoneValidation !== undefined && !validation.phoneValidation"
      class="validation-message"
    >{{ $t('global.validation.phone') }}
    </span>

    <span
      v-else-if="validation.required && !validation.dateBefore && validation.dateBefore !== undefined"
      class="form-input-message"
    >{{ $t('global:validation.date.range.before') }}
    </span>

    <span
      v-else-if="validation.required && !validation.dateAfter && validation.dateAfter !== undefined"
      class="form-input-message"
    >{{ $t('global:validation.date.range.after') }}
    </span>

    <span
      v-else-if="!validation.cnpjValidation && validation.cnpjValidation !== undefined"
      class="form-input-message"
    >{{ $t('global.validation.cnpjValidation') }}
    </span>

    <span
      v-else-if="!validation.regexSlug && validation.regexSlug !== undefined"
      class="form-input-message"
    >{{ $t('global.validation.regexSlug') }}
    </span>

    <span
      v-else-if="!validation.slugDuplicate && validation.slugDuplicate !== undefined"
      class="form-input-message"
    >{{ $t('global.validation.slugDuplicate') }}
    </span>

    <span
      v-else-if="!validation.titleDuplicate && validation.titleDuplicate !== undefined"
      class="form-input-message"
    >{{ $t('global.validation.title.duplicate') }}
    </span>

    <span
      v-else-if="!validation.aliasDuplicate && validation.aliasDuplicate !== undefined"
      class="form-input-message"
    >{{ $t('global.validation.alias.duplicate') }}
    </span>

    <span
      v-else-if="!validation.systemEmailValidTitle && validation.systemEmailValidTitle !== undefined"
      class="form-input-message"
    >{{ $t('global.validation.systemEmailValidTitle') }}
    </span>

    <span
      v-else-if="!validation.systemEmailValidBody && validation.systemEmailValidBody !== undefined"
      class="form-input-message"
    >{{ $t('global.validation.systemEmailValidBody') }}
    </span>

    <span
      v-else-if="validation.fileValidation !== undefined && !validation.fileValidation"
      class="validation-message"
    >{{ $t('global.validation.file') }}
    </span>

    <span
      v-else-if="!validation.regexPassword && validation.regexPassword !== undefined"
      class="form-input-message"
    >{{ $t('global.validation.regexPassword') }}
    </span>

    <span
      v-else-if="!validation.mediationPlanValidateTag && validation.mediationPlanValidateTag !== undefined"
      class="form-input-message"
    >
      {{ $t('global.validation.mediationPlanValidateTag') }}
    </span>

    <span
      v-else-if="!validation.maxSize && validation.maxSize !== undefined"
      class="form-input-message"
    >
      {{ $t('global.validation.mediationAttachmentMaxSize') }}
    </span>

    <span
      v-else-if="!validation.shouldBeInRange && validation.shouldBeInRange !== undefined"
      class="form-input-message"
    >
      {{ $t('global.validation.shouldBeInRange') }}
    </span>

    <span
      v-else-if="!validation.hexColorValidate && validation.hexColorValidate !== undefined"
      class="form-input-message"
    >
      {{ $t('global.validation.hex-color-validation') }}

    </span>

    <span
      v-else-if="!validation.urlYoutubeVimeoValidator && validation.urlYoutubeVimeoValidator !== undefined"
      class="form-input-message"
    >
      {{ $t('global:validation.urlYoutubeVimeoValidator') }}
    </span>

    <span
      v-else-if="!validation.vimeoOnly && validation.vimeoOnly !== undefined"
      class="form-input-message"
    >
      {{ $t('global:validation.vimeoOnly') }}
    </span>

    <span
      v-else-if="!validation.youtubeOnly && validation.youtubeOnly !== undefined"
      class="form-input-message"
    >
      {{ $t('global:validation.youtubeOnly') }}
    </span>
  </div>
</template>
