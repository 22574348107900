<script>
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ToolsManage',
  components: {
    Action: () => import('@/components/general/Action'),
    ActionBar: () => import('@/components/general/ActionBar'),
    InputField: () => import('@/components/general/InputField'),
    ContentHeader: () => import('@/components/general/ContentHeader')
  },

  data () {
    return {
      formData: {
        id: null,
        title: null,
        callText: null,
        active: true
      },

      backUrl: { name: 'tools.type_tools.index' },
      themeList: [],
      isTitleDuplicate: false
    }
  },

  validations () {
    return {
      formData: {
        title: {
          required,
          titleDuplicate: () => {
            return !this.isTitleDuplicate
          }
        },

        callText: { required }
      }
    }
  },

  computed: {
    isEditing () {
      return this.$route.params.id || this.formData.id
    }
  },

  watch: {
    'formData.title' () {
      if (!this.isTitleDuplicate) return
      this.isTitleDuplicate = false
    }
  },

  created () {
    if (this.$route.params.id) {
      this.setFetching(true)

      this.attemptManageTypeTool(this.$route.params.id)
        .then(() => {
          this.formData = {
            ...this.formData,
            ...this.$store.getters.getManageTypeTool
          }
        })
        .catch(() => {
          this.setFeedback({ message: this.$t('type_tool.load:fetch.error') })
          this.$router.push(this.backUrl)
        }).finally(() => {
          this.setFetching(false)
        })
    }
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptSaveTypeTool',
      'attemptManageTypeTool',
      'attemptUpdateTypeTool'
    ]),

    formatFormData () {
      const data = {}

      data.id = this.formData.id
      data.title = this.formData.title
      data.callText = this.formData.callText
      data.active = this.formData.id ? this.formData.active : true

      return data
    },

    submitCreation () {
      this.setFetching(true)

      return this.attemptSaveTypeTool(this.formatFormData())
        .then(({ data }) => {
          this.formData.id = data.id
          this.setFeedback({ message: this.$t('type_tool:submit.create.success') })
          this.$router.push(this.backUrl)
        }).catch(({ data }) => {
          if (data.error.message === 'tool_type_title_already_in_use') {
            this.setFeedback({ message: this.$t('type_tool:submit.create.title.already.exists.error') })

            this.isTitleDuplicate = true
            this.$v.formData.slug.$touch()
          } else {
            this.setFeedback({ message: this.$t('type_tool:submit.create.error') })
          }
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    submitUpdate () {
      this.setFetching(true)

      return this.attemptUpdateTypeTool(this.formatFormData())
        .then(() => {
          this.setFeedback({ message: this.$t('type_tool:submit.update.success') })
          this.$router.push(this.backUrl)
        }).catch(({ data }) => {
          if (data.error.message === 'tool_type_title_already_in_use') {
            this.setFeedback({ message: this.$t('type_tool:submit.create.title.already.exists.error') })

            this.isTitleDuplicate = true
            this.$v.formData.slug.$touch()
          } else {
            this.setFeedback({ message: this.$t('type_tool:submit.update.error') })
          }
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    submit () {
      return new Promise((resolve, reject) => {
        this.$v.$touch()

        if (!this.$v.$invalid) {
          resolve(true)

          return this.isEditing ? this.submitUpdate() : this.submitCreation()
        } else {
          this.setFeedback({ message: this.$t('thematicPages:feedback.validation.error') })
          reject(new Error('Error'))
        }
      })
    },

    leave () {
      this.$nextTick(() => {
        this.$router.push(this.backUrl)
      })
    }
  }
}
</script>

<template>
  <div class="main-content thematic-pages-create">
    <ContentHeader
      :title="isEditing ? formData.name : $t('type_tool.create:header.title')"
      dark-theme
    >
      <Action
        slot="back"
        type="button"
        :text="$t('global:back.type_tool')"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave()"
      />
      <ActionBar slot="actionbar" />
      <template slot="buttons">
        <Action
          :text="$t('global:form.save')"
          type="button"
          flat
          dark
          @click="submit()"
        />
      </template>
    </ContentHeader>
    <div
      class="main-content-inner"
    >
      <div class="center">
        <div class="thematic-pages-create-header">
          <p
            class="thematic-pages-create-description"
            v-html="$t('type_tool.create:header.description')"
          />
        </div>
        <form
          class="form"
          @submit.prevent="submit()"
        >
          <InputField
            v-model="formData.title"
            :label="$t('global:form.type_tool.name')"
            :validation="$v.formData.title"
            :counter="100"
            :under-description="$t('global:form.type_tool.name.under-description')"
          />

          <InputField
            v-model="formData.callText"
            :label="$t('global:form.tracks.callText')"
            :validation="$v.formData.callText"
            :counter="250"
            :under-description="$t('global:form.type_tool.callText.under-description')"
          />
        </form>
      </div>
    </div>
  </div>
</template>

<style>
.thematic-pages-create .form {
  max-width: 480px;
  margin: 0 auto;
}
.thematic-pages-create-header {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 40px;
}
.thematic-pages-create-title {
  font-family: 'Quicksand';
  font-size: 2.4em;
  font-weight: bold;
  color: #005EB8;
  line-height: 30px;
  margin-bottom: 5px;
}

.thematic-pages-create-description {
  font-family: 'Quicksand';
  font-size: 1.8em;
  color: #005EB8;
  line-height: 30px;
  margin-bottom: 0;
}

.thematic-pages-create-header + .center .tabs {
  display: flex;
  justify-content: center;
  margin: 65px 0 80px;
}

</style>
