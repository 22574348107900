<script>
import { mask } from 'vue-the-mask'
import { mapActions } from 'vuex'

export default {
  name: 'BatchList',
  directives: { mask },
  components: {
    Modal: () => import('@/components/general/Modal'),
    Action: () => import('@/components/general/Action'),
    Datatable: () => import('@/components/general/Datatable'),
    Pagination: () => import('@/components/general/Pagination')
  },

  data () {
    return {
      pagination: {
        query: {},
        order: { created_at: 'DESC' },
        page: 1,
        lastPage: null,
        limit: null
      },

      batchList: []
    }
  },

  watch: {
    'pagination.page' () {
      this.getBatchList()
    }
  },

  created () {
    this.getBatchList()
  },

  methods: {
    ...mapActions([
      'attemptGetBatchList',
      'setFetching',
      'setFeedback'
    ]),

    prevPage () {
      if (this.pagination.page > 1) {
        this.pagination.page -= 1
      }
    },

    nextPage () {
      this.pagination.page += 1
    },

    firstPage () {
      this.pagination.page = 1
    },

    lastPage () {
      this.pagination.page = this.pagination.lastPage
    },

    getBatchList () {
      this.setFetching(true)

      this.attemptGetBatchList(this.pagination).then((response) => {
        this.batchList = response.data
        this.pagination.lastPage = response.lastPage
      }).finally(() => {
        this.setFetching(false)
      })
    },

    dateFormat (datetime) {
      if (datetime === '' || datetime === undefined || datetime === null) {
        return datetime
      }

      const date = new Date(datetime)

      return Intl.DateTimeFormat('pt-BR').format(date)
    },

    leave () {
      this.$router.push({ name: 'users.index' })
    },

    createNewUserBatch () {
      this.$router.push({
        name: 'users.batch.manage',
        params: { origin: 'batch.list' }
      })
    },

    createNewEnrollmentBatch () {
      this.$router.push({
        name: 'enrollment.batch.manage',
        params: { origin: 'batch.list' }
      })
    },

    batchDetails (batch) {
      this.$router.push({
        name: 'batch.details',
        params: {
          id: batch.id,
          type: batch.batchContext
        }
      })
    }
  }
}
</script>

<template>
  <Modal
    :active="true"
    @close="leave"
  >
    <div class="modal-form modal-add-user">
      <h2 class="modal-title">
        {{ $t('user.management.index:modal.title.batch.list') }}
      </h2>
      <span class="modal-subtitle">{{ $t('user.management.index:modal.description.batch.list') }}</span>
      <div
        v-if="batchList.length > 0"
        class="center users-table"
      >
        <div class="actions">
          <Action
            type="button"
            :text="$t('users.management:add.new.student.batch')"
            primary
            small
            dark
            @click="createNewUserBatch()"
          />
          <Action
            type="button"
            :text="$t('users.management:add.new.enrollment.batch')"
            primary
            small
            dark
            @click="createNewEnrollmentBatch()"
          />
        </div>
        <Datatable
          :items="batchList"
          dark
        >
          <template slot="thead">
            <tr>
              <th
                width="320"
                class="th"
              >
                {{ $t('batch.list:datatable.header.1') }}
              </th>
              <th class="th">
                {{ $t('batch.list:datatable.header.2') }}
              </th>
              <th class="th">
                {{ $t('batch.list:datatable.header.3') }}
              </th>
              <th class="th">
                {{ $t('batch.list:datatable.header.4') }}
              </th>
              <th class="th text-center">
                {{ $t('batch.list:datatable.header.5') }}
              </th>
              <th class="th text-center">
                {{ $t('batch.list:datatable.header.6') }}
              </th>
              <th class="th text-center">
                {{ $t('batch.list:datatable.header.7') }}
              </th>
            </tr>
          </template>
          <template
            slot="tbody"
            slot-scope="props"
          >
            <tr>
              <td
                width="320"
                class="td"
              >
                <span class="td-text bolder td-block">{{ props.item.fileOriginalName }}</span>
              </td>
              <td class="td">
                <span class="td-text">{{ $t('batch.list.context:' + props.item.batchContext) }}</span>
              </td>
              <td class="td">
                <span class="td-text">{{ $t('batch.list.status:' + props.item.status) }}</span>
              </td>
              <td class="td">
                <span class="td-text">{{ dateFormat(props.item.createdAt) }}</span>
              </td>
              <td class="td text-center">
                <span class="td-text">{{ props.item.processed }}</span>
              </td>
              <td class="td text-center">
                <span class="td-text">{{ props.item.failed }}</span>
              </td>
              <td class="td text-center">
                <span class="td-text">{{ props.item.total }}</span>
              </td>
              <td class="td">
                <Action
                  type="button"
                  :text="$t('batch.list:btn.details')"
                  borderless
                  small
                  dark
                  @click="batchDetails(props.item)"
                />
              </td>
            </tr>
          </template>
        </Datatable>
        <Pagination
          :active-page="pagination.page"
          :page-count="pagination.lastPage"
          dark
          @firstPage="firstPage"
          @lastPage="lastPage"
          @nextPage="nextPage"
          @previousPage="prevPage"
        />
      </div>
    </div>
  </Modal>
</template>

<style>
.actions {
  margin-top: 80px;
}
.actions .btn {
  margin-right: 10px;
}
</style>
