<script>
import { mapActions } from 'vuex'
import { format } from 'date-fns'

export default {
  name: 'Comments',
  components: {
    Action: () => import('@/components/general/Action'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    FilterList: () => import('@/components/general/FilterList'),
    FilterListSearch: () => import('@/components/general/FilterListSearch'),
    Dropdown: () => import('@/components/general/Dropdown'),
    Datatable: () => import('@/components/general/Datatable'),
    Pagination: () => import('@/components/general/Pagination'),
    DropdownLink: () => import('@/components/general/DropdownLink'),
    Modal: () => import('@/components/general/Modal'),
    SelectField: () => import('@/components/general/SelectField'),
    Datepicker: () => import('@/components/general/Datepicker')
  },

  data () {
    return {
      commentsList: [],

      pagination: {
        filter: {},
        page: 1,
        limit: 12,
        lastPage: null
      },

      filteredFormLabels: {
        status: [
          {
            text: this.$t('comments:status.approved'),
            value: 'approved'
          },
          {
            text: this.$t('comments:status.disapproved'),
            value: 'disapproved'
          },
          {
            text: this.$t('comments:status.pending'),
            value: 'pending'
          }
        ],

        contentType: [
          {
            text: this.$t('notifications.filters.manage:field.option.program'),
            value: 'program'
          },
          {
            text: this.$t('notifications.filters.manage:field.option.solution'),
            value: 'solution'
          }
        ]
      },

      query: {},
      filter: {
        status: null,
        type: null,
        start_date: null,
        end_date: null
      },

      modalCommentsFiltersOpen: false
    }
  },

  computed: {
    canWrite () {
      return this.getContextPermission('comments') === 'write'
    }
  },

  created () {
    this.getCommentsList()
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetCommentsList',
      'attemptApproveComment',
      'attemptDisapproveComment'
    ]),

    getCommentsList () {
      this.setFetching(true)

      this.attemptGetCommentsList(this.pagination).then(pagination => {
        this.pagination.lastPage = pagination.data.lastPage

        this.commentsList = pagination.data.data.map((commnet) => {
          return {
            ...commnet,
            show: false
          }
        })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    disapproveComment (idComment) {
      this.attemptDisapproveComment(idComment).then(() => {
        this.getCommentsList()
        this.setFeedback({ message: this.$t('comment:feedback.disapproved.success') })
      }).catch(() => {
        this.setFeedback({ message: this.$t('comment:feedback.disapproved.error') })
      })
    },

    approveComment (idComment) {
      this.attemptApproveComment(idComment).then(() => {
        this.getCommentsList()
        this.setFeedback({ message: this.$t('comment:feedback.approved.success') })
      }).catch(() => {
        this.setFeedback({ message: this.$t('comment:feedback.approved.error') })
      })
    },

    formatDate (date) {
      const dateFormatted = format(
        new Date(date),
        'dd/MM/yyyy'
      )

      return dateFormatted
    },

    prevPage () {
      if (this.pagination.page > 1) {
        this.pagination.page -= 1
        this.getCommentsList()
      }
    },

    nextPage () {
      this.pagination.page += 1
      this.getCommentsList()
    },

    firstPage () {
      this.pagination.page = 1
      this.getCommentsList()
    },

    lastPage () {
      this.pagination.page = this.pagination.lastPage
      this.getCommentsList()
    },

    toFilter () {
      Object.keys(this.filter).map((key) => {
        if (this.filter[key]) {
          this.pagination.filter[key] = this.filter[key]
        }

        return key
      })

      this.pagination.page = 1

      this.closeFilterModal()
      this.getCommentsList()
    },

    openFilterModal () {
      this.modalCommentsFiltersOpen = true
    },

    closeFilterModal () {
      this.modalCommentsFiltersOpen = false
    },

    clearFilters () {
      this.filter = {
        status: null,
        type: null,
        start_date: null,
        end_date: null
      }

      this.pagination.filter = {}
      this.closeFilterModal()
      this.getCommentsList()
    },

    searchSession (search) {
      this.pagination.query = {
        comments_name: search
      }

      this.pagination.page = 1
      this.getCommentsList()
    },

    toggleShowContent (id) {
      const findIndex = this.commentsList.findIndex(item => item.id === id)

      this.commentsList[findIndex].show = !this.commentsList[findIndex].show
    }

  }
}
</script>

<template>
  <div class="main-content">
    <ContentHeader
      :title="$t('comments:header.title')"
      :description="$t('comments:header.description')"
      background="/assets/images/themes/default/png/users_banner.png"
    />
    <FilterList>
      <Action
        v-if="canWrite"
        slot="button"
        flat
        fixed-width
        type="button"
        :text="$t('sessions.list:btn.filter')"
        @click="openFilterModal"
      />

      <FilterListSearch
        slot="search"
        :hide-error-details="true"
        @search="searchSession"
      />
    </FilterList>
    <div
      v-if="commentsList.length > 0"
      class="center solutions-table"
    >
      <Datatable :items="commentsList">
        <template slot="thead">
          <tr>
            <th
              class="th"
              width="20%"
            >
              {{ $t('comments:datatable.header.1') }}
            </th>
            <th
              class="th"
              width="10%"
            >
              {{ $t('comments:datatable.header.2') }}
            </th>
            <th
              class="th"
              width="10%"
            >
              {{ $t('comments:datatable.header.3') }}
            </th>
            <th
              class="th text-center"
              width="15%"
            >
              {{ $t('comments:datatable.header.4') }}
            </th>
            <th
              class="th text-center"
              width="25%"
            >
              {{ $t('comments:datatable.header.5') }}
            </th>
            <th
              class="th text-center"
              width="15%"
            >
              {{ $t('comments:datatable.header.6') }}
            </th>
            <th
              class="th"
              width="40"
            />
          </tr>
        </template>
        <template
          slot="tbody"
          slot-scope="props"
        >
          <tr>
            <td class="td">
              <span class="td-text bolder">{{ props.item.title }}</span>
            </td>
            <td class="td">
              <span class="td-text">{{ $t('global:form.'+ props.item.type) }}</span>
            </td>

            <td class="td">
              <span class="td-text">{{ props.item.user.username }}</span>
            </td>

            <td class="td text-center">
              <span class="td-text">{{ formatDate(props.item.createdAt) }}</span>
            </td>
            <td class="td text-center">
              <div class="td-content">
                <span
                  :class="{'is-open': props.item.show}"
                  class="td-text content-hide"
                >
                  {{ props.item.comment }}
                </span>
                <Action
                  slot="button"
                  flat
                  tiny
                  icon-right
                  :icon="props.item.show ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
                  fixed-width
                  type="button"
                  :text="props.item.show ? '':$t('global:visualize')"
                  @click="toggleShowContent(props.item.id)"
                />
              </div>
            </td>
            <td class="td text-center">
              <span
                v-if="props.item.status === 'approved'"
                class="td-tag active-ribbon"
              >{{ $t('comments:status.approved') }}</span>
              <span
                v-else-if="props.item.status === 'disapproved'"
                class="td-tag inactive-ribbon"
              >{{ $t('comments:status.disapproved') }}</span>
              <span
                v-else
                class="td-tag pending-ribbon"
              >{{ $t('comments:status.pending') }}</span>
            </td>
            <td
              class="td td-actions"
              width="40"
            >
              <Dropdown
                v-if="canWrite"
                icon="dots-vertical"
                right
              >
                <DropdownLink
                  v-if="props.item.status !== 'disapproved'"
                  :text="$t('comments:action.disapproved')"
                  @click="disapproveComment(props.item.id)"
                />
                <DropdownLink
                  v-if="props.item.status !== 'approved'"
                  :text="$t('comments:action.approved')"
                  @click="approveComment(props.item.id)"
                />
              </Dropdown>
            </td>
          </tr>
        </template>
      </Datatable>

      <Pagination
        :active-page="pagination.page"
        :page-count="pagination.lastPage"
        @firstPage="firstPage"
        @lastPage="lastPage"
        @nextPage="nextPage"
        @previousPage="prevPage"
      />
    </div>
    <div
      v-else
      class="center solutions-table"
    >
      <p>Nenhum comentário encontrado para o termo pesquisado</p>
    </div>
    <Modal
      class="modal-sessions-filters"
      :active="modalCommentsFiltersOpen"
      :cancel="true"
      @close="closeFilterModal"
    >
      <div class="modal-form modal-sessions-filters">
        <h2 class="modal-title">
          {{ $t('sessions.list:filter.title') }}
        </h2>
        <span class="modal-subtitle">{{ $t('sessions.list:filter.description') }}</span>
        <form @submit.prevent="toFilter">
          <SelectField
            v-model="filter.status"
            class="sessions-solutions-field"
            dark
            :label="$t('comments:datatable.header.6')"
            :items="filteredFormLabels.status"
          />
          <SelectField
            v-model="filter.type"
            class="sessions-solutions-field"
            dark
            :label="$t('comments:datatable.header.2')"
            :items="filteredFormLabels.contentType"
          />

          <Datepicker
            v-model="filter.start_date"
            dark
            class="sessions-date"
            hide-optional-text
            :label="$t('sessions.list:start')"
            min="1900-01-01"
          />
          <Datepicker
            v-model="filter.end_date"
            dark
            class="sessions-date"
            hide-optional-text
            :label="$t('sessions.list:end')"
            min="1900-01-01"
          />

          <div class="form-buttons">
            <Action
              primary
              large
              dark
              fixed-width
              type="button"
              :text="$t('sessions.list:btn.clear.filter.action')"
              @click="clearFilters"
            />
            <Action
              secondary
              large
              dark
              submit
              fixed-width
              type="button"
              :text="$t('sessions.list:btn.filter.action')"
            />
          </div>
        </form>
      </div>
    </Modal>
    <RouterView />
  </div>
</template>

<style>
.datatable .text-center .td-content {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 64px;
}

.td-content .btn.has-fixed-width {
  min-width: 40px;
  padding-right: 0;
}

.content-hide {
  overflow: hidden;
  transition: all 0.4_s ease-in-out;
  height: 0;
  width: 0;
}

.content-hide.is-open.td-text {
  height: auto;
  width: auto;
  padding: 10px 0;
  word-break: break-word;
}

.form-buttons {
  display: flex;
  justify-content: space-around;
}
</style>
