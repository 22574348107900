const MediationPlanModule = () => import('./MediationPlanModule.vue')
const ListPage = () => import('./pages/ListPage.vue')
const AuditPage = () => import('./pages/AuditPage.vue')
const ManagePage = () => import('./pages/ManagePage.vue')

export const routes = [
  {
    path: '/notifications/mediation-plan',
    component: MediationPlanModule,
    children: [
      {
        path: '/',
        name: 'notifications.mediation-plan',
        component: ListPage
      },

      {
        path: 'audit/:id',
        name: 'notifications.mediation-plan.audit',
        component: AuditPage
      },

      {
        path: 'Manage/:id?',
        name: 'notifications.mediation-plan.manage',
        component: ManagePage
      }
    ]
  }
]
