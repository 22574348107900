<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  props: {
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    },
    drillOption: {
      default: null
    }
  },
  data () {
    return {
      selectedData: {}
    }
  },
  mounted () {
    setTimeout(() => {
      this.renderChart(this.chartData, {
        onClick: this.click,
        onHover: (_, dataset) => {
          if (!dataset.length) {
            this.selectedData = {}
          }
        },
        tooltips: {
          xPadding: 10,
          yPadding: 8,
          caretPadding: 15,
          bodyFontFamily: 'Roboto',
          titleFontFamily: 'Roboto',
          cornerRadius: 5,
          backgroundColor: 'rgba(120,120,120,.85)',
          mode: 'index',
          intersect: false,
          callbacks: {
            footer: (label, dataset) => {
              if (this.options.scales.xAxes[0].stacked) {
                this.selectedData.value = dataset.datasets[label[0].datasetIndex].label
                this.selectedData.groupment = dataset.labels[label[0].index].toLowerCase()
              } else {
                this.selectedData.groupment = label[0].label
              }
            }
          }
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: this.chartData ? this.chartData.datasets.length <= 5 : false,
          position: 'bottom',
          align: 'start',
          labels: {
            fontFamily: 'Roboto'
          },
          fill: true
        },
        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true
            }
          }],
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true
            }
          }]
        },
        ...this.options
      })
    }, 500)
  },
  methods: {
    click () {
      if (this.selectedData) {
        this.$emit('selection', { ...this.selectedData, type: this.drillOption })
      }
    }
  }
}
</script>
<style scoped>
  #bar-chart {
    height: 290px;
  }
</style>
