<script>
export default {
  name: 'FormSection',
  props: {
    title: {
      type: String,
      default: null
    },

    dark: {
      type: Boolean,
      default: false
    },

    blueTitle: {
      type: Boolean,
      default: false
    },

    uppercase: {
      type: Boolean,
      default: false
    },

    hasLine: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <div
    class="form-section"
    :class="{ 'theme-dark': dark }"
  >
    <p
      v-if="title"
      class="form-section-title"
      :class="{
        'blue': blueTitle,
        'is-uppercase': uppercase,
        'has-line': hasLine
      }"
    >
      {{ title }}
    </p>
    <hr
      v-if="hasLine"
      class="form-section-line"
    >
    <slot />
  </div>
</template>

<style src="@/assets/styles/themes/default/form.css"></style>
