<script>
export default {
  name: 'ModalConfirm',
  components: {
    Action: () => import('@/components/general/Action'),
    Modal: () => import('@/components/general/Modal')
  },

  props: {
    active: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      required: true
    },

    description: {
      type: String,
      default: null
    }
  },

  methods: {
    confirm () {
      this.$emit('confirmAction')
    },

    cancel () {
      this.$emit('cancelAction')
    }
  }
}
</script>

<template>
  <Modal
    :active="active"
    :cancel="false"
    @close="cancel"
  >
    <div class="modal-confirm">
      <div
        v-if="title"
        class="modal-confirm-content"
      >
        <h3 class="modal-confirm-title">
          {{ title }}
        </h3>
      </div>
      <div
        v-if="description"
        class="modal-confirm-description"
      >
        <p>{{ description }}</p>
      </div>
      <div class="modal-confirm-footer">
        <Action
          type="button"
          :text="$t('global:confirm')"
          flat
          class="btn-confirm"
          :dark="false"
          @click="confirm"
        />
        <Action
          type="button"
          :text="$t('global:cancel')"
          flat
          :dark="false"
          @click="cancel"
        />
      </div>
    </div>
  </Modal>
</template>
