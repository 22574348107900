<script>
import { mapState } from 'vuex'
import config from '@/config'

const { DEFAULT_CLASSROOM_MENU } = config

export default {
  name: 'VerticalMenu',
  components: {
    Action: () => import('@/components/general/Action'),
    InputField: () => import('@/components/general/InputField'),
    Logo: () => import('@/components/general/Logo'),
    VerticalMenuItem: () => import('@/components/general/VerticalMenuItem'),
    VerticalMenuSubItem: () => import('@/components/general/VerticalMenuSubItem')
  },

  props: {
    items: {
      type: Array,
      default () {
        return []
      }
    },

    classroom: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      expanded: true,
      icon: 'local_library',
      search: null,
      defaultMenuType: DEFAULT_CLASSROOM_MENU
    }
  },

  computed: {
    ...mapState([ 'Account' ])
  },

  methods: {
    expandMenu () {
      this.expanded = !this.expanded
      this.$emit('resize', this.expanded)
    },

    closeMenu () {
      this.$emit('close')
    },

    isParentActive (menuItem) {
      return this.$route && this.$route.name ? this.$route.name.startsWith(menuItem.link.name.split('.')[0]) : false
    }
  }
}
</script>

<template>
  <nav
    class="menu"
    :class="{ 'is-reduced': !expanded && $media.desktop, 'menu-classroom': classroom }"
  >
    <a
      v-if="!$media.mobile"
      href="#"
      class="menu-btn"
      @click.prevent="expandMenu()"
    >{{ expanded ? $t('global:menu.btn.close') : $t('global:menu.btn.open') }}</a>
    <div class="menu-header">
      <Logo :theme="$theme" />
      <p
        class="menu-company-headline"
        v-html="$t('vertical.menu:company.headline')"
      />
      <Action
        v-if="$media.mobile"
        type="button"
        icon="close"
        @click="closeMenu()"
      />
    </div>
    <div
      v-if="$media.mobile"
      class="menu-section"
    >
      <form class="menu-search-form">
        <InputField
          v-model="search"
          :label="$t('global:menu.search')"
          :floating-label="false"
          append-icon="search"
          dark
        />
      </form>
    </div>
    <template v-for="(section, index1) in items">
      <div
        v-if="typeof section.deniedProfile === 'undefined' || Account.user.currentProfile !== section.deniedProfile"
        :key="index1"
        class="menu-section"
        :class="{'menu-section-first': index1 === 0}"
      >
        <div
          v-if="section.title"
          class="menu-section-title-wrapper"
        >
          <h5 class="menu-section-title">
            {{ expanded ? $t(section.title.expanded) : $t(section.title.reduced) }}
          </h5>
        </div>
        <ul class="menu-list">
          <template
            v-for="(menuItem, index2) in section.items"
          >
            <VerticalMenuItem
              v-if="!menuItem.bottom && menuItem.enabled"
              :key="index2"
              :link="menuItem.link"
              :text="menuItem.text"
              :icon="menuItem.icon"
              :highlight="menuItem.highlight"
              :submenu="Array.isArray(menuItem.items)"
              :role="menuItem.role"
              :disabled="menuItem.disabled"
              :parent-active="isParentActive(menuItem)"
              :exact="menuItem.exact"
              :callback="menuItem.callback"
            >
              <VerticalMenuSubItem
                v-for="(menuSubItem, index3) in menuItem.items"
                :key="index3"
                :text="menuSubItem.text"
                :icon="menuSubItem.icon"
              />
            </VerticalMenuItem>
          </template>
        </ul>
        <ul class="bottom-list">
          <template
            v-for="(menuItem, index2) in section.items"
          >
            <VerticalMenuItem
              v-if="menuItem.bottom && menuItem.enabled"
              :key="index2"
              :link="menuItem.link"
              :text="menuItem.text"
              :icon="menuItem.icon"
              :highlight="menuItem.highlight"
              :submenu="Array.isArray(menuItem.items)"
              :role="menuItem.role"
              :disabled="menuItem.disabled"
              :exact="menuItem.exact"
              :callback="menuItem.callback"
            >
              <VerticalMenuSubItem
                v-for="(menuSubItem, index3) in menuItem.items"
                :key="index3"
                :text="menuSubItem.text"
                :icon="menuSubItem.icon"
              />
            </VerticalMenuItem>
          </template>
        </ul>
      </div>
    </template>
  </nav>
</template>

<style src="@/assets/styles/themes/default/verticalmenu.css"></style>
