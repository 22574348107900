<script>
import flatpickr from 'flatpickr'
import Languages from 'flatpickr/dist/l10n'
import { isAfter } from 'date-fns'

export default {
  name: 'Datepicker',
  components: {
    Action: () => import('@/components/general/Action'),
    Icon: () => import('@/components/general/Icon'),
    ValidationMessage: () => import('@/components/general/ValidationMessage')
  },

  props: {
    hint: {
      type: String,
      default: null
    },

    label: {
      type: String,
      default: null
    },

    floatingLabel: {
      type: Boolean,
      default: true
    },

    disabled: {
      type: Boolean,
      default: false
    },

    readonly: {
      type: Boolean,
      default: false
    },

    value: null,
    min: {
      type: [
        String,
        Date
      ],

      default: null
    },

    max: {
      type: [
        String,
        Date
      ],

      default: null
    },

    time: {
      type: Boolean,
      default: false
    },

    range: {
      type: Boolean,
      default: false
    },

    multiple: {
      type: Boolean,
      default: false
    },

    minutesInThirty: {
      type: Boolean
    },

    options: {
      type: Object,
      default: () => { return {} }
    },

    validation: {
      type: Object,
      default: () => { return {} }
    },

    dark: {
      type: Boolean,
      default: false
    },

    hideOptionalText: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      datepicker: null,
      isFocused: false,
      dateFormated: null,
      elemInViewport: true,
      valueInside: false
    }
  },

  computed: {
    hasValue () {
      return this.value && this.value.toString().length > 0
    },

    isRequired () {
      return this.validation.$params && typeof this.validation.$params.required === 'object'
    }
  },

  watch: {
    value (value) {
      this.datepicker.setDate(value, true)
      this.valueInside = false
    },

    min (value) {
      const minDate = value.constructor.toString().indexOf('Date') > -1 ? value : new Date(value.replace(/-/g, '/').replace(/T.+/, ''))

      minDate.setDate(minDate.getDate())

      if (isAfter(minDate, this.value)) {
        this.datepicker.clear()
      }

      this.datepicker.set('minDate', minDate)

      if (typeof this.validation.$reset === 'function') {
        this.validation.$reset()
      }
    },

    max (value) {
      this.datepicker.set('maxDate', value)
    },

    disabled () {
      this.disabled ? this.datepicker._input.setAttribute('disabled', 'disabled') : this.datepicker._input.removeAttribute('disabled')
    }
  },

  mounted () {
    const altFormat = this.time ? 'd/m/Y H:i' : 'd/m/Y'
    const options = Object.assign({
      defaultDate: this.value,
      mode: this.range ? 'range' : this.multiple ? 'multiple' : 'single',
      time_24hr: true,
      enableTime: this.time,
      enableSeconds: false,
      altInput: true,
      allowInput: false,
      defaultMinute: 0,
      minuteIncrement: this.getMinuteIncrement(),
      altInputClass: 'flatpickr-input',
      altFormat: altFormat,
      maxDate: this.max,
      minDate: this.min,
      disableMobile: true,
      appendTo: this.$refs.calendar,
      locale: Languages[this.$i18n.locale.match(/\w+/)[0]],
      nextArrow: '<svg viewBox="0 0 24 24" class="icon"><path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" /></svg>',
      prevArrow: '<svg viewBox="0 0 24 24" class="icon"><path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" /></svg>',
      onChange: (selectedDates, dateStr, instance) => {
        if (selectedDates.length) {
          if (this.minutesInThirty) {
            if (process.env.NODE_ENV !== 'test') {
              let minutesValue = selectedDates[0].getMinutes()

              minutesValue = minutesValue <= 15 ? 0 : minutesValue >= 45 ? 0 : 30
              selectedDates[0].setMinutes(minutesValue)
            }

            const formattedDate = instance.formatDate(selectedDates[0], 'Y-m-d H:i')

            this.datepicker.setDate(formattedDate)
          }

          this.dateFormated = instance.formatDate(selectedDates[0], 'l, d \\de F, Y')
        }
      },
      onOpen: () => {
        this.isFocused = true
        this.elemInViewport = this.inViewport(this.$el)
      },
      onClose: () => {
        this.isFocused = false
      }
    }, this.options)

    this.datepicker = flatpickr(this.$refs.input, options)
    this.elemInViewport = this.inViewport(this.$el)

    if (this.value) {
      this.datepicker.setDate(this.value, true)
    } else {
      this.dateFormated = this.datepicker.formatDate(new Date(), 'l, d \\de F, Y')
    }
  },

  beforeDestroy () {
    this.datepicker && this.datepicker.destroy()
  },

  methods: {
    updateValue (value) {
      typeof this.validation.$touch === 'function' && this.validation.$touch()
      this.valueInside = true
      this.$emit('input', value)
    },

    inViewport (elem) {
      return window.innerHeight - elem.getBoundingClientRect().bottom > 300
    },

    openDatepicker () {
      this.datepicker.altInput.focus()
    },

    closeDatepicker () {
      this.isFocused = false
    },

    getMinuteIncrement () {
      return this.minutesInThirty && process.env.NODE_ENV !== 'test' ? 30 : 1
    }
  }
}
</script>

<template>
  <div
    class="form-item datepicker has-append-icon"
    :class="{ 'has-error': validation.$error, 'has-value': hasValue, 'has-focus': isFocused, 'is-disabled': disabled, 'has-floating-label': floatingLabel, 'theme-dark': dark }"
  >
    <label
      v-if="label"
      class="form-label"
      @click.prevent="openDatepicker"
    >{{ label }} <span v-if="!isRequired && !disabled && !hideOptionalText">{{ $t('global:form.optional') }}</span></label>
    <div class="form-input-wrapper">
      <input
        ref="input"
        class="form-input"
        spellcheck="false"
        type="text"
        :disabled="disabled"
        :readonly="readonly"
        :value="value"
        @input="updateValue($event.target.value)"
      >
      <div
        class="form-input-append"
        @click="openDatepicker"
      >
        <Icon
          name="calendar-range"
          wrapper
        />
      </div>
    </div>
    <div class="form-input-details">
      <span
        v-if="hint && !validation.$error"
        class="form-input-hint"
      >{{ hint }}</span>
      <ValidationMessage :validation="validation" />
    </div>
    <div
      v-show="isFocused"
      class="form-input-datepicker"
      :class="{ 'open-up': !elemInViewport, 'has-time': time }"
      tabindex="0"
    >
      <div class="form-input-datepicker-inner">
        <div class="form-input-datepicker-header">
          <span class="form-input-datepicker-label">{{ label }}</span>
          <span class="form-input-datepicker-date">{{ dateFormated }}</span>
        </div>
        <div class="form-input-datepicker-container">
          <div
            ref="calendar"
            class="form-input-datepicker-calendar"
          />
          <Action
            v-if="time"
            class="form-input-datepicker-btn"
            type="button"
            :text="$t('global:select')"
            flat
            :disabled="!hasValue"
            dark
            @click="closeDatepicker"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/form.css"></style>

<style lang="scss" src="@/assets/styles/themes/default/datepicker.scss"></style>
