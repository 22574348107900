import { http } from '@/support/http'
import { createQuery } from '@/support/utils/paginatorQueryBuilder'
import { parseToFormDataWithArray, parseToFormDataWithArrayExtraFields } from '@/support/payloadParser'

export const listCertificates = (payload) => {
  const queryString = createQuery(payload)

  return http.get(`api/certificates?${queryString}`, payload)
}

export const listSolutionsCertificate = (idCertificate) => {
  return http.get(`api/certificate/${idCertificate}/solutions`)
}

export const listLearningPathsCertificate = (idCertificate) => {
  return http.get(`api/certificate/${idCertificate}/learning_paths`)
}

export const saveCertificate = (payload) => {
  const form = parseToFormDataWithArray(payload)

  if (payload.image_front && typeof payload.image_front === 'object') {
    form.append('image_front', payload.image_front, payload.image_front.name)
  }

  if (payload.image_back && typeof payload.image_back === 'object') {
    form.append('image_back', payload.image_back, payload.image_back.name)
  }

  return http.post('api/certificate', form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const updateCertificate = (payload) => {
  const form = parseToFormDataWithArray(payload)

  if (payload.image_front && typeof payload.image_front === 'object') {
    form.append('image_front', payload.image_front, payload.image_front.name)
  }

  if (payload.image_back && typeof payload.image_back === 'object') {
    form.append('image_back', payload.image_back, payload.image_back.name)
  }

  return http.post(`api/certificate/${payload.id}`, form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const getCertificateManagement = (payload) => {
  return http.get(`api/certificate/${payload}/manage`)
}

export const toggleCertificateActive = (payload) => {
  return http.post(`api/certificate/${payload}/toggle`)
}

export const getPreviewCertificate = (payload) => {
  return http.get(
    `/api/certificate/${payload}/preview`,
    {
      responseType: 'blob',
      headers: { 'Content-Type': 'application/pdf' },
      data: {}
    }
  )
}

export const listEntrepreneurProfiles = (payload) => {
  const queryString = createQuery(payload)

  return http.get(`api/entrepreneur-profile?${queryString}`, payload)
}

export const saveEntrepreneurProfile = (payload) => {
  const form = parseToFormDataWithArrayExtraFields(payload)

  if (payload.banner && typeof payload.banner === 'object') {
    form.append('banner', payload.banner, payload.banner.name)
  }

  if (payload.cardImage && typeof payload.cardImage === 'object') {
    form.append('cardImage', payload.cardImage, payload.cardImage.name)
  }

  return http.post('api/entrepreneur-profile/create', form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const editEntrepreneurProfiles = (payload) => {
  const form = parseToFormDataWithArrayExtraFields(payload)

  if (payload.banner && typeof payload.banner === 'object') {
    form.append('banner', payload.banner, payload.name)
  }

  if (payload.cardImage && typeof payload.cardImage === 'object') {
    form.append('cardImage', payload.cardImage, payload.slug)
  }

  return http.post(`api/entrepreneur-profile/edit/${payload.id}`, form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const toggleActiveEntrepreneurProfiles = (id) => {
  return http.put(`api/entrepreneur-profile/toggle/active/${id}`)
}

export const listThemes = (payload) => {
  const queryString = createQuery(payload)

  return http.get(`api/theme?${queryString}`, payload)
}

export const createTheme = (payload) => {
  const form = parseToFormDataWithArray(payload)

  if (payload.banner && typeof payload.banner === 'object') {
    form.append('banner', payload.banner, payload.banner.name)
  }

  if (payload.cardImage && typeof payload.cardImage === 'object') {
    form.append('cardImage', payload.cardImage, payload.cardImage.name)
  }

  return http.post('api/theme', form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const updateTheme = (payload) => {
  const form = parseToFormDataWithArray(payload)

  if (payload.banner && typeof payload.banner === 'object') {
    form.append('banner', payload.banner, payload.name)
  }

  if (payload.cardImage && typeof payload.cardImage === 'object') {
    form.append('cardImage', payload.cardImage, payload.slug)
  }

  return http.post(`api/theme/${payload.id}`, form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const updateFeaturedTheme = (payload) => {
  return http.put(`api/theme/${payload.id}/featured`, payload)
}

export const updateFeaturedEntrepreneurProfile = (payload) => {
  return http.put(`api/entrepreneur-profile/${payload.id}/featured`, payload)
}

export const toggleTheme = (id) => {
  return http.post(`api/theme/${id}/toggle`)
}

export const listSurveys = (payload) => {
  const queryString = createQuery(payload)

  return http.get(`api/survey?${queryString}`, payload)
}

export const listSolutionsSurvey = (idSurvey) => {
  return http.get(`api/survey/${idSurvey}/solutions`)
}

export const getSurvey = (id) => {
  return http.get(`api/survey/${id}`)
}

export const getSurveyBySlug = (slug) => {
  return http.get(`showcase/survey/${slug}`)
}

export const createSurvey = (payload) => {
  return http.post('api/survey', payload)
}

export const updateSurvey = (payload) => {
  return http.post(`api/survey/${payload.id}`, payload)
}

export const toggleSurvey = (id) => {
  return http.post(`api/survey/${id}/toggle`)
}

export const listProfiles = (payload) => {
  const query = createQuery(payload)

  return http.get(`api/profiles?${query}`)
}

export const getProfile = (payload) => {
  return http.get(`api/profile/${payload}/manage`)
}

export const createProfile = (payload) => {
  return http.post('api/profile', payload)
}

export const updateProfile = (payload) => {
  return http.post(`api/profile/${payload.id}`, payload)
}

export const toggleProfile = (id) => {
  return http.post(`api/profile/${id}/toggle`)
}

export const saveMsTeamsToken = (code) => {
  return http.post('api/dashboard/msteams', { code: code })
}

export const pingServiceIntegration = (alias) => {
  return http.post('api/integrations/ping', { alias: alias })
}

export const getGeneralSettings = () => {
  return http.get('api/configuration')
}

export const saveGeneralSettings = (settings) => {
  return http.post('api/configuration', settings)
}

export const getFinancialSettings = () => {
  return http.get('api/financial/configuration')
}

export const saveFinancialSettings = (settings) => {
  return http.post('api/financial/configuration', settings)
}

export const listProgramsSurvey = (idSurvey) => {
  return http.get(`api/survey/${idSurvey}/programs`)
}

export const getERList = (payload) => {
  const queryString = createQuery(payload)

  return http.get(`api/rm_integration?${queryString}`)
}

export const saveERList = (settings) => {
  return http.post('api/rm_integration', settings)
}
