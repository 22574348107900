<script>
import managementMixin from '@/modules/Management/managementMixin'

export default {
  name: 'Gamification',
  components: {
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Action: () => import('@/components/general/Action'),
    FilterList: () => import('@/components/general/FilterList'),
    Tabs: () => import('@/components/general/Tabs')
  },

  mixins: [ managementMixin ],

  data () {
    return {
      gamificationTabs: [
        {
          location: { name: 'management.gamification.ranking' },
          text: this.$t('management.gamification:ranking')
        },
        {
          location: { name: 'management.gamification.level' },
          text: this.$t('management.gamification:level')
        },
        {
          location: { name: 'management.gamification.medal' },
          text: this.$t('management.gamification:medal')
        },
        {
          location: { name: 'management.gamification.points.rules' },
          text: this.$t('management.gamification:points.rules')
        },
        {
          location: { name: 'management.gamification.rewards' },
          text: this.$t('management.gamification:rewards')
        }
      ]
    }
  },

  methods: {
    accessPlatform () {
      console.log('Redirecionar usuário para algum lugar ¯\\_(ツ)_/¯')
    }
  }
}
</script>

<template>
  <div class="gamification main-content">
    <ContentHeader
      :title="$t('management:header.title')"
      :description="$t('management:header.description')"
      background="/assets/images/themes/default/png/management_banner.png"
    >
      <Tabs
        slot="tabs"
        dark
        :links="tabLinks"
      />
    </ContentHeader>

    <FilterList>
      <Tabs
        slot="button"
        class="gamification-tabs"
        :links="gamificationTabs"
      />

      <Action
        v-if="false"
        slot="search"
        type="button"
        primary
        large
        fixed-width
        :text="$t('management.gamification:access.platform')"
        @click="accessPlatform"
      />
    </FilterList>

    <div class="gamification-container">
      <RouterView />
    </div>
  </div>
</template>

<style>
.gamification-tabs {
  display: inline-block;
  margin: 20px 0;
}

.gamification-container {
  padding: 0 20px;
}
</style>
