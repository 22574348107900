<script>
export default {
  name: 'IconPicker',
  components: {
    ValidationMessage: () => import('@/components/general/ValidationMessage')
  },

  props: {
    value: null,

    options: {
      type: Array,
      default: null
    },

    context: {
      type: String,
      default: null
    },

    label: {
      type: String,
      default: null
    },

    dark: {
      type: Boolean,
      default: false
    },

    validation: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },

  computed: {
    hasValidation () {
      return Object.keys(this.validation).length
    }
  },

  methods: {
    updateValue (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<template>
  <div
    class="color-picker"
    :class="{'has-value': value, 'has-floating-label': label, 'theme-dark': dark, 'has-validation': hasValidation, 'has-error': validation.$error}"
  >
    <label
      v-if="label"
      class="form-label"
    >
      {{ label }}
    </label>
    <div class="picker-item-list">
      <div
        v-for="(radioOption, radioIndex) in options"
        :key="radioIndex"
        class="picker-item"
        :class="{'is-checked': context + radioOption === value}"
      >
        <input
          :style="`background-image: url(${context}${radioOption})`"
          class="radio-option"
          :value="value"
          type="radio"
          @input="updateValue(context + radioOption)"
        >
      </div>
    </div>
    <ValidationMessage :validation="validation" />
  </div>
</template>

<style lang="scss" scoped>
.color-picker {
  width: 100%;
  display: flex;
  padding: 8px 0;
  margin-top: 24px;
  flex-direction: column;
  justify-content: flex-start;

  &.has-floating-label .form-label {
    margin-bottom: 8px;
    transform: translateY(0);
  }

  .picker-item-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 8px;

    .picker-item {
      margin-top: 24px;
      background-color: #fff;
      padding: 10px;
      border-radius: 50%;

      .radio-option {
        opacity: .3;
        cursor: pointer;
        appearance: none;
        border-radius: 16px;
        transition: all 200ms ease;
        background-size: 84%;
        height: 72px;
        width: 72px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  .picker-item {
    &:not(.is-checked) {
      &:hover .radio-option {
        transform: scale(1.05);
        opacity: 1;
      }
    }

    &.is-checked .radio-option {
      transform: scale(1.1);
      opacity: 1;
    }
  }

  .form-input-messages-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
</style>
