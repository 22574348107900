import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import { vuexI18nPlugin } from '@/support/i18n'

import state from './state'
import mutations from './mutations'
import actions from './actions'
import * as getters from './getters'
import modules from './modules'

Vue.use(Vuex)

const storageBlacklist = [
  'offerings/SET_CURRENT_COURSES',
  'offerings/SET_CURRENT_CONFIGS',
  'solutions/SET_SOLUTIONS_LIST'
]

const storageWhitelist = (s) => ({
  language: s.language,
  accessibility: s.accessibility,
  fullscreen: s.fullscreen,
  Auth: s.Auth,
  Users: s.Users,
  Management: s.Management,
  route: s.route,
  Dashboard: s.Dashboard
})

const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: window.localStorage,
  filter: mutations => (!storageBlacklist.includes(mutations.type)),
  reducer: (s) => (storageWhitelist(s))
})

export default new Vuex.Store({
  plugins: [
    vuexLocalStorage.plugin,
    vuexI18nPlugin
  ],
  state,
  mutations,
  actions,
  getters,
  modules
})
