<script>
import Tagify from '@yaireo/tagify'
import '@yaireo/tagify/dist/tagify.css'

export default {
  name: 'Tagify',
  components: {
    ValidationMessage: () => import('@/components/general/ValidationMessage')
  },

  props: {
    placeholder: {
      type: String,
      default: null
    },

    // label: {
    //   type: String,
    //   default: null
    // },

    disabled: {
      type: Boolean,
      default: false
    },

    readonly: {
      type: Boolean,
      default: false
    },

    value: null,
    validation: {
      type: Object,
      default: function () {
        return {}
      }
    },

    // hint: {
    //   type: String,
    //   default: null
    // },

    // hintTooltip: {
    //   type: Boolean,
    //   default: false
    // },

    dark: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      tagify: null
    }
  },

  watch: {
    value (newVal) {
      this.tagify.loadOriginalValues(newVal)
    }
  },

  mounted () {
    const input = this.$refs.tagify

    this.tagify = new Tagify(input, {})

    if (this.$refs.tagify.value) {
      this.$emit('input', this.$refs.tagify.value)
    }
  },

  methods: {
    updateValue () {
      if (!this.tagify.value) return
      this.$emit('input', this.$refs.tagify.value)
    }
  }
}
</script>

<template>
  <div
    :class="{ 'is-readonly': readonly,'has-error': validation.$error, 'is-disabled': disabled, 'theme-dark': dark }"
    class="form-item"
  >
    <div class="form-input-wrapper">
      <input
        ref="tagify"
        type="text"
        :value="value"
        :placeholder="placeholder"
        @change="updateValue()"
      >
    </div>
    <div class="form-input-details">
      <ValidationMessage :validation="validation" />
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/form.css"></style>

<style>
/*
 *
 * Override Tagify styles
 *
 */
.tagify {
  border: none;
  border-bottom: 1px solid #005EB8;
}

.tagify .tagify__input {
  line-height: 14px;
}

.tagify .tagify__input,
.tagify .tagify__tag .tagify__tag-text {
  font-size: 16px;
}

.tagify .tagify__tag>div::before {
  box-shadow: 0 0 8px 1.1em #e5e5e5 inset;
  box-shadow: 0 0 8px var(--tag-inset-shadow-size,1.1em) var(--tag-bg,#e5e5e5) inset;
}

.tagify .tagify__tag:hover:not([readonly]) div::before {
  box-shadow: 0 0 8px 1.1em #d3e2e2 inset;
  box-shadow: 0 0 8px var(--tag-inset-shadow-size,1.1em) var(--tag-hover,#d3e2e2) inset;
}

.tagify__tag__removeBtn:hover+div::before {
  box-shadow: 0 0 8px 1.1em rgba(211,148,148,.3) inset!important;
  box-shadow: 0 0 8px var(--tag-inset-shadow-size,1.1em) var(--tag-remove-bg,rgba(211,148,148,.3)) inset!important;
}

.tagify__tag.tagify--notAllowed:not(.tagify__tag--editable) div::before{
  box-shadow: 0 0 8px 1.1em rgba(211,148,148,.5) inset!important;
  box-shadow: 0 0 8px var(--tag-inset-shadow-size,1.1em) var(--tag-invalid-bg,rgba(211,148,148,.5)) inset!important;
}
</style>
