import { http } from '@/support/http'
import { createQuery } from '../../../support/utils/paginatorQueryBuilder'
import { parseToFormDataWithArray } from '@/support/payloadParser'

export const getTracksList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/learning_paths?${queryString}`)
}

export const getTracksSearch = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/learning_paths/search?${queryString}`)
}

export const getTrack = (idTrack) => {
  return http.get(`api/learning_path/${idTrack}`)
}

export const saveTrack = (data) => {
  const form = parseToFormDataWithArray(data)

  if (data.cardImage && typeof data.cardImage === 'object') {
    form.append('cardImage', data.cardImage, data.cardImage.name)
  }

  if (data.image && typeof data.image === 'object') {
    form.append('image', data.image, data.image.name)
  }

  return http.post('api/learning_path', form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const updateTrack = ({ id, data, isLinkedTab }) => {
  const form = parseToFormDataWithArray(data)

  if (data.image && typeof data.image === 'object') {
    form.append('image', data.image, data.image.name)
  }

  if (data.cardImage && typeof data.cardImage === 'object') {
    form.append('cardImage', data.cardImage, data.cardImage.name)
  }

  form.append('isLinkedTab', isLinkedTab)

  return http.post(`api/learning_path/${id}`, form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const toggleActiveTrack = (idTrack) => {
  return http.put(`api/learning_path/${idTrack}/active/toggle`)
}

export const updateTracksHighlights = (data) => {
  return http.post('api/learning_path_highlight', data)
}

export const getListTracksHighlights = () => {
  return http.get('showcase/learning_paths_highlights')
}
